import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { useTranslation } from 'react-i18next'

export default function Notification(props) {
  const { notification, clearNotification } = props
  const { t } = useTranslation()


  const type = notification && notification.type ? notification.type : 'success'
  if (notification) {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!notification}
        autoHideDuration={5000}
        onClose={clearNotification}
      >
        <MuiAlert variant='filled' severity={type} onClose={clearNotification}>
          {notification ? t(notification.text) : null}
        </MuiAlert>
      </Snackbar>
    )
  }
  return null
}
