import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import NotificationSidebar from './NotificationSidebar'
import logo from '../../Assets/Images/logo-white.png'
import ChevronDownIcon from '../../Assets/Icons/chevron-down.svg'
import { Colors } from '../../Utils/theme'
import { Collapse } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  drawer: {
    height: '100vh',
    position: 'relative',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    zIndex: 1203
  },
  flex: {
    flex: 1
  },
  drawerPaper: {
    backgroundColor: 'transparent',
    border: 0,
    height: '100vh',
    width: '17rem',
    overflowX: 'hidden',
    overflowY: 'hidden'
  },
  drawerInner: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto',
    backgroundColor: Colors.navy,
    width: '15rem',
    [theme.breakpoints.up('lg')]: {
      borderRadius: '.75rem',
      minHeight: 'calc(100vh - 4rem)',
      margin: '2rem',
    }
  },
  noRightBorderRadius: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    marginTop: '2.5rem',
    marginBottom: '1.75rem',
    alignSelf: 'center',
    maxWidth: '36%',
    height: 'auto',
    [theme.breakpoints.down('lg')]: {
      marginTop: '1.5rem',
      marginBottom: '1rem'
    }
  },
  listItem: {
    position: 'relative',
    height: '3.5rem',
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  subLink: {
    textDecoration: 'none',
    display: 'block',
    height: '3rem',
  },
  link: {
    width: '100%',
    height: '5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '1.25rem',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  linkClosed: {
    width: '100%',
    height: '5rem',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  icon: {
    width: '1.875rem',
    minWidth: 1
  },
  itemTextRoot: {
    margin: 0
  },
  itemText: {
    fontFamily: 'Ambit',
    fontWeight: 700,
    color: Colors.white,
    fontSize: '1rem',
    marginLeft: '2rem'
  },
  treeCorner: {
    position: 'absolute',
    top: '-.25rem',
    left: '3rem',
    height: '1.5rem',
    width: '1rem',
    borderLeft: `1px dashed ${Colors.border}`,
    borderBottom: `1px dashed ${Colors.border}`
  },
  treeCornerLong: {
    position: 'absolute',
    top: '-1.65rem',
    left: '3rem',
    height: '2.85rem',
    width: '1rem',
    borderLeft: `1px dashed ${Colors.border}`,
    borderBottom: `1px dashed ${Colors.border}`
  },
  subListItem: {
    position: 'relative',
    paddingLeft: '4rem'
  },
  subItemText: {
    whiteSpace: 'normal',
    marginLeft: '1rem',
    fontFamily: 'Ambit',
    fontWeight: 400,
    color: Colors.white,
    fontSize: '1rem',
    lineHeight: 1.2
  },
  logoutButton: {
    marginBottom: '1rem',
    alignSelf: 'center',
    backgroundColor: Colors.black20,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    height: '3.5rem',
    width: '85%',
    borderRadius: '.625rem',
    alignItems: 'center',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: Colors.black40
    }
  },
  logoutText: {
    margin: 0,
    marginLeft: '.5rem',
    fontFamily: 'Ambit',
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.white
  },
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(50,50,50,.78)'
  },
  itemIcon: {
    marginLeft: '1.25rem',
    maxHeight: '1.5rem',
    maxWidth: '1.5rem',
    height: '1.5rem'
  },
  activeBadge: {
    display: 'block',
    backgroundColor: Colors.white,
    position: 'absolute',
    top: '28%',
    bottom: '28%',
    borderRadius: '10rem',
    left: '-.27rem',
    width: '.5rem'
  },
  divider: {
    height: '1px',
    backgroundColor: Colors.white20,
    marginBottom: '1rem'
  },
  chevronDown: {
    height: '1rem',
    paddingRight: '2rem',
    paddingLeft: '1rem'
  },
  footerAnchor: {
    height: '2rem'
  },
  countBadge: {
    marginRight: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '1.75rem',
    minWidth: '1.75rem',
    borderRadius: '1rem',
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.black,
    backgroundColor: Colors.lightGreen
  }
}))

export default function Sidebar(props) {
  const { t } = useTranslation()
  const classes = useStyles()
  const currentLocation = useLocation()
  const open = props.open

  const [notificationSidebarOpen, setNotificationSidebarOpen] = useState(0)
  const toggleNotificationSidebarOpen = () => {
    const newValue = notificationSidebarOpen ? 0 : Date.now()
    setNotificationSidebarOpen(newValue)
  }
  const handleDrawerRootClick = () => {
    if (notificationSidebarOpen && notificationSidebarOpen < (Date.now() - 500)) {
      setNotificationSidebarOpen(0)
    }
  }

  const [openedSubMenus, setOpenedSubMenus] = useState<string[]>([])

  const toggleSubMenu = (parent) => {
    let newOpenedSubMenus = openedSubMenus.filter(item => item !== parent)
    if (newOpenedSubMenus.length === openedSubMenus.length) {
      newOpenedSubMenus = [...newOpenedSubMenus, parent]
    }
    setOpenedSubMenus(newOpenedSubMenus)
  }

  const renderItemText = (text) => {
    return (
      <ListItemText
        primary={t(text)}
        classes={{ root: classes.itemTextRoot, primary: classes.itemText }}
      />
    )
  }

  const renderSubItemText = (text) => {
    return (
      <ListItemText
        primary={t(text)}
        classes={{ root: classes.itemTextRoot, primary: classes.subItemText }}
      />
    )
  }

  const handleListItemClick = () => {
    if (open) {
      props.toggleDrawer()
    }
  }

  const renderChildItems = (item: any) => {
    if (item.children) {
      return (
        <Collapse in={openedSubMenus.includes(item.url)} timeout="auto" unmountOnExit>
          {item.children.map((child, cid) => {
            return (
              <Link key={child.url} to={child.url} className={classes.subLink}>
                <ListItem classes={{ root: classes.subListItem }}>
                  <div className={cid === 0 ? classes.treeCorner : classes.treeCornerLong} />
                  {renderSubItemText(child.text)}
                </ListItem>
              </Link>
            )
          })}
        </Collapse>
      )
    }
    return null
  }

  const renderBadges = (item) => {
    if (item.text === 'notifications') {
      if (props?.notifications?.length) {
        return (
          <>
            <div className={classes.countBadge}>
              {props.notifications.length}
            </div>
            <div className={classes.flex} />
          </>
        )
      }
    }
    return null
  }

  const renderItems = (items) => {
    return items.map((item) => {
      // TODO: add better check here to handle entity == entities etc.
      const isActive = !!(currentLocation.pathname || '').slice(0, 6).includes((item.url || '').slice(0, 6))
      if (item.children) {
        return (
          <>
            <ListItem
              key={item.url}
              classes={{ root: classes.listItem }}
              title={t(item.text)}
              disableGutters
              button
            >
              <div className={classes.link} onClick={() => toggleSubMenu(item.url)}>
                <ListItemIcon classes={{ root: classes.icon }}>
                  <img
                    src={item.icon}
                    className={classes.itemIcon} alt='icon'
                  />
                </ListItemIcon>
                {renderItemText(item.text)}
                <img src={ChevronDownIcon} className={classes.chevronDown} />
              </div>
              {isActive && <div className={classes.activeBadge} />}
              {renderBadges(item)}
            </ListItem>
            {renderChildItems(item)}
          </>
        )
      }

      if (item.url === '#') {
        return (
          <>
            <ListItem
              onClick={toggleNotificationSidebarOpen}
              key={item.url}
              classes={{ root: classes.listItem }}
              title={t(item.text)}
              disableGutters
              button
            >
              <div className={classes.link} onClick={() => toggleSubMenu(item.url)}>
                <ListItemIcon classes={{ root: classes.icon }}>
                  <img
                    src={item.icon}
                    className={classes.itemIcon} alt='icon'
                  />
                </ListItemIcon>
                {renderItemText(item.text)}
              </div>
              {isActive && <div className={classes.activeBadge} />}
              {renderBadges(item)}
            </ListItem>
            {renderChildItems(item)}
          </>
        )
      }

      // Pressing Link bubbles also to the ListItem onClick event
      return (
        <ListItem
          onClick={handleListItemClick}
          key={item.url}
          classes={{ root: classes.listItem }}
          title={t(item.text)}
          disableGutters
          button
        >
          <Link to={item.url} className={classes.link}>
            <ListItemIcon classes={{ root: classes.icon }}>
              <img
                src={item.icon}
                className={classes.itemIcon} alt='icon'
              />
            </ListItemIcon>
            {renderItemText(item.text)}
          </Link>
          {isActive && <div className={classes.activeBadge} />}
          {renderBadges(item)}
        </ListItem>
      )
    })
  }

  const renderNotificationSidebar = () => {
    return (
      <NotificationSidebar
        open={notificationSidebarOpen}
        notifications={props.notifications}
        onClose={toggleNotificationSidebarOpen}
      />
    )
  }

  return (
    <>
      {open && <div className={classes.backdrop} onClick={props.toggleDrawer} />}
      <Drawer
        variant='permanent'
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={[classes.drawerInner, (notificationSidebarOpen ? classes.noRightBorderRadius : '')].join(' ')} onClick={handleDrawerRootClick}>
          <div className={classes.logoContainer}>
            <img src={logo} alt='logo' className={classes.logo} />
          </div>
          <div className={classes.divider} />
          <List disablePadding>{renderItems(props.primaryNavigationItems)}</List>
          <div className={classes.flex} />
          <div className={classes.divider} />
          <List disablePadding>{renderItems(props.secondaryNavigationItems)}</List>
          <div className={classes.footerAnchor} />
        </div>
      </Drawer>
      {renderNotificationSidebar()}
    </>
  )
}
