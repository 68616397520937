import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '1.125rem',
    fontWeight: 400,
    marginBottom: '.5rem',
    color: Colors.label
  }
}))

export default function Label(props) {
  const classes = useStyles()

  return (
    <div className={classes.label}>
      {props.children}
    </div>
  )
}
