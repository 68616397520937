import React, { ReactElement, useEffect, useState } from 'react'
import { PageContainer, PageHeader, PageContent } from '../../../Components'
import { useStore } from '../../../Models/RootStore'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ShiftType } from '../../../Schemas'
import ShiftGeneral from '../../../Components/Shift/ShiftGeneral'
import ShiftLocation from '../../../Components/Shift/ShiftLocation'
import { useTranslation } from 'react-i18next'

type tabType = 'general' | 'contracts'
const initialTab = window.location.hash.replace('#','') as tabType

const Shift = (): ReactElement => {
  const { sessionStore, shiftStore }: any = useStore()
  const navigate = useNavigate()
  const location = useLocation()
  const { shiftId } = useParams()
  const [shift, setShift] = useState<ShiftType | null>(null)
  const [tab, setTab] = useState<tabType>(initialTab ? initialTab : 'general')
  const { t } = useTranslation()

  useEffect(() => {
    if (shiftId !== undefined) {
      void initShift(parseInt(shiftId, 10))
    }
  }, [shiftId])

  const initShift = async (id: number): Promise<void> => {
    // Load metadata if not cached
    if (shiftStore.types.length === 0) {
      await shiftStore.getShifts({ perPage: 1 })
    }
    const shift = await shiftStore.getShift(id)
    if (shift !== null) {
      setShift(shift)
    }
  }

  const handleChange = (key: string, value: string | number) => {
    const newShift = {...shift} as ShiftType
    const keys = key.split('.')
    const last = keys.pop() as string
    keys.reduce((o, k) => o[k] ??= {}, newShift)[last] = value
    setShift(newShift)
  }

  const handleGoBack = (): void => {
    const queryParams = (location.state as any)?.queryParams
    navigate('/shifts', { state: { queryParams }})
  }

  const handleTabChange = (value: tabType): void => {
    window.location.hash = value
    setTab(value)
  }

  return (
    <PageContainer>
      <PageHeader
        user={sessionStore.user}
        logout={() => sessionStore.logout()}
        onBackToList={handleGoBack}
        tab={tab}
        setTab={handleTabChange}
        tabs={[
          {
            name: t('shift_tab_general'),
            value: 'general'

          },
          {
            name: t('shift_tab_location'),
            value: 'location'
          }
        ]}
      />
      <PageContent size='small'>
        {shift && tab === 'general' && (
          <ShiftGeneral
            shift={shift}
            onChange={handleChange}
          />
        )}
        {shift && tab === 'location' && (
          <ShiftLocation
            shift={shift}
            onChange={handleChange}
          />
        )}
      </PageContent>
    </PageContainer>
  )
}

export default Shift
