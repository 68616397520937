import SettingsIcon from '../Assets/Icons/settings.svg'
import NotificationsIcon from '../Assets/Icons/notification.svg'
import DashboardIcon from '../Assets/Icons/dashboard.svg'
import ShiftsIcon from '../Assets/Icons/shifts.svg'
import CustomersIcon from '../Assets/Icons/customers.svg'
import CleanersIcon from '../Assets/Icons/cleaners.svg'
import MessagingIcon from '../Assets/Icons/messaging.svg'
import AnalyticsIcon from '../Assets/Icons/analytics.svg'
import UsersIcon from '../Assets/Icons/users.svg'
import SupportIcon from '../Assets/Icons/support.svg'

export const AvailablePrimaryRoutes = (role: string) => {
  return [
    { url: '/dashboard', icon: DashboardIcon, text: 'dashboard' },
    { url: '/shifts', icon: ShiftsIcon, text: 'shifts' },
    { url: '/customers', icon: CustomersIcon, text: 'customers' },
    { url: '/employees', icon: CleanersIcon, text: 'employees' },
    {
      url: '/messaging',
      text: 'messaging',
      icon: MessagingIcon,
      children: [
        { url: '/notifications', icon: MessagingIcon, text: 'push_messages' },
        { url: '/polls', icon: MessagingIcon, text: 'polls' },
        { url: '/general-feedbacks', icon: MessagingIcon, text: 'feedbacks' }
      ]
    },
    {
      url: '/analytics',
      text: 'analytics',
      icon: AnalyticsIcon,
      children: [
        { url: '/user-analytics', icon: AnalyticsIcon, text: 'downloads_and_users' },
        { url: '/usage-analytics', icon: AnalyticsIcon, text: 'usage_statistics' }
      ]
    }
  ]
}

export const AvailableSecondaryRoutes = (role: string) => {
  return [
    { url: '#', icon: NotificationsIcon, text: 'notifications' },
    { url: '/settings', icon: SettingsIcon, text: 'settings' },
    { url: '/users', icon: UsersIcon, text: 'users' },
    /* { url: '/support', icon: SupportIcon, text: 'technical_support' } */
  ]
}


export enum Lang {
  fi = 'fi',
  en ='en'
}
