import React, { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { TableHeader, DataMessage, Checkbox } from '..'
import { useNavigate } from 'react-router-dom'
import ChevronDown from '../../Assets/Icons/chevron-down-dark.svg'
import ChevronUp from '../../Assets/Icons/chevron-up-dark.svg'
import { Colors } from '../../Utils/theme'
import useFormatter from '../../Hooks/useFormatter'

// Styles

const useStyles = makeStyles((theme) => ({
  tableOuterContainer: {
    borderLeft: `1px solid ${Colors.border}`,
    borderRight: `1px solid ${Colors.border}`
  },
  root: {
    cursor: 'pointer',
    backgroundColor: Colors.white,
    '&:hover': {
      backgroundColor: `${Colors.tableHover} !important`
    },
    borderBottom: `1px solid ${Colors.border}`
  },
  rootActive: {
    cursor: 'pointer',
    backgroundColor: Colors.lightGreen,
    '&:hover': {
      backgroundColor: `${Colors.lightGreen} !important`
    },
    borderBottom: `1px solid ${Colors.border}`
  },
  cell: {
    fontFamily: 'Ambit',
    fontSize: '1.25rem',
    fontWeight: 400,
    color: Colors.black,
    borderBottom: 'none',
    height: '4.25rem'
  },
  secondary: {
    color: Colors.secondaryText
  },
  checkboxContainer: {
    paddingLeft: '.25rem',
    maxWidth: '2rem'
  },
  chevronIcon: {
    height: '.75rem',
    margin: '0 .25rem'
  },

  expandedContainer: {
    color: Colors.text,
    paddingLeft: '5.75rem',
    width: '100%',
    padding: '1.5rem 2rem',
    borderBottom: `1px solid ${Colors.border}`,
    fontSize: '1rem'
  }
}))

function ItemTableRow (props) {
  const { item } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { formatDate } = useFormatter()

  const [expanded, setExpanded] = useState(false)
  const openItem = (item = null) => setExpanded(!expanded)

  return (
    <>
      <TableRow
        classes={{ root: expanded ? classes.rootActive : classes.root }}
        hover
      >
        <TableCell className={classes.cell}>
          <div className={classes.checkboxContainer}>
            <Checkbox
              checked={props.selectedIds.includes(item.id)}
              onChange={() => props.toggleSelect(item.id)}
              label=''
              noBackgroundEffect={expanded}
            />
          </div>
        </TableCell>
        {[
          t(`feedback_kind_${item?.kind}`),
          item?.customer?.name,
          item?.shift?.pricableItem?.name,
          formatDate(item?.startTime),
          `${item?.employee?.person?.firstName} ${item?.employee?.person?.lastName}`,
        ].map((content, index) => {
          return (
            <TableCell key={index} className={classes.cell} onClick={openItem}>
              <span className={index ? classes.secondary : ''}>{content}</span>
            </TableCell>
          )
        })}
        <TableCell className={classes.cell} onClick={openItem}>
          <img src={expanded ? ChevronUp : ChevronDown} className={classes.chevronIcon} />
        </TableCell>
      </TableRow>
      {expanded && (
        <tr>
          <td colSpan={7} className={classes.expandedContainer}>
            {item?.note}
          </td>
        </tr>
      )}
    </>
  )
}

function ShiftFeedbackTable(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.items) {
      return props.items.map((item) => (
        <React.Fragment key={item.id}>
          <ItemTableRow
            item={item}
            onSelect={() => null}
            selectedIds={props.selectedIds}
            toggleSelect={props.toggleSelect}
          />
        </React.Fragment>
      ))
    }
    return null
  }

  const isAllSelected = () => {
    if (!props.selectedIds.length || props.selectedIds.length < props.items.length) return false
    for (const item of props.items) {
      if (!props.selectedIds.includes(item.id)) {
        return false
      }
    }
    return true
  }

  const toggleAll = () => {
    const all = isAllSelected()
    if (all) {
      props.clearSelect()
    } else {
      // Add new items to selection
      props.selectAll(props.items.map(item => item.id))
    }
  }

  return (
    <div className={classes.tableOuterContainer}>
      <Table>
        <TableHeader
          columnNames={[
            t('feedback_kind'),
            t('feedback_giver'),
            t('task'),
            t('cleaning_date'),
            t('cleaner'),
            ''
          ]}
          sort={props.sort}
          onSort={props.onSort}
          sortKeys={props.sortKeys}
          onSelect={toggleAll}
          selected={isAllSelected()}
        />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      <DataMessage
        loading={props.loading}
        data={props.items}
        message={t('no_results')}
      />
    </div>
  )
}

export default ShiftFeedbackTable
