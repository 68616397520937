import React, { ReactElement, useEffect, useState } from 'react'
import { PageContainer, PageHeader, PageContent } from '../../../Components'
import { useStore } from '../../../Models/RootStore'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { EmployeeType } from '../../../Schemas'
import EmployeeGeneral from '../../../Components/Employee/EmployeeGeneral'
import EmployeeContracts from '../../../Components/Employee/EmployeeContracts'
import { useTranslation } from 'react-i18next'

type tabType = 'general' | 'contracts'
const initialTab = window.location.hash.replace('#','') as tabType

const Employee = (): ReactElement => {
  const { sessionStore, employeeStore }: any = useStore()
  const navigate = useNavigate()
  const location = useLocation()
  const { employeeId } = useParams()
  const [employee, setEmployee] = useState<EmployeeType | null>(null)
  const [tab, setTab] = useState<tabType>(initialTab ? initialTab : 'general')
  const { t } = useTranslation()

  useEffect(() => {
    if (employeeId !== undefined) {
      void initEmployee(parseInt(employeeId, 10))
    }
  }, [employeeId])

  const initEmployee = async (id: number): Promise<void> => {
    // Load metadata if not cached
    if (employeeStore.contractTypes.length === 0) {
      await employeeStore.getEmployees({ perPage: 1 })
    }
    const employee = await employeeStore.getEmployee(id)
    if (employee !== null) {
      setEmployee(employee)
    }
  }

  const handleChange = (key: string, value: string | number) => {
    const newEmployee = {...employee} as EmployeeType
    const keys = key.split('.')
    const last = keys.pop() as string
    keys.reduce((o, k) => o[k] ??= {}, newEmployee)[last] = value
    setEmployee(newEmployee)
  }

  const handleGoBack = (): void => {
    const queryParams = (location.state as any)?.queryParams
    navigate('/employees', { state: { queryParams }})
  }

  const handleTabChange = (value: tabType): void => {
    window.location.hash = value
    setTab(value)
  }

  return (
    <PageContainer>
      <PageHeader
        user={sessionStore.user}
        logout={() => sessionStore.logout()}
        onBackToList={handleGoBack}
        tab={tab}
        setTab={handleTabChange}
        tabs={[
          {
            name: t('employee_tab_general'),
            value: 'general'

          },
          {
            name: t('employee_tab_contracts'),
            value: 'contracts'
          }
        ]}
      />
      <PageContent size='small'>
        {employee && tab === 'general' && (
          <EmployeeGeneral
            employee={employee}
            onChange={handleChange}
          />
        )}
        {employee && tab === 'contracts' && (
          <EmployeeContracts
              employee={employee}
              onChange={handleChange}
          />
        )}
      </PageContent>
    </PageContainer>
  )
}

export default Employee
