import { createContext, useContext } from 'react'
import AppStore from './AppStore'
import SessionStore from './SessionStore'
import UserStore from './UserStore'
import FileStore from './FileStore'
import ShiftStore from './ShiftStore'
import CustomerStore from './CustomerStore'
import EmployeeStore from './EmployeeStore'
import NotificationStore from './NotificationStore'
import PollStore from './PollStore'
import AnalyticsStore from './AnalyticsStore'
import GeneralFeedbackStore from './GeneralFeedbackStore'
import ShiftFeedbackStore from './ShiftFeedbackStore'

class RootStore {
  appStore
  sessionStore
  userStore
  customerStore
  employeeStore
  fileStore
  shiftStore
  notificationStore
  pollStore
  analyticsStore
  generalFeedbackStore
  shiftFeedbackStore

  constructor() {
    this.appStore = new AppStore(this)
    this.sessionStore = new SessionStore(this)
    this.userStore = new UserStore(this)
    this.customerStore = new CustomerStore(this)
    this.employeeStore = new EmployeeStore(this)
    this.fileStore = new FileStore(this)
    this.shiftStore = new ShiftStore(this)
    this.notificationStore = new NotificationStore(this)
    this.pollStore = new PollStore(this)
    this.analyticsStore = new AnalyticsStore(this)
    this.generalFeedbackStore = new GeneralFeedbackStore(this)
    this.shiftFeedbackStore = new ShiftFeedbackStore(this)
  }
}

export const createStore = () => {
  const rootStore = new RootStore()
  return rootStore
}

export const StoreContext = createContext({})
export const StoreProvider = StoreContext.Provider

export const useStore = () => useContext(StoreContext)
