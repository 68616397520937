import React, { useState } from 'react'
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from 'react-simple-maps'
import { Colors } from '../../Utils/theme';
import { t } from 'i18next';

// https://raw.githubusercontent.com/deldersveld/topojson/master/countries/finland/finland-regions.json'
const geoUrl = '/data/finland-regions.json'

const markers = [
  {
    name: 'Tampere',
    value: 11,
    coordinates: [23.4, 61.2]
  },
  {
    name: 'Turku',
    value: 21,
    coordinates: [22.264, 60.454]
  },
  {
    name: "Helsinki",
    value: 42,
    coordinates: [24.94583,	60.192059]
  },
  {
    name: 'Oulu',
    value: 42,
    coordinates: [25.46816,	65.01236]
  },
  {
    name: 'Hämeenlinna',
    value: 9,
    coordinates: [24.46434, 60.99596]
  }
];

const MapChart = () => {
  const [tooltip, setTooltip] = useState<number|null>(null)

  const renderTooltip = (index, marker) => {
    const { name, value, coordinates } = marker
    const baseY = -130
    const baseX = -120
    return (
      <Marker
        key={index}
        coordinates={coordinates}
        onClick={() => setTooltip(null)}
      >
        <rect
          y={baseY}
          x={baseX}
          height={100}
          width={240}
          fill={Colors.lightGreen}
          style={{
            cursor: 'pointer',
            userSelect: 'none',
            backgroundColor: Colors.lightGreen
          }}
        >
        </rect>
        <text
          y={baseY+40}
          x={baseX+15}
          style={{
            cursor: 'pointer',
            userSelect: 'none',
            fontFamily: "system-ui",
            fontSize: '24px',
            fontWeight: 700,
            fill: Colors.black
          }}
        >
          {name}
        </text>
        <text
          y={baseY+40+35}
          x={baseX+15}
          style={{
            cursor: 'pointer',
            userSelect: 'none',
            fontFamily: "system-ui",
            fontSize: '24px',
            fontWeight: 400,
            fill: Colors.text
          }}
        >
          {value} {t('num_shifts_today')}
        </text>
      </Marker>
    )
  }

  return (
    <ComposableMap
      projection="geoAzimuthalEqualArea"
      projectionConfig={{
        rotate: [-27, -65, 1],
        scale: 3300
      }}
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              fill={Colors.navy}
              stroke={Colors.navy}
            />
          ))
        }
      </Geographies>
      {markers.map(({ name, value, coordinates }, index) => (
        <Marker
          key={index}
          coordinates={coordinates}
          onClick={() => setTooltip(tooltip !== index ? index : null)}
        >
          <circle
            r={20}
            fill={Colors.navy}
            stroke={Colors.lightGreen}
            strokeWidth={5}
            style={{
              cursor: 'pointer'
            }}
          />
          <text
            textAnchor="middle"
            y={5}
            style={{
              cursor: 'pointer',
              fontFamily: "system-ui",
              fontWeight: 900,
              fill: Colors.white
            }}
          >
            {value}
          </text>
        </Marker>
      ))}
      {tooltip !== null && renderTooltip(tooltip, markers[tooltip])}
    </ComposableMap>
  );
};

export default MapChart
