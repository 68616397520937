import Chart from 'react-apexcharts'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
}))

export default function HorizontalBarChart(props) {
  const classes = useStyles()

  const ageLabels = props.versions ? ['1.0.0', '1.0.1', '1.1.0', '1.1.1', '1.1.2', '1.2.0'] : ['18-24', '25-34', '35-44', '45-54', '55-64', '65+']
  const mockData = Array(6).fill(null).map((x, i) => {
    return {
      label: i+1, //`2023-07-${`0${i+1}`.slice(-2)}`,
      value: Math.round(123 * Math.random()+21)
    }
  })

  const getOptions = () => {
    return (
      {
        labels: props.labels,
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        },
        dataLabels: {
          enabled: false
        },
        colors: [Colors.chart1, Colors.chart2, Colors.chart3],
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 5
          }
        },
        chart: {
          animations: { enabled: false },
          stacked: props.stacked ? true : false,
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: mockData.map(i => i.label),
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
          labels: {
            show: true
          },
        },
        yaxis: {
          // floating: true,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
          labels: {
            show: true
          },
        }
      }
    )
  }

  const getSeries = () => {
    return [
      {
        name: props.labels[0],
        data: mockData.map(i => i.value)
      },
      {
        name: props.labels[1],
        data: mockData.map(i => i.value)
      }
    ]
  }

  return (
    <Chart
      options={getOptions()}
      series={getSeries()}
      type='bar'
      width={'100%'}
      height={'300'}
    />
  )
}
