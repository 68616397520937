import React, { ReactElement, useEffect, useState } from 'react'
import { Dialog, Select } from '../index'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../Models/RootStore'
import makeStyles from '@mui/styles/makeStyles'
import DatePicker from '../Common/DatePicker'
import { Grid } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: '300px'
  },
  group: {
    marginBottom: '0.6rem'
  }
}))

interface Params {
  type: number
  status: number,
  startDate: string
  endDate: string
  startTime: string
  endTime: string
}

interface Props {
  open: boolean
  onClose: () => void
  onConfirm: (props: Params) => void
  filterParams: Params
}

const ShiftFilterDialog = ({ open, onClose, onConfirm, filterParams }: Props): ReactElement => {
  const { t } = useTranslation()
  const [filterType, setFilterType] = useState(filterParams.type)
  const [filterStatus, setFilterStatus] = useState(filterParams.status)
  const [filterStartDate, setFilterStartDate] = useState(filterParams.startDate)
  const [filterEndDate, setFilterEndDate] = useState(filterParams.endDate)
  const [filterStartTime, setFilterStartTime] = useState(filterParams.startTime)
  const [filterEndTime, setFilterEndTime] = useState(filterParams.endTime)
  const { shiftStore }: any = useStore()
  const classes = useStyles()

  useEffect(() => {
    if (filterParams.type !== filterType) {
      setFilterType(filterParams.type)
    }
    if (filterParams.status !== filterStatus) {
      setFilterStatus(filterParams.status)
    }
    if (filterParams.startDate !== filterStartDate) {
      setFilterStartDate(filterParams.startDate)
    }
    if (filterParams.endDate !== filterEndDate) {
      setFilterEndDate(filterParams.endDate)
    }
    if (filterParams.startTime !== filterStartTime) {
      setFilterStartTime(filterParams.startTime)
    }
    if (filterParams.endTime !== filterEndTime) {
      setFilterEndTime(filterParams.endTime)
    }
  }, [
    filterParams.type,
    filterParams.status,
    filterParams.startDate,
    filterParams.endDate,
    filterParams.startTime,
    filterParams.endTime
  ])

  const timeOptions = [-1, ...Array.from({ length: 25 }, (_, i) => i)].map(i => ({
    label: i === -1  ? '' : `${i}.00`,
    value: i === -1  ? '' : `${i}.00`,
  }))

  const typeOptions =
    [
      {
        label: t('all_options'),
        value: 0
      },
      ...(shiftStore.types || []).map(item => (
        {
          label: t(`shift_type_${item.name}`),
          value: item.id
        }
      ))
    ]

  const statusOptions =
    [
      {
        label: t('all_options'),
        value: 0
      },
      ...(shiftStore.statuses || []).map(item => (
        {
          label: t(`shift_status_${item.name}`),
          value: item.id
        }
      ))
    ]

  const handleClose = (): void => {
    setFilterType(filterParams.type)
    setFilterStatus(filterParams.status)
    setFilterStartDate(filterParams.startDate)
    setFilterEndDate(filterParams.endDate)
    setFilterStartTime(filterParams.startTime)
    setFilterEndTime(filterParams.endTime)
    onClose()
  }

  const handleConfirm = (): void => {
    onConfirm({
      type: filterType,
      status: filterStatus,
      startDate: filterStartDate,
      endDate: filterEndDate,
      startTime: filterStartTime,
      endTime: filterEndTime
    })
  }

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      handleOk={handleConfirm}
      title={t('filter_shifts')}
      okText={t('show_results')}
    >
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <DatePicker
              label={t('start_date')}
              value={filterStartDate}
              onChange={setFilterStartDate}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DatePicker
              label={t('end_date')}
              value={filterEndDate}
              onChange={setFilterEndDate}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select
              label={t('start_time')}
              options={timeOptions}
              value={filterStartTime}
              onChange={setFilterStartTime}
              noMargin
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select
              label={t('end_time')}
              options={timeOptions}
              value={filterEndTime}
              onChange={setFilterEndTime}
              noMargin
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select
              label={t('type')}
              options={typeOptions}
              value={filterType}
              onChange={setFilterType}
              noMargin
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select
              label={t('status')}
              options={statusOptions}
              value={filterStatus}
              onChange={setFilterStatus}
              noMargin
            />
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

export default ShiftFilterDialog
