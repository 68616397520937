import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    flexDirection: 'column',
    flexGrow: 1,
    flex: 1,
    padding: '2rem 3rem 15rem'
  },
  mediumContainer: {
    maxWidth: '75rem'
  },
  smallContainer: {
    maxWidth: '50rem'
  }
}))

const PageContent = (props) => {
  const classes = useStyles()

  const getSizeClass = () => {
    if (props.size === 'medium') return classes.mediumContainer
    if (props.size === 'small') return classes.smallContainer
    return ''
  }

  return (
    <div className={classes.container}>
      <div className={getSizeClass()}>
        {props.children}
      </div>
    </div>
  )
}

export default PageContent
