import React, { ReactElement, useEffect, useState } from 'react'
import { PageContainer, PageHeader, PageContent } from '../../../Components'
import { useStore } from '../../../Models/RootStore'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { CustomerType } from '../../../Schemas'
import CustomerForm from '../../../Components/Customer/CustomerForm'

const Customer = (): ReactElement => {
  const { sessionStore, customerStore }: any = useStore()
  const navigate = useNavigate()
  const location = useLocation()
  const { customerId } = useParams()
  const [customer, setCustomer] = useState<CustomerType | null>(null)

  useEffect(() => {
    if (customerId !== undefined) {
      void initCustomer(parseInt(customerId, 10))
    }
  }, [customerId])

  const initCustomer = async (id: number): Promise<void> => {
    // Load metadata if not cached
    if (customerStore.categories.length === 0) {
      await customerStore.getCustomers({ perPage: 1 })
    }
    const customer = await customerStore.getCustomer(id)
    if (customer !== null) {
      setCustomer(customer)
    }
  }

  const handleChange = (key: string, value: string | number) => {
    const newCustomer = {...customer} as CustomerType
    const keys = key.split('.')
    const last = keys.pop() as string
    keys.reduce((o, k) => o[k] ??= {}, newCustomer)[last] = value
    setCustomer(newCustomer)
  }

  const handleGoBack = (): void => {
    const queryParams = (location.state as any)?.queryParams
    navigate('/customers', { state: { queryParams }})
  }

  return (
    <PageContainer>
      <PageHeader
        user={sessionStore.user}
        logout={() => sessionStore.logout()}
        onBackToList={handleGoBack}
      />
      <PageContent size='small'>
        {customer && <CustomerForm customer={customer} onChange={handleChange} />}
      </PageContent>
    </PageContainer>
  )
}

export default Customer
