import Chart from 'react-apexcharts'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
}))

const mockData = Array(30).fill(null).map((x, i) => {
  return {
    label: i+1, //`2023-07-${`0${i+1}`.slice(-2)}`,
    value: Math.round(123 * Math.random()+21)
  }
})

export default function DonutChart(props) {
  const classes = useStyles()

  const getOptions = () => {
    return (
      {
        labels: ["Henkilöasiakas", "Yritysasiakas"],
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        },
        dataLabels: {
          enabled: false
        },
        colors: [Colors.chart1, Colors.chart2, Colors.chart3],
        plotOptions: {
          bar: {
            borderRadius: 11
          }
        },
        chart: {
          animations: { enabled: false },
          stacked: props.stacked ? true : false,
          toolbar: {
            show: false
          }
        },

      }
    )
  }

  const getSeries = () => {
    return [152, 422]
  }

  return (
    <Chart
      options={getOptions()}
      series={getSeries()}
      type='donut'
      width={'100%'}
      height={'300'}
    />
  )
}
