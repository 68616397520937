import { z } from 'zod';


export const NotificationSchema = z.object({
  id: z.number().optional(),
  publishedAt: z.string().nullable().optional(),
  sentAt: z.string().nullable().optional(), // Accept null values
  type: z.enum(['general', 'reminder', 'feedback', 'notification', 'important', 'campaign', 'marketing']),
  actionType: z.enum(['give_feedback', 'open_app_store']).nullable(),
  titleFi: z.string(),
  titleEn: z.string(),
  contentHtmlFi: z.string(),
  contentHtmlEn: z.string(),
  photo: z.string().nullable().optional(), // Accept null values
  video: z.string().nullable().optional(), // Accept null values
  senderName: z.string().optional(),
  active: z.boolean().optional(),
  creatorId: z.number().optional(),
  updaterId: z.number().nullable().optional(),
  createdAt: z.string().optional().transform((val) => val ? new Date(val) : undefined), // Transform string to Date
  updatedAt: z.string().optional().transform((val) => val ? new Date(val) : undefined), // Transform string to Date
});

export type NotificationType = z.infer<typeof NotificationSchema>
export type  TActionType = 'give_feedback' | 'open_app_store' | null
export type TCategory = 'general' | 'reminder' | 'feedback' | 'notification' | 'important' | 'campaign' | 'marketing'