import { z } from 'zod'
import { ShiftSchema } from './ShiftSchema'
import { CustomerSchema } from './CustomerSchema'
import { EmployeeSchema } from './EmployeeSchema'

export const ShiftFeedbackSchema = z.object({
  id: z.number(),
  rating: z.string(),
  feedbackType: z.string(),
  deadline: z.string().nullable().transform((val) => val ? new Date(val) : null),
  completedAt: z.string().nullable().transform((val) => val ? new Date(val) : null),
  kind: z.string(),
  priority: z.number().nullable(),
  status: z.string(),
  note: z.string(),
  visibleToCustomer: z.boolean(),
  visibleToEmployee: z.boolean(),
  extId: z.number(),
  extResponsibleId: z.number().nullable(),
  extCreatedAt: z.string().nullable().transform((val) => val ? new Date(val) : null),
  extUpdatedAt: z.string().nullable().transform((val) => val ? new Date(val) : null),
  extActivityId: z.number().nullable(),
  active: z.boolean(),
  startTime: z.string().transform((val) => val ? new Date(val) : null),
  endTime: z.string().nullable().transform((val) => val ? new Date(val) : null),
  createdAt: z.string().transform((val) => val ? new Date(val) : null),
  updatedAt: z.string().transform((val) => val ? new Date(val) : null),
  shift: ShiftSchema.nullable().optional(),
  customer: CustomerSchema.nullable().optional(),
  employee: EmployeeSchema.nullable().optional()
})

export type ShiftFeedbackType = z.infer<typeof ShiftFeedbackSchema>
