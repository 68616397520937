import { z } from 'zod'

export const CustomerSchema = z.object({
  id: z.number(),
  extId: z.number(),
  extUnitId: z.number().nullable(),
  extUpdatedAt: z.string().nullable().transform((val) => val ? new Date(val) : null),
  feedbackCount: z.number().optional(),
  name: z.string(),
  customerType: z.string(),
  category: z.string(),
  streetAddress: z.string(),
  postalCode: z.string(),
  city: z.string(),
  area: z.string(),
  customerNumber: z.string(),
  status: z.string(),
  active: z.boolean(),
  lastActiveAt: z.string().nullable().transform((val) => val ? new Date(val) : null),
  createdAt: z.string().nullable().transform((val) => val ? new Date(val) : null),
  updatedAt: z.string().nullable().transform((val) => val ? new Date(val) : null),
  person: z.object({
    extId: z.number().nullable(),
    firstName: z.string(),
    lastName: z.string(),
    dateOfBirth: z.string(),
    title: z.string(),
    streetAddress: z.string(),
    postalCode: z.string(),
    city: z.string(),
    country: z.string(),
    email: z.string(),
    phone: z.string()
  }),
  company: z.object({
    extId: z.number().nullable(),
    officialName: z.string(),
    costCenterCode: z.string(),
    status: z.string(),
    email: z.string(),
    phone: z.string()
  })
})

export type CustomerType = z.infer<typeof CustomerSchema>
