import { z } from 'zod'

export const LeaveSchema = z.object({
  id: z.number(),
  leaveType: z.string(),
  startTime: z.string().transform((val) => val ? new Date(val) : null),
  endTime: z.string().transform((val) => val ? new Date(val) : null),
  confirmed: z.string(),
  createdAt: z.string().transform((val) => val ? new Date(val) : null),
  updatedAt: z.string().transform((val) => val ? new Date(val) : null)
})

export type LeaveType = z.infer<typeof LeaveSchema>
