import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import {
  UserTable,
  PageContainer,
  PageHeader,
  PageContent,
  IndexActions,
  TableHeaderActions
} from '../../../Components'
import UserDialog from '../../../Components/User/UserDialog'
import { UserType } from '../../../Schemas'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    padding: '2.5rem'
  }
}))

const SORT_KEYS = ['name', 'role', 'email']
const SORT_DIRECTIONS = ['asc', 'desc']

interface QueryParams {
  search: string
  perPage: number
  sort: string
  state: string
}

function Users() {
  const classes = useStyles()

  const { sessionStore, userStore }: any = useStore()

  const [showDialog, setShowDialog] = useState(false)
  const [queryParams, setQueryParams] = useState<QueryParams>({
    search: '',
    perPage: 10,
    sort: `${SORT_KEYS[0]}_${SORT_DIRECTIONS[0]}`,
    state: 'enabled'
  })
  const [currentUser, setCurrentUser] = useState<UserType | null>(null)

  useEffect(() => {
    refreshItems()
  }, [queryParams])

  const { t } = useTranslation()

  const refreshItems = (newParams = {}) => {
    userStore.getUsers({...queryParams, page: 0})
  }

  const handleQueryParamsChange = (key: string, value: string | number): void => {
    setQueryParams({...queryParams, [key]: value})
  }

  const handleDialogSuccess = () => {
    setShowDialog(false)
    setCurrentUser(null)
    refreshItems()
  }

  const getStateOptions = () => {
    return [
      {
        label: t('enabled_users'),
        value: 'enabled'
      },
      {
        label: t('disabled_users'),
        value: 'disabled'
      },
      {
        label: t('all_users'),
        value: 'all'
      }
    ]
  }

  const getSortOptions = () => {
    const options: any = []
    for (const direction of SORT_DIRECTIONS) {
      for (const sortKey of SORT_KEYS) {
        const fullSortKey = `${sortKey}_${direction}`
        options.push({
          value: fullSortKey,
          label: t(`sort_${fullSortKey}`)
        })
      }
    }
    return options
  }

  const handleUserOpen = (id: number): void => {
    setCurrentUser(userStore.users.find(item => item.id === id))
    setShowDialog(true)
  }

  const handleNewUser = (): void => {
    setCurrentUser(null)
    setShowDialog(true)
  }

  return (
    <PageContainer>
      <PageHeader
        title={t('users')}
        user={sessionStore.user}
        logout={() => sessionStore.logout()}
      />
      <PageContent>
        <IndexActions
          sort={queryParams.sort}
          setSort={(sort: string) => handleQueryParamsChange('sort', sort)}
          sortOptions={getSortOptions()}
          onCreate={handleNewUser}
          category={queryParams.state}
          setCategory={(state: string) => handleQueryParamsChange('state', state)}
          categoryOptions={getStateOptions()}
        />
        <TableHeaderActions
          search={queryParams.search}
          setSearch={(search: string) => handleQueryParamsChange('search', search)}
          perPage={queryParams.perPage}
          setPerPage={(perPage: number) => handleQueryParamsChange('perPage', perPage)}
          total={userStore.totalFiltered}
        />
        <UserTable
          loading={userStore.loading}
          items={userStore.users}
          sort={queryParams.sort}
          sortKeys={SORT_KEYS}
          onUserOpen={handleUserOpen}
          onSort={(sort: string) => handleQueryParamsChange('sort', sort)}
        />
      </PageContent>
      <UserDialog
        user={currentUser}
        open={showDialog}
        onClose={() => setShowDialog(false)}
        onSuccess={handleDialogSuccess}
      />
    </PageContainer>
  )
}

export default observer(Users)
