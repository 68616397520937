import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class PollStore {
  rootStore
  polls = []
  skip = 0
  take = 50
  total = 0 // Number of total results
  categories = [] // (Kustannusyksiköt) Lempi, Tampere; Lempi, Turku etc.

  poll = null
  loading = null
  pollAnswers = []

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setLoading = (loading) => { this.loading = loading }
  setPolls = (polls) => { this.polls = polls }
  setTotal = (total) => { this.total = total }
  setPoll = (poll) => { this.poll = poll }
  setSkip = (skip) => { this.skip = skip }
  setTake = (take) => { this.take = take }
  setCategories = (categories) => { this.categories = categories }
  setPollAnswers = (pollAnswers) => { this.pollAnswers = pollAnswers }

  async getPolls(params) {
    const search = params.search || ''
    const take = params.perPage || 50
    const skip = (params.page || 0) * take
    const category = (params.category !== 'all') ? params.category : undefined
    const sort = params.sort || undefined

    this.setLoading(true)
    const response: any = await Api.getPolls({ skip, take, search, category, sort })
    if (response.ok) {
      this.setPolls(response.data.items)
      this.setTotal(response.data.total)
      this.setCategories(response.data.categories)
    }
    this.setLoading(false)
  }

  async getPoll(id) {
    this.setLoading(true)
    const response: any = await Api.getPoll(id)
    if (response.ok) {
      this.setPoll(response.data)
    }
    this.setLoading(false)
  }

  async createPoll(poll) {
    this.setLoading(true)
    const response = await Api.createPoll(poll)
    this.rootStore.appStore.setErrorOrSuccess(response, 'validation_error_check_fields', 'poll_created')
    this.setLoading(false)
  }

  async updatePoll(id, poll) {
    this.setLoading(true)
    const response = await Api.updatePoll(id, poll)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')
    this.setLoading(false)
  }

  async getPollAnswers(id) {
    this.setLoading(true)
    const response = await Api.getPollAnswers(id)
    this.setLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    if (response.ok) {
      this.setPollAnswers(response.data)
    }
  }
}
