import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import HeaderUser from './HeaderUser'

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    borderLeft: `1px solid ${Colors.border}`,
    width: '26.25rem',
    display: 'flex',
    flexDirection: 'column'
  },
  innerContainer: {
    padding: '2rem'
  },
  userContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '0 2rem'
  }
}))

const PageActions = (props) => {
  const classes = useStyles()

  const renderUser = () => {
    if (props.user) {
      return (
        <div className={classes.userContainer}>
          <HeaderUser
            user={props.user}
            logout={props.logout}
          />
        </div>
      )
    }
    return null
  }

  return (
    <div className={classes.container}>
      {renderUser()}
      <div className={classes.innerContainer}>
        {props.children}
      </div>
    </div>
  )
}

export default PageActions
