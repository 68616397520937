import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { TableHeader, DataMessage } from '..'
import { Link, useNavigate } from 'react-router-dom'
import { Colors } from '../../Utils/theme'
import { formatDateTime } from '../../Hooks/useFormatter'
import { IconButton } from '@mui/material'
import EditIcon from '../../Assets/Icons/edit-dark.svg'

const useStyles = makeStyles((theme) => ({
  tableOuterContainer: {
    borderLeft: `1px solid ${Colors.border}`,
    borderRight: `1px solid ${Colors.border}`
  },
  root: {
    cursor: 'pointer',
    backgroundColor: Colors.white,
    '&:hover': {
      backgroundColor: `${Colors.tableHover} !important`
    },
    borderBottom: `1px solid ${Colors.border}`
  },
  cell: {
    fontFamily: 'Ambit',
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.black,
    borderBottom: 'none',
    height: '4.25rem'
  },
  secondary: {
    color: Colors.secondaryText
  },
  showLink: {
    backgroundColor: Colors.lightGreen,
    color: Colors.navy,
    textDecoration: 'underline',
    padding: '.5rem',
    borderRadius: '.625rem'
  }
}))

function ItemTableRow (props) {
  const { item } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const openItem = () => null

  return (
    <TableRow
      classes={{ root: classes.root }}
      onClick={openItem}
      hover
    >
      <TableCell className={classes.cell}>
        <span>{item.screenName}</span>
      </TableCell>
      <TableCell className={classes.cell}>
        <span className={classes.secondary}>
          {item.usageShare}
        </span>
      </TableCell>
      <TableCell className={classes.cell}>
        <span className={classes.secondary}>
          {item.usageShareChange}
        </span>
      </TableCell>
      <TableCell className={classes.cell}>
        <span className={classes.secondary}>
          {item.usageTime}
        </span>
      </TableCell>
      <TableCell className={classes.cell}>
        <span className={classes.secondary}>
          {item.viewCount}
        </span>
      </TableCell>
      <TableCell className={classes.cell}>
        <span className={classes.secondary}>
          {item.eventCount}
        </span>
      </TableCell>
      <TableCell className={classes.cell}>
        <span className={classes.secondary}>
          {item.conversion}
        </span>
      </TableCell>
    </TableRow>
  )
}

function ContentUsageTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.items) {
      return props.items.map((item) => (
        <React.Fragment key={item.id}>
          <ItemTableRow item={item} />
        </React.Fragment>
      ))
    }
    return null
  }

  return (
    <div className={classes.tableOuterContainer}>
      <Table>
        <TableHeader columnNames={[t('content_section_name'), t('usage_share'), t('usage_share_diff_previous_30d'), t('avg_time_short'), t('view_count_short'), t('events'), t('conversion')]} />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      <DataMessage
        loading={props.loading}
        data={props.items}
        message={t('no_results')}
      />
    </div>
  )
}

export default ContentUsageTable
