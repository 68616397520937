import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { TableHeader, DataMessage, Checkbox, Button } from '..'
import { Link, useNavigate } from 'react-router-dom'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  tableOuterContainer: {
    borderLeft: `1px solid ${Colors.border}`,
    borderRight: `1px solid ${Colors.border}`
  },
  root: {
    cursor: 'pointer',
    backgroundColor: Colors.white,
    '&:hover': {
      backgroundColor: `${Colors.tableHover} !important`
    },
    borderBottom: `1px solid ${Colors.border}`
  },
  cell: {
    fontFamily: 'Ambit',
    fontSize: '1.25rem',
    fontWeight: 400,
    color: Colors.black,
    borderBottom: 'none',
    height: '4.25rem'
  },
  secondary: {
    color: Colors.secondaryText
  },
  checkboxContainer: {
    paddingLeft: '.25rem',
    maxWidth: '2rem'
  },
  showLink: {
    backgroundColor: Colors.lightGreen,
    color: Colors.navy,
    padding: '.5rem',
    borderRadius: '.5rem'
  }
}))

function ItemTableRow (props) {
  const { item } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const openItem = () => props.openItem(item.id)

  const renderTimeSpan = () => {
    let start = ''
    let end = ''
    let endYear = -1
    if (item.validUntil) {
      end = moment(item.validUntil).format('DD.MM.YYYY')
      endYear =  moment(item.validUntil).year()
    }

    const startTime = item.validFrom || item.createdAt
    const startYear = moment(startTime).year()
    if (startYear !== endYear) {
      start = moment(startTime).format('DD.MM.YYYY')
    } else {
      start = moment(startTime).format('DD.MM.')
    }

    if (start && end) {
      return `${start} - ${end}`
    }
    if (start && !end) {
      return `${start} ->`
    }
    if (!start && end) {
      return `- ${end}`
    }
    return ''
  }

  return (
    <TableRow
      classes={{ root: classes.root }}
      hover
    >
      <TableCell className={classes.cell}>
        <div className={classes.checkboxContainer}>
          <Checkbox
            checked={props.selectedIds.includes(item.id)}
            onChange={() => props.toggleSelect(item.id)}
            label=''
          />
        </div>
      </TableCell>
      {[
        item.nameFi || item.titleFi || item.nameEn || item.titleEn,
        t(`poll_type_${item.type}`),
        item?.totalAnswers,
        renderTimeSpan(),
        `${item?.creator?.firstName || ''} ${item?.creator?.lastName || ''}`.trim(),
      ].map((content, index) => {
        return (
          <TableCell key={index} className={classes.cell} onClick={openItem}>
            <span className={index ? classes.secondary : ''}>{content}</span>
          </TableCell>
        )
      })}
      <TableCell className={classes.cell}>
        <Link to={`/poll-results/${item.id}`} className={classes.showLink}>{t('show')}</Link>
      </TableCell>
    </TableRow>

  )
}

function PollTable(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.items) {
      return props.items.map((item) => (
        <React.Fragment key={item.id}>
          <ItemTableRow
            item={item}
            onSelect={() => null}
            openItem={props.openItem}
            selectedIds={props.selectedIds}
            toggleSelect={props.toggleSelect}
          />
        </React.Fragment>
      ))
    }
    return null
  }

  const isAllSelected = () => {
    if (!props.selectedIds.length || props.selectedIds.length < props.items.length) return false
    for (const item of props.items) {
      if (!props.selectedIds.includes(item.id)) {
        return false
      }
    }
    return true
  }

  const toggleAll = () => {
    const all = isAllSelected()
    if (all) {
      props.clearSelect()
    } else {
      // Add new items to selection
      props.selectAll(props.items.map(item => item.id))
    }
  }

  return (
    <div className={classes.tableOuterContainer}>
      <Table>
        <TableHeader
          columnNames={[t('poll_name'), t('category'), t('num_answers_short'), t('open_at'), t('publisher'), t('results')]}
          sort={props.sort}
          sortKeys={props.sortKeys}
          onSort={props.onSort}
          onSelect={toggleAll}
          selected={isAllSelected()}
        />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      <DataMessage
        loading={props.loading}
        data={props.items}
        message={t('no_polls')}
      />
    </div>
  )
}

export default PollTable
