import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Colors } from '../../Utils/theme'
import makeStyles from '@mui/styles/makeStyles'
import DotsIcon from '../../Assets/Icons/dots.svg'
import { useTranslation } from 'react-i18next';

const PHOTO_SIZE = '3rem'
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: Colors.white,
    alignItems: 'center',
    width: '100%'
  },
  photoContainer: {
    width: PHOTO_SIZE,
    height: PHOTO_SIZE,
    maxWidth: PHOTO_SIZE,
    maxHeight: PHOTO_SIZE,
    minWidth: PHOTO_SIZE,
    minHeight: PHOTO_SIZE,
    backgroundColor: Colors.backgroundGrey,
    borderRadius: PHOTO_SIZE,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    lineHeight: 1,
    userSelect: 'none'
  },
  middleContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '2rem'
  },
  name: {
    fontWeight: 900,
    textTransform: 'uppercase',
    color: Colors.navy,
    userSelect: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  email: {
    fontWeight: 300,
    color: Colors.navy,
    userSelect: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  actionsButton: {
    backgroundColor: Colors.navy,
    width: PHOTO_SIZE,
    height: PHOTO_SIZE,
    maxWidth: PHOTO_SIZE,
    maxHeight: PHOTO_SIZE,
    minWidth: PHOTO_SIZE,
    minHeight: PHOTO_SIZE,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '.75rem',
    '&:hover': {
      backgroundColor: Colors.navy80
    }
  },
  dotsIcon: {
    height: '1.75rem'
  }
}))

const HeaderUser = (props) => {
  const classes = useStyles()
  const user = props.user

  const { t } = useTranslation()

  const [menuOpen, setMenuOpen] = useState(false)
  const [menuAnchorElement, setMenuAnchorElement] = useState(null)

  const openMenu = (event) => {
    setMenuAnchorElement(event.currentTarget)
    setMenuOpen(true)
  };
  const closeMenu = () => {
    setMenuAnchorElement(null)
    setMenuOpen(false)
  };

  const renderPhoto = () => {
    const initials = `${(user.firstName || '').slice(0, 1)}${(user.lastName || '').slice(0, 1)}`.toUpperCase()
    return (
      <div className={classes.photoContainer}>
        {initials}
      </div>
    )
  }

  const renderMenu = () => {
    return (
      <div>
        <Menu
          anchorEl={menuAnchorElement}
          open={menuOpen}
          onClose={closeMenu}
        >
          <MenuItem onClick={props.logout}>{t('logout')}</MenuItem>
        </Menu>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      {renderPhoto()}
      <div className={classes.middleContainer}>
        <div className={classes.name}>{[user.firstName, user.lastName].join(' ').trim()}</div>
        <div className={classes.email}>{user.email}</div>
      </div>
      <Button className={classes.actionsButton} onClick={openMenu}>
        <img src={DotsIcon} className={classes.dotsIcon} />
      </Button>
      {renderMenu()}
    </div>
  )
}

export default HeaderUser
