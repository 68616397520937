import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import { Colors } from '../../../Utils/theme'
import { LineChart, PageHeader } from '../../../Components'
import BarChartIcon from '../../../Assets/Icons/bar-chart.svg'
import UninstallsIcon from '../../../Assets/Icons/uninstalls.svg'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    padding: '2.5rem'
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row'

  },
  headerBox: {
    flex: 1,
    border: `1px solid ${Colors.border}`,
    borderRadius: '.625rem',
    padding: '1.25rem',
    textAlign: 'center'
  },
  headerTitle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '1.25rem',
    color: Colors.navy,
    fontWeight: 900,
    textShadow: `.125rem .125rem 0 ${Colors.lightGreen}`
  },
  headerValue: {
    fontSize: '4rem',
    color: Colors.navy,
    fontWeight: 400,
    textShadow: `.125rem .125rem 0 ${Colors.lightGreen}`
  },
  headerNote: {
    color: Colors.text
  },
  horizontalSpacer: {
    width: '2rem'
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  topLeftContainer: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column'
  },
  topRightContainer: {
    paddingTop: '1rem',
    marginLeft: '2rem',
    width: '18rem',
    minWidth: '18rem',
    minHeight: '28rem',
    border: `1px solid ${Colors.border}`,
    borderRadius: '.625rem',
  },
  mapContainer: {
    marginTop: '1rem',
    widht: '100%',
    overflow: 'hidden'
  },
  mapInnerContainer: {
    width: '30rem',
    marginLeft: '-25%'
  },
  feedbacksContainer: {
    flex: 1,
    marginTop: '2rem',
    border: `1px solid ${Colors.border}`,
    borderRadius: '.625rem',
  },
  footerContainer: {
    marginTop: '2rem',
    border: `1px solid ${Colors.border}`,
    borderRadius: '.625rem'
  },


  topHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row'
    }
  },

  headerColumnContainer: {
    display: 'flex',
    flex: 1,
    border: `1px solid ${Colors.border}`,
    borderRadius: '.625rem',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  headerPrimaryContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    textAlign: 'center',
    borderBottom: `1px solid ${Colors.border}`,
    [theme.breakpoints.up('md')]: {
      borderBottom: 0,
      borderRight: `1px solid ${Colors.border}`
    }
  },
  headerSecondaryContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column'
  },
  headerSecondaryContainerRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  headerSecondaryBox: {
    padding: '.5rem 0',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  primaryTitle: {
    fontWeight: 900,
    textTransform: 'uppercase',
    fontSize: '1.25rem',
    textShadow: `.25rem .25rem 0 ${Colors.lightGreen}`,
    color: Colors.navy
  },
  primaryValue: {
    fontWeight: 300,
    fontSize: '3.75rem',
    textShadow: `.25rem .25rem 0 ${Colors.lightGreen}`,
    color: Colors.navy
  },
  barChartIcon: {
    height: '2.1875rem',
    marginRight: '.5rem'
  },
  primaryTitleRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  primarySubtitle: {
    color: Colors.inputText
  },
  headerSecondaryRowContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row'
  },
  headerHorizontalLine: {
    height: `1px`,
    backgroundColor: Colors.border
  },
  headerVerticalLine: {
    width: '1px',
    backgroundColor: Colors.border
  },
  headerSecondaryTitle: {
    fontWeight: 900,
    fontSize: '.875rem',
    color: Colors.navy
  },
  headerSecondaryValue: {
    fontSize: '2.25rem',
    color: Colors.navy
  },
  chartContainer: {
    paddingTop: '2.5rem',
    marginTop: '2rem',
    borderRadius: '.875rem',
    border: `1px solid ${Colors.border}`,
    padding: '2rem',
    position: 'relative'
  },
  chartTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '1rem',
    [theme.breakpoints.up('lg')]: {
      paddingBottom: 0,
      position: 'absolute',
      top: '2rem',
      left: '2rem'
    }
  },
  chartTitle: {
    fontSize: '1.25rem',
    fontWeight: 900,
    textShadow: `.25rem .25rem 0 ${Colors.lightGreen}`,
    textTransform: 'uppercase',
    marginRight: '.25rem',
    color: Colors.navy
  },
  chartTitleSecondary: {
    fontSize: '1.25rem',
    color: Colors.navy,
    textShadow: `.25rem .25rem 0 ${Colors.lightGreen}`
  }
}))

function UserAnalytics() {
  const classes = useStyles()

  const { sessionStore, analyticsStore }: any = useStore()
  const { t } = useTranslation()

  useEffect(() => {
    analyticsStore.getUserSummary()
  }, [])
/*
  download: {
    total: 3579,
    android: 2345,
    ios: 1234,
    change: 0.38,
    previousDay: 12
  },
  uninstall: {
    total: 123,
    android: 90,
    ios: 33,
    change: 0.12,
    previousDay: 2
  },
*/


  const formatChange = (ratio) => {
    let changePercent: any = 0
    if (ratio) {
      changePercent = Math.round(ratio * 100)
    }
    if (changePercent > 0) {
      changePercent = `+${changePercent}`
    }
    return `${changePercent}%`
  }

  // Loading
  if (!analyticsStore.userSummary) return null

  const { download, uninstall } = analyticsStore?.userSummary

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('downloads_and_users')}
        user={sessionStore.user}
        logout={() => sessionStore.logout()}
      />

      <div className={classes.container}>
        <div className={classes.topHeaderContainer}>
          <div className={classes.headerColumnContainer}>
            <div className={classes.headerPrimaryContainer}>
              <div className={classes.primaryTitleRow}>
                <img src={BarChartIcon} className={classes.barChartIcon} />
                <div className={classes.primaryTitle}>{t('downloads')}</div>
              </div>
              <div className={classes.primaryValue}>{download?.total}</div>
              <div className={classes.primarySubtitle}>{t('total_count')}</div>
            </div>
            <div className={classes.headerSecondaryContainer}>
              <div className={classes.headerSecondaryRowContainer}>
                <div className={classes.headerSecondaryBox}>
                  <div className={classes.headerSecondaryTitle}>{t('android')}</div>
                  <div className={classes.headerSecondaryValue}>{download.android}</div>
                </div>
                <div className={classes.headerVerticalLine} />
                <div className={classes.headerSecondaryBox}>
                  <div className={classes.headerSecondaryTitle}>{t('progress_last_30d')}</div>
                  <div className={classes.headerSecondaryValue}>{formatChange(download?.change)}</div>
                </div>
              </div>
              <div className={classes.headerHorizontalLine} />
              <div className={classes.headerSecondaryRowContainer}>
                <div className={classes.headerSecondaryBox}>
                  <div className={classes.headerSecondaryTitle}>{t('ios')}</div>
                  <div className={classes.headerSecondaryValue}>{download?.ios}</div>
                </div>
                <div className={classes.headerVerticalLine} />
                <div className={classes.headerSecondaryBox}>
                  <div className={classes.headerSecondaryTitle}>{t('previous_day_short')}</div>
                  <div className={classes.headerSecondaryValue}>{download?.previousDay}</div>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.headerColumnContainer}>
            <div className={classes.headerPrimaryContainer}>
              <div className={classes.primaryTitleRow}>
                <img src={UninstallsIcon} className={classes.barChartIcon} />
                <div className={classes.primaryTitle}>{t('removals')}</div>
              </div>

              <div className={classes.primaryValue}>{uninstall?.total}</div>
              <div className={classes.primarySubtitle}>{t('total_count')}</div>
            </div>
            <div className={classes.headerSecondaryContainer}>
              <div className={classes.headerSecondaryRowContainer}>
                <div className={classes.headerSecondaryBox}>
                  <div className={classes.headerSecondaryTitle}>{t('android')}</div>
                  <div className={classes.headerSecondaryValue}>{uninstall?.android}</div>
                </div>
                <div className={classes.headerVerticalLine} />
                <div className={classes.headerSecondaryBox}>
                  <div className={classes.headerSecondaryTitle}>{t('progress_last_30d')}</div>
                  <div className={classes.headerSecondaryValue}>{formatChange(uninstall?.change)}</div>
                </div>
              </div>
              <div className={classes.headerHorizontalLine} />
              <div className={classes.headerSecondaryRowContainer}>
                <div className={classes.headerSecondaryBox}>
                  <div className={classes.headerSecondaryTitle}>{t('ios')}</div>
                  <div className={classes.headerSecondaryValue}>{uninstall?.ios}</div>
                </div>
                <div className={classes.headerVerticalLine} />
                <div className={classes.headerSecondaryBox}>
                  <div className={classes.headerSecondaryTitle}>{t('previous_day_short')}</div>
                  <div className={classes.headerSecondaryValue}>{uninstall?.previousDay}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.chartContainer}>
          <div className={classes.chartTitleContainer}>
            <span className={classes.chartTitle}>{t('active_users')}</span>
            <span className={classes.chartTitleSecondary}> - {t('previous_30d_short')}</span>
          </div>
          <LineChart
            data={analyticsStore.userSummary.activeUsers.series}
          />
        </div>

      </div>
    </div>
  )
}

export default observer(UserAnalytics)
