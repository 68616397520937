import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { decode } from 'html-entities'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import striptags from 'striptags'
import linkifyHtml from 'linkify-html'

export const editorToHtml = (editorInput) => {
  if (editorInput) {
    return linkifyHtml(draftToHtml(convertToRaw(editorInput.getCurrentContent())))
  }
  return ''
}

export const htmlToText = (editorInput) => {
  if (editorInput) {
    const input: any = editorInput
    return decode(striptags(input).trim())
  }
  return ''
}

export const htmlToEditor = (htmlInput) => {
  const { contentBlocks, entityMap } = htmlToDraft(htmlInput)
  return EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap))
}
