import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import { CustomerSchema, CustomerType } from '../Schemas'

export default class CustomerStore {
  rootStore
  customers: CustomerType[] = []
  total = 0 // Number of total results
  totalFiltered = 0 // Number of total results after filtering
  categories: { id: number, name: string}[] = []
  types: { id: number, name: string}[] = []
  statuses: { id: number, name: string}[] = []
  loading = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setLoading = (loading) => { this.loading = loading }
  setCustomers = (customers) => { this.customers = customers }
  setTotal = (total) => { this.total = total }
  setTotalFiltered = (total) => { this.totalFiltered = total }
  setCategories = (categories) => { this.categories = categories }
  setTypes = (types) => { this.types = types }
  setStatuses = (statuses) => { this.statuses = statuses }

  async getCustomers(params): Promise<void> {
    const search = params.search || ''
    const take = params.perPage || 50
    const skip = (params.page || 0) * take
    const category = (params.category !== 0) ? params.category : undefined
    const type = (params.type !== 0) ? params.type : undefined
    const status = (params.status !== 0) ? params.status : undefined
    const sort = params.sort || undefined

    this.setLoading(true)
    const response: any = await Api.getCustomers({ skip, take, search, category, type, status, sort })
    if (response.ok) {
      const validCustomers: CustomerType[] = []
      response.data.items.forEach(item => {
        try {
          const customer = CustomerSchema.parse(item)
          validCustomers.push(customer)
        } catch (error) {
          console.error("Invalid customer: ", item, error)
        }
      })
      this.setCustomers(validCustomers)
      this.setTotal(response.data.total)
      this.setTotalFiltered(response.data.totalFiltered)
      this.setCategories(response.data.categories)
      this.setTypes(response.data.types)
      this.setStatuses(response.data.statuses)
    }
    this.setLoading(false)
  }

  async getCustomer(id: number): Promise<CustomerType | null> {
    this.setLoading(true)
    const customer = this.customers.find(customer => customer.id === id)
    if (customer !== undefined) {
      return customer
    } else {
      const response: any = await Api.getCustomer(id)
      if (response.ok) {
        try {
          const customer = CustomerSchema.parse(response.data)
          this.setCustomers([...this.customers, customer])
          return customer
        } catch (error) {
          console.error("Invalid customer: ", response.data, error)
        }
      }
    }
    this.setLoading(false)
    return null
  }
}
