import Chart from 'react-apexcharts'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
}))

export default function AreaChart(props) {
  const classes = useStyles()

  const getOptions = () => {
    return (
      {
        legend: {
          position: 'top',
          offsetX: -10,
          offsetY: 0
        },
        dataLabels: {
          enabled: false
        },
        colors: [Colors.chart1, Colors.chart2, Colors.chart3],
        stroke: {
          width: 3
        },
        plotOptions: {
          bar: {
            borderRadius: 11
          }
        },
        chart: {
          animations: { enabled: false },
          stacked: props.stacked ? true : false,
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: props.data[0].data.map(i => i.label),
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
          labels: {
            show: true
          },
        },
        yaxis: {
          opposite: true,
          // floating: true,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
          labels: {
            show: true
          },
        }
      }
    )
  }

  const getSeries = () => {
    return props.data.map(item => {
      return {
        name: item.name,
        data: item.data.map(i => i.value)
      }
    })
  }

  return (
    <Chart
      options={getOptions()}
      series={getSeries()}
      type='area'
      width={'100%'}
      height={'200'}
    />
  )
}
