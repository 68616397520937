import React from 'react'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'

enum TitleTypes {
  Title = 'title',
  Main = 'main',
  Subtitle = 'subtitle',
  Widget = 'widget'
}

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    margin: 0,
    fontFamily: 'Ambit',
    fontSize: '2.5rem',
    fontWeight: 700,
    color: Colors.black,
    marginBottom: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem'
    }
  },
  title: {
    margin: 0,
    fontFamily: 'Ambit',
    fontSize: '2.5rem',
    fontWeight: 400,
    color: Colors.black,
    marginBottom: '1.5rem'
  },
  subtitle: {
    fontFamily: 'Ambit',
    fontWeight: 600,
    color: Colors.navy,
    fontSize: '1.675rem',
    paddingBottom: '0.325rem',
    marginTop: '1.375rem',
    marginBottom: '1.5rem',
    borderBottom: `2px solid ${Colors.border}`
  },
  widget: {
    margin: 0,
    fontFamily: 'Ambit',
    fontSize: '1.5rem',
    fontWeight: 500,
    color: Colors.black,
    marginBottom: '1.5rem'
  },
  description: {
    margin: 0,
    fontFamily: 'Ambit',
    fontSize: '1.125rem',
    fontWeight: 300,
    color: Colors.black,
    marginBottom: '2rem'
  }
}))

export default function Title(props) {
  const classes = useStyles()

  const renderTitle = () => {
    const { type, title } = props
    if (type && type === TitleTypes.Main) {
      return <h1 className={classes.mainTitle}>{title}</h1>
    }
    if (type && type === TitleTypes.Subtitle) {
      return <h3 className={classes.subtitle}>{title}</h3>
    }
    if (type && type === TitleTypes.Widget) {
      return <h3 className={classes.widget}>{title}</h3>
    }
    return <h2 className={classes.title}>{title}</h2>
  }

  const renderDescription = () => {
    if (props.description) {
      const { descriptionStyle = {} } = props
      return (
        <p className={clsx(classes.description, descriptionStyle)}>
          {props.description}
        </p>
      )
    }
    return null
  }

  return (
    <>
      {props.spacer && <div style={{ height: props.spacer }} />}
      {renderTitle()}
      {renderDescription()}
    </>
  )
}
