import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import IconButton from '@mui/material/IconButton'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import { Colors } from '../../../Utils/theme'
import { ContentUsageTable, DonutChart, HorizontalBarChart, PageHeader, Select } from '../../../Components'

import ChevronLeftIcon from '../../../Assets/Icons/chevron-left.svg'
import ChevronRightIcon from '../../../Assets/Icons/chevron-right.svg'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    padding: '2.5rem'
  },
  headerBoxesContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    },
    gap: '2rem'
  },
  headerBox: {
    flex: 1,
    border: `1px solid ${Colors.border}`,
    borderRadius: '.625rem',
    padding: '.5rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerBoxTitle: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: '1.125rem',
    textTransform: 'uppercase',
    textShadow: `.25rem .25rem 0 ${Colors.lightGreen}`
  },
  headerBoxValue: {
    fontSize: '4rem',
    textShadow: `.25rem .25rem 0 ${Colors.lightGreen}`
  },
  headerBoxText: {
    color: Colors.inputText
  },
  chevronIcon: {
    height: '1rem'
  },
  actionsRow: {
    display: 'flex',
    marginBottom: '2rem'
  },
  actionsSelectCotainer: {
    width: '10rem',
    margin: '0 1rem'
  },
  actionsSelectCotainerWide: {
    width: '12rem',
    margin: '0 1rem'
  },
  flex: {
    flex: 1
  },
  directionButton: {
    border: `1px solid ${Colors.border}`,
    borderRadius: '.625rem',
    height: '3.125rem',
    width: '3.125rem'
  },
  middleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2rem',
    gap: '2rem',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  chartContainer: {
    flex: 1,
    border: `1px solid ${Colors.border}`,
    borderRadius: '.625rem',
    padding: '2rem'
  },
  chartTitle: {
    fontSize: '1.25rem',
    fontWeight: 900,
    textTransform: 'uppercase',
    textShadow: `.25rem .25rem 0 ${Colors.lightGreen}`
  },
  chartSubtitle: {
    color: Colors.text,
    fontSize: '.875rem'
  },
  tableContainer: {
    marginTop: '2rem',
    borderRadius: '.625rem',
    border: `1px solid ${Colors.border}`,
  },
  tableInnerContainer: {
    marginLeft: '-1px',
    marginRight: '-1px',
    marginBottom: '-1px',
    borderBottomLeftRadius: '1rem',
    borderBottomRightRadius: '1rem'
  },
  tableHeaderContainer: {
    padding: '2rem',
  }
}))

enum CustomerType {
  all = 'all',
  private = 'private',
  business = 'business'
}

enum TimePeriod {
  month = 'month'
}

function UsageAnalytics() {
  const classes = useStyles()

  const { sessionStore, analyticsStore }: any = useStore()

  const { t } = useTranslation()

  const [timePeriod, setTimePeriod] = useState(TimePeriod.month)
  const [customerType, setCustomerType] = useState(CustomerType.all)

  const handleSetTimePeriod = (item) => {
    setTimePeriod(item.value)
  }

  const handleSetCustomerType = (item) => {
    setCustomerType(item.value)
  }

  const renderActions = () => {
    return (
      <div className={classes.actionsRow}>
        <IconButton
          onClick={() => null}
          className={classes.directionButton}
          size='large'
        >
          <img src={ChevronLeftIcon} className={classes.chevronIcon} alt='right' />
        </IconButton>

        <div className={classes.actionsSelectCotainer}>
          <Select
            options={[
              { label: t('previous_30d'), value: TimePeriod.month }
            ]}
            onChange={handleSetTimePeriod}
            value={timePeriod}
            noMargin
          />
        </div>

        <IconButton
          onClick={() => null}
          className={classes.directionButton}
          size="large"
        >
          <img src={ChevronRightIcon} className={classes.chevronIcon} alt='right' />
        </IconButton>
        <div className={classes.flex} />
        <div className={classes.actionsSelectCotainerWide}>
          <Select
            options={[
              { label: t('all_customers'), value: CustomerType.all },
              { label: t('private_persons'), value: CustomerType.private },
              { label: t('business_users'), value: CustomerType.business }
            ]}
            value={customerType}
            onChange={handleSetCustomerType}
            noMargin
          />
        </div>
      </div>
    )
  }

  const renderHeaderBoxes = () => {
    return (
      <div className={classes.headerBoxesContainer}>
        <div className={classes.headerBox}>
          <div className={classes.headerBoxTitle}>{t('orders')}</div>
          <div className={classes.headerBoxValue}>123</div>
          <div className={classes.headerBoxText}>+3% edel.pv</div>
        </div>
        <div className={classes.headerBox}>
          <div className={classes.headerBoxTitle}>{t('contact_requests_short')}</div>
          <div className={classes.headerBoxValue}>123</div>
          <div className={classes.headerBoxText}>+4% edel.pv</div>
        </div>
        <div className={classes.headerBox}>
          <div className={classes.headerBoxTitle}>{t('new_customers')}</div>
          <div className={classes.headerBoxValue}>123</div>
          <div className={classes.headerBoxText}>+5% edel.pv</div>
        </div>
        <div className={classes.headerBox}>
          <div className={classes.headerBoxTitle}>{t('feedbacks')}</div>
          <div className={classes.headerBoxValue}>123</div>
          <div className={classes.headerBoxText}>+21% edel.pv</div>
        </div>
        <div className={classes.headerBox}>
          <div className={classes.headerBoxTitle}>{t('recommendations')}</div>
          <div className={classes.headerBoxValue}>123</div>
          <div className={classes.headerBoxText}>+12% edel.pv</div>
        </div>
      </div>
    )
  }

  const renderUserRoles = () => {
    return (
      <div className={classes.chartContainer}>
        <div className={classes.chartTitle}>{t('user_roles')}</div>
        <div className={classes.chartSubtitle}>{t('from_app_usage_times')}</div>
        <DonutChart />
      </div>
    )
  }

  const renderDemographics = () => {
    return (
      <div className={classes.chartContainer}>
          <div className={classes.chartTitle}>{t('user_roles')}</div>
          <div className={classes.chartSubtitle}>{t('from_app_usage_times')}</div>
          <HorizontalBarChart
            labels={['Mies', 'Nainen']}
            stacked
          />
      </div>
    )
  }

  const renderAppVersions = () => {
    return (
      <div className={classes.chartContainer}>
          <div className={classes.chartTitle}>{t('app_versions')}</div>
          <div className={classes.chartSubtitle}>{t('ios_and_android')}</div>
          <HorizontalBarChart
            labels={['iOS', 'Android']}
            versions
            stacked
          />
      </div>
    )
  }

  const getContentUsageItems = () => {
    // Mock data
    return [
      {
        screenName: 'Aloutusnäkymä',
        usageShare: '27.6%',
        usageShareChange: '+3.2%',
        usageTime: '48s',
        viewCount: '213',
        eventCount: '123',
        conversion: '21.4%'
      }
    ]
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('usage_analytics')}
        user={sessionStore.user}
        logout={() => sessionStore.logout()}
      />

      <div className={classes.container}>
        {renderActions()}
        {renderHeaderBoxes()}
        <div className={classes.middleContainer}>
          {renderUserRoles()}
          {renderDemographics()}
          {renderAppVersions()}
        </div>

        <div className={classes.tableContainer}>
          <div className={classes.tableHeaderContainer}>
            <div className={classes.chartTitle}>{t('content_section_usage_shares')}</div>
            <div className={classes.chartSubtitle}>{t('percentage_and_time')}</div>
          </div>
          <div className={classes.tableInnerContainer}>
            <ContentUsageTable
              loading={analyticsStore.loading}
              items={getContentUsageItems()}
            />
          </div>
        </div>
      </div>


    </div>
  )
}

export default observer(UsageAnalytics)
