import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class FileStore {
  rootStore

  loading: any = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateLoading = (loading) => { this.loading = loading }

  async uploadPhoto(data) {
    this.updateLoading(true)
    const response = await Api.uploadPhoto(data)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    if (response.ok) {
      return response.data.url
    }
    return null
  }
}
