import React, { ReactElement, useEffect, useState } from 'react'
import { Checkbox, Dialog, Input, Select } from '../index'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../Models/RootStore'
import Grid from '@mui/material/Grid'
import { UserType } from '../../Schemas'

const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: '300px'
  },
  checkBoxContainer: {
    marginTop: '2rem'
  },
  errorsContainer: {
    marginBottom: '1rem'
  },
  error: {
    color: theme.palette.error.main,
    marginBottom: '0.25rem'
  }
}))

interface UserProps {
  firstName: string
  lastName: string
  email: string
  password: string
  role: string
  active: boolean
}

interface Props {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  user: UserType | null
}

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  role: 'admin',
  active: true
}

const UserDialog = ({ open, onClose, onSuccess, user }: Props): ReactElement => {
  const { userStore }: any = useStore()
  const { t } = useTranslation()
  const classes = useStyles()
  const [form, setForm] = useState<UserProps>(defaultValues)
  const [errors, setErrors] = useState<string[]>([])

  useEffect(() => {
    if (user === null) {
      setForm(defaultValues)
    } else {
      setForm({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        role: user.role,
        active: user.active,
        password: ''
      })
    }
  }, [user])

  const handleClose = (): void => {
    setErrors([])
    if (user === null) {
      setForm(defaultValues)
    }
    onClose()
  }

  const handleConfirm = async (): Promise<void> => {
    let result
    if (user) {
      result = await userStore.updateUser(user.id, form)
    } else {
      result = await userStore.createUser(form)
    }
    if (result === true){
      setErrors([])
      setForm(defaultValues)
      onSuccess()
    } else {
      setErrors(result)
    }
  }

  const handleChange = (key: string, value: string | boolean): void => {
    setForm({ ...form, [key]: value })
  }

  const roleOptions = [
    {
      label: 'Admin',
      value: 'admin'
    },
    {
      label: 'User',
      value: 'user'
    }
  ]

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      handleOk={handleConfirm}
      title={user === null ? t('create_user') : t('update_user')}
      okText={t('save')}
      loading={userStore.loading}
    >
      <div className={classes.content}>
        {errors.length > 0 && (
          <div className={classes.errorsContainer}>
            {errors.map(error => <div className={classes.error}>{error}</div>)}
          </div>
        )}
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Select
              label={t('role')}
              options={roleOptions}
              value={form.role}
              onChange={value => handleChange('role', value)}
              noMargin
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <div className={classes.checkBoxContainer}>
              <Checkbox
                label={t('active')}
                checked={form.active}
                onChange={() => handleChange('active', !form.active)}
                noMargin
              />
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              value={form.firstName}
              onChange={value => handleChange('firstName', value)}
              label={t('first_name')}
              noMargin
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              value={form.lastName}
              onChange={value => handleChange('lastName', value)}
              label={t('last_name')}
              noMargin
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              value={form.email}
              onChange={value => handleChange('email', value)}
              label={t('email')}
              noMargin
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              type='password'
              autoComplete='new-password'
              value={form.password}
              onChange={value => handleChange('password', value)}
              label={t('password')}
              noMargin
            />
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

export default UserDialog
