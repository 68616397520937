import { makeAutoObservable } from 'mobx'
import { Api, MockApi } from '../Services'

export default class AnalyticsStore {
  rootStore
  customersSummary = null
  employeesSummary = null

  userSummary = null

  loading = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setLoading = (loading) => { this.loading = loading }
  setCustomersSummary = (customersSummary) => { this.customersSummary = customersSummary }
  setEmployeesSummary = (employeesSummary) => { this.employeesSummary = employeesSummary }
  setUserSummary = (userSummary) => { this.userSummary = userSummary }


  async getCustomersSummary(date: string) {
    this.setLoading(true)
    const response: any = await Api.getCustomersSummary(date)
    if (response.ok) {
      this.setCustomersSummary(response.data)
    }
    this.setLoading(false)
  }
  async getEmployeesSummary(date: string) {
    this.setLoading(true)
    const response: any = await Api.getEmployeesSummary(date)
    if (response.ok) {
      this.setEmployeesSummary(response.data)
    }
    this.setLoading(false)
  }

  async getUserSummary(startDate: string, endDate: string) {
    this.setLoading(true)
    const response: any = await MockApi.getUserSummary(startDate, endDate)
    if (response.ok) {
      this.setUserSummary(response.data)
    }
    this.setLoading(false)
  }
}
