import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import {
  ShiftTable,
  PageContainer,
  PageHeader,
  PageContent,
  IndexActions,
  TableHeaderActions
} from '../../../Components'
import { useLocation, useNavigate } from 'react-router-dom'
import { ShiftType } from '../../../Schemas'
import ShiftFilterDialog from '../../../Components/Shift/ShiftFilterDialog'
import FilterButtons from '../../../Components/Common/FilterButtons'
import useFormatter from '../../../Hooks/useFormatter'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    padding: '2.5rem'
  }
}))

const SORT_KEYS = ['startDate', 'duration', 'customerName', 'taskName', 'priceArea']
const SORT_DIRECTIONS = ['asc', 'desc']

interface QueryParams {
  search: string
  perPage: number
  sort: string
  type: number
  status: number
  startDate: string
  endDate: string
  startTime: string
  endTime: string
}

function Shifts() {
  const classes = useStyles()

  const { sessionStore, shiftStore }: any = useStore()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const { formatDate } = useFormatter()

  const [queryParams, setQueryParams] = useState<QueryParams>({
    search: '',
    perPage: 10,
    sort: `${SORT_KEYS[0]}_${SORT_DIRECTIONS[0]}`,
    type: 0,
    status: 0,
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: ''
  })

  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [showFilter, setShowFilter] = useState(false)

  const handleToggleSelect = (id: number): void => {
    // Try remove
    const newIds: number[] = selectedIds.filter(val => val !== id)
    if (newIds.length !== selectedIds.length) {
      setSelectedIds(newIds)
    } else {
      // Add new
      setSelectedIds([...selectedIds, id])
    }
  }
  const handleSelectAll = (): void => {
    setSelectedIds(shiftStore.shifts.map((shift: ShiftType) => shift.id))
  }
  const handleClearSelect = (): void => setSelectedIds([])

  useEffect(() => {
    // Handle setting queryParams when returning from customer screen
    const params = (location.state as any)?.queryParams
    if (params !== undefined) {
      setQueryParams(params)
    }
    // Clear state for page refresh to clear all queryParams
    navigate('')
  }, [])

  useEffect(() => {
    refreshItems()
  }, [queryParams])

  const refreshItems = () => {
    shiftStore.getShifts({
      startDate: queryParams.startDate,
      endDate: queryParams.endDate,
      startTime: queryParams.startTime,
      endTime: queryParams.endTime,
      type: queryParams.type,
      status: queryParams.status,
      search: queryParams.search,
      perPage: queryParams.perPage,
      page: 0,
      sort: queryParams.sort
    })
  }

  const handleQueryParamsChange = (key: string, value: string | number): void => {
    setQueryParams({...queryParams, [key]: value})
  }

  const getSortOptions = () => {
    const options: any = []
    for (const direction of SORT_DIRECTIONS) {
      for (const sortKey of SORT_KEYS) {
        const fullSortKey = `${sortKey}_${direction}`
        options.push({
          value: fullSortKey,
          label: t(`sort_${fullSortKey}`)
        })
      }
    }
    return options
  }

  const handleFilterConfirm = (params: {
    type: number, status: number, startDate: string, endDate: string
  }): void => {
    setQueryParams({...queryParams, ...params})
    setShowFilter(false)
  }

  const resolveFilterButtonItems = (): { key: string, label: string, value: string }[] => {
    const buttons: { key: string, label: string, value: string }[] = []
    if (queryParams.type !== 0) {
      const name = shiftStore.types.find(item => item.id === queryParams.type)?.name
      buttons.push({ key: 'type', label: t('type'), value: t(`shift_type_${name}`) })
    }
    if (queryParams.status !== 0) {
      const name = shiftStore.statuses.find(item => item.id === queryParams.status)?.name
      buttons.push({ key: 'status', label: t('status'), value: t(`shift_status_${name}`) })
    }
    if (queryParams.startDate !== '') {
      buttons.push({ key: 'startDate', label: t('start_date'), value: formatDate(queryParams.startDate) })
    }
    if (queryParams.endDate !== '') {
      buttons.push({ key: 'endDate', label: t('end_date'), value: formatDate(queryParams.endDate) })
    }
    if (queryParams.startTime !== '') {
      buttons.push({ key: 'startTime', label: t('start_time'), value: queryParams.startTime })
    }
    if (queryParams.endTime !== '') {
      buttons.push({ key: 'endTime', label: t('end_time'), value: queryParams.endTime })
    }
    return buttons
  }

  const handleFilterDisable = (key: string): void => {
    switch (key) {
      case 'type':
        setQueryParams({...queryParams, type: 0})
        break
      case 'status':
        setQueryParams({...queryParams, status: 0})
        break
      case 'startDate':
        setQueryParams({...queryParams, startDate: ''})
        break
      case 'endDate':
        setQueryParams({...queryParams, endDate: ''})
        break
      case 'startTime':
        setQueryParams({...queryParams, startTime: ''})
        break
      case 'endTime':
        setQueryParams({...queryParams, endTime: ''})
        break
    }
  }

  const handleShiftOpen = (id: number): void => {
    navigate(`/shifts/${id}`, { state: { queryParams }})
  }

  return (
    <PageContainer>
      <PageHeader
        title={t('shifts')}
        user={sessionStore.user}
        logout={() => sessionStore.logout()}
        total={shiftStore.total}
      />
      <PageContent>
        <IndexActions
          sort={queryParams.sort}
          setSort={(sort: string) => handleQueryParamsChange('sort', sort)}
          sortOptions={getSortOptions()}
        />
        <FilterButtons
          items={resolveFilterButtonItems()}
          onDisable={handleFilterDisable}
        />
        <TableHeaderActions
          search={queryParams.search}
          setSearch={(search: string) => handleQueryParamsChange('search', search)}
          perPage={queryParams.perPage}
          setPerPage={(perPage: number) => handleQueryParamsChange('perPage', perPage)}
          onFilter={() => setShowFilter(state => !state)}
          total={shiftStore.totalFiltered}
        />
        <ShiftTable
          loading={shiftStore.loading}
          items={shiftStore.shifts}
          sort={queryParams.sort}
          sortKeys={SORT_KEYS}
          onSort={(sort: string) => handleQueryParamsChange('sort', sort)}
          selectedIds={selectedIds}
          onToggleSelect={handleToggleSelect}
          onSelectAll={handleSelectAll}
          onClearSelect={handleClearSelect}
          onShiftOpen={handleShiftOpen}
        />
      </PageContent>
      <ShiftFilterDialog
        open={showFilter}
        onClose={() => setShowFilter(false)}
        onConfirm={handleFilterConfirm}
        filterParams={queryParams}
      />
    </PageContainer>
  )
}

export default observer(Shifts)
