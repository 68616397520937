import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import { EmployeeSchema, EmployeeType } from '../Schemas/EmployeeSchema'

export default class EmployeeStore {
  rootStore
  employees: EmployeeType[] = []
  total = 0 // Number of total results
  totalFiltered = 0 // Number of total results filtered
  contractTypes = []
  loading = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setLoading = (loading) => { this.loading = loading }
  setEmployees = (employees) => { this.employees = employees }
  setTotal = (total) => { this.total = total }
  setTotalFiltered = (totalFiltered) => { this.totalFiltered = totalFiltered }
  setContractTypes = (types) => { this.contractTypes = types }

  async getEmployees(params) {
    const search = params.search || ''
    const take = params.perPage || 50
    const skip = (params.page || 0) * take
    const contractType = (params.type !== 0) ? params.type : undefined
    const sort = params.sort || undefined

    this.setLoading(true)
    const response: any = await Api.getEmployees({ skip, take, search, contractType, sort })
    if (response.ok) {
      const validEmployees: EmployeeType[] = []
      response.data.items.forEach(item => {
        try {
          const employee = EmployeeSchema.parse(item)
          validEmployees.push(employee)
        } catch (error) {
          console.error("Invalid employee: ", item, error)
        }
      })
      this.setEmployees(validEmployees)
      this.setTotal(response.data.total)
      this.setTotalFiltered(response.data.totalFiltered)
      this.setContractTypes(response.data.contractTypes)
    }
    this.setLoading(false)
  }

  async getEmployee(id: number): Promise<EmployeeType | null> {
    this.setLoading(true)
    const response: any = await Api.getEmployee(id)
    if (response.ok) {
      try {
        return EmployeeSchema.parse(response.data)
      } catch (error) {
        console.error("Invalid employee: ", response.data, error)
      }
    }
    this.setLoading(false)
    return null
  }
}
