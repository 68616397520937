import { z } from 'zod'
import { CustomerSchema } from './CustomerSchema'
import { EmployeeSchema } from './EmployeeSchema'
import { PricableItemSchema } from './PricableItemSchema'

export const ShiftSchema = z.object({
  id: z.number(),
  extId: z.number(),
  extCreatedAt: z.string().nullable().transform((val) => val ? new Date(val) : null),
  extUpdatedAt: z.string().nullable().transform((val) => val ? new Date(val) : null),
  status: z.string(),
  shiftType: z.string(),
  startDate: z.string().transform((val) => val ? new Date(val) : null),
  endDate: z.string().transform((val) => val ? new Date(val) : null),
  manualPricableItemName: z.string(),
  minHours: z.string(),
  additionalInfo: z.string(),
  employeeRegistrationComment: z.string(),
  note: z.string(),
  internalNote: z.string(),
  customerNote: z.string(),
  paidAt: z.string().nullable().transform((val) => val ? new Date(val) : null),
  billedAt: z.string().nullable().transform((val) => val ? new Date(val) : null),
  createdAt: z.string().nullable().transform((val) => val ? new Date(val) : null),
  updatedAt: z.string().nullable().transform((val) => val ? new Date(val) : null),
  price: z.object({
    extId: z.number().nullable(),
    extProjectId: z.number().nullable(),
    extPricableItemId: z.number().nullable(),
    priceList: z.any(),
    type: z.string(),
    area: z.string(),
    unitPrice: z.string(),
    unit: z.string(),
    usePriceForWeekday: z.number().nullable(),
    weekday: z.number().nullable(),
    startDate: z.string().nullable().transform((val) => val ? new Date(val) : null),
    endDate: z.string().nullable().transform((val) => val ? new Date(val) : null),
    description: z.string(),
    active: z.boolean(),
    discount: z.string()
  }).optional(),
  salary: z.object({
    extId: z.number().nullable(),
    extProjectId: z.number().nullable(),
    extPayablePricableItemId: z.number().nullable(),
    type: z.string(),
    area: z.string(),
    salary: z.string(),
    startDate: z.string().nullable().transform((val) => val ? new Date(val) : null),
    endDate: z.string().nullable().transform((val) => val ? new Date(val) : null),
    description: z.string(),
    active: z.boolean()
  }).optional(),
  customerName: z.string().optional().nullable(),
  customer: CustomerSchema.optional().nullable(),
  employee: EmployeeSchema.optional().nullable(),
  pricableItem: PricableItemSchema.optional().nullable()
})

export type ShiftType = z.infer<typeof ShiftSchema>
