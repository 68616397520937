import { UserType } from '../../Schemas'
import React, { ReactElement } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    backgroundColor: Colors.white,
    '&:hover': {
      backgroundColor: `${Colors.tableHover} !important`
    },
    borderBottom: `1px solid ${Colors.border}`
  },
  cell: {
    fontFamily: 'Ambit',
    fontSize: '1.25rem',
    fontWeight: 400,
    color: Colors.black,
    borderBottom: 'none',
    height: '4.25rem'
  },
  secondary: {
    color: Colors.secondaryText
  },
  checkboxContainer: {
    paddingLeft: '.25rem',
    maxWidth: '2rem'
  }
}))

interface Props {
  item: UserType,
  onClick: (id: number) => void
}

const UserTableRow = ({ item, onClick }: Props): ReactElement => {
  const classes = useStyles()

  return (
    <TableRow
      classes={{ root: classes.root }}
      onClick={() => onClick(item.id)}
      hover
    >
      <TableCell key={`row${item.id}_name`} className={classes.cell}>
        <span>{`${item.firstName} ${item.lastName}`.trim()}</span>
      </TableCell>
      <TableCell key={`row${item.id}_role`} className={classes.cell}>
        <span className={classes.secondary}>{item.role}</span>
      </TableCell>
      <TableCell key={`row${item.id}_email`} className={classes.cell}>
        <span className={classes.secondary}>{item.email}</span>
      </TableCell>
    </TableRow>
  )
}

export default UserTableRow
