import { z } from 'zod'

export const ContractSchema = z.object({
  id: z.number(),
  weeklyHours: z.number(),
  periodHours: z.number(),
  periodPartTimeMultiplier: z.number(),
  contractType: z.string(),
  startDate: z.string().transform((val) => val ? new Date(val) : null),
  endDate: z.string().nullable().transform((val) => val ? new Date(val) : null),
  createdAt: z.string().transform((val) => val ? new Date(val) : null),
  updatedAt: z.string().transform((val) => val ? new Date(val) : null)
})

export type ContractType = z.infer<typeof ContractSchema>
