import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginBottom: '1.5rem'
  },
  noMarginContainer: {
    width: '100%'
  },
  item: {
    fontFamily: 'Ambit',
    fontWeight: 500,
    fontSize: '1rem',
    color: Colors.inputText
  },
  label: {
    fontSize: '1rem',
    fontWeight: 400,
    margin: 0,
    marginBottom: '0.5rem',
    color: Colors.label
  }
}))

const STYLES = {
  select: {
    minWidth: '4.125rem',
    paddingRight: '1rem',
    height: '3.125rem',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Ambit',
    fontWeight: 500,
    fontSize: '1rem',
    color: Colors.inputText,
    backgroundColor: Colors.white,
    borderRadius: '.625rem',
    '.MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${Colors.border} !important`
    },
    '& > div': {
      padding: '0rem 0.875rem !important'
    },
    '&:focus': {
      // backgroundColor: Colors.white
    },
    '& .MuiSvgIcon-root': {
      color: Colors.navy
    }
  },
  selectDisabled: {
    height: '3.125rem',
    paddingRight: '1rem',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Ambit',
    fontWeight: 400,
    fontSize: '1rem',
    color: Colors.inputText,
    backgroundColor: Colors.white,
    borderRadius: '.625rem',
    '.MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${Colors.border} !important`
    },
    '& > div': {
      padding: '0rem 0.875rem !important'
    }
  }
}

export default function BaseSelect(props) {
  const { options, error, containerStyle, style, disabled } = props

  const classes = useStyles()

  const getSelectStyle = () => {
    if (disabled) {
      return style ? { ...STYLES.selectDisabled, ...style } : STYLES.selectDisabled
    }
    return style ? { ...STYLES.select, ...style } : STYLES.select
  }

  const onChange = (evt) => {
    console.log(evt)
    props.onChange(evt.target.value)
  }

  const renderLabel = () => {
    if (props?.label) {
      return <p className={classes.label}>{props.label}</p>
    }
    return null
  }

  const renderHelperText = () => {
    if (props.helperText) {
      return <FormHelperText>{props.helperText}</FormHelperText>
    }
    return null
  }

  return (
    <>
      {renderLabel()}
      <FormControl variant='outlined' className={props.noMargin ? classes.noMarginContainer : classes.container} sx={containerStyle}>
        <Select
          labelId='select-label'
          id='select-outlined'
          value={props.value}
          onChange={onChange}
          label={undefined}
          sx={getSelectStyle()}
          disabled={disabled}
          error={error}
          variant='outlined'
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value} classes={{ root: classes.item }}>
              {option.label || option.labelFi}
            </MenuItem>
          ))}
        </Select>
        {renderHelperText()}
      </FormControl>
    </>
  )
}
