import React, { ReactElement } from 'react'
import { EmployeeType } from '../../Schemas'
import makeStyles from '@mui/styles/makeStyles'
import { Input, TableHeader, Title } from '../index'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import useFormatter from '../../Hooks/useFormatter'

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: '3rem'
  }
}))

interface Props {
  employee: EmployeeType
  onChange: (key: string, value: string | number) => void
}

const EmployeeContracts = ({ employee, onChange }: Props): ReactElement => {
  const { t } = useTranslation()
  const { formatDate } = useFormatter()
  const classes = useStyles()

  const ContractInfo = <div className={classes.section}>
    <Title title={t('employee_contracts_title')} type="main" />
    <Grid container spacing={4}>
      <Grid item md={6} xs={12}>
        <Input
          value={t(`contract_type_${employee.contractType}`)}
          onChange={value => onChange('contractType', value)}
          label={t('contract_type')}
          noMargin
          disabled
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Input
          value={t(`contract_status_${employee.contractStatus}`)}
          onChange={value => onChange('contractStatus', value)}
          label={t('contract_status')}
          noMargin
          disabled
        />
      </Grid>
    </Grid>
  </div>

  const ContractsTable = <div className={classes.section}>
    <Title title={t('contracts')} type="main" />
    <Table>
      <TableHeader
        columnNames={[t('start_date'), t('end_date'), t('type'),  t('weekly_hours'),  t('period_hours')]}
      />
      <TableBody>
        {employee.contracts?.map(contract => (
          <TableRow>
            <TableCell key={`contract_row${contract.id}_start_time`}>
              <span>{formatDate(contract.startDate)}</span>
            </TableCell>
            <TableCell key={`contract_row${contract.id}_end_time`}>
              <span>{formatDate(contract.endDate)}</span>
            </TableCell>
            <TableCell key={`contract_row${contract.id}_type`}>
              <span>{t(`contract_type_${contract.contractType}`)}</span>
            </TableCell>
            <TableCell key={`contract_row${contract.id}_weekly_hours`}>
              <span>{contract.weeklyHours}</span>
            </TableCell>
            <TableCell key={`contract_row${contract.id}_period_hours`}>
              <span>{contract.periodHours}</span>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>

  return (
    <>
      {ContractInfo}
      {Array.isArray(employee.contracts) && employee.contracts.length > 0 && ContractsTable}
    </>
  )
}

export default EmployeeContracts
