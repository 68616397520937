import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import makeStyles from '@mui/styles/makeStyles'
import { Dialog } from '../../Components'
import { lighten } from '@mui/material/styles'
import { Colors } from '../../Utils/theme'
import closeIcon from '../../Assets/Icons/close.svg'
import BigPlus from '../../Assets/Icons/big-plus.svg'
import UpArrow from '../../Assets/Icons/up-arrow.svg'
import DownArrow from '../../Assets/Icons/down-arrow.svg'
import GreenCheck from '../../Assets/Icons/green-check.svg'
import RedAlert from '../../Assets/Icons/red-alert.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    border: `2px solid ${Colors.border}`,
    padding: '.5rem',
    marginBottom: '1.5rem',
    position: 'relative',
    borderRadius: '.3125rem'
  },
  minimizedRoot: {
    backgroundColor: Colors.lightGrey
  },
  addButton: {
    color: Colors.navy,
    textDecoration: 'underline',
    fontWeight: 700,
    cursor: 'pointer',
    textAlign: 'center',
    display: 'inline-block',
    padding: '1rem',
    '&:hover': {
      color: lighten(Colors.navy, 0.25)
    }
  },
  smallAddButton: {
    fontSize: '0.825rem',
    color: Colors.navy,
    textDecoration: 'underline',
    fontWeight: 700,
    cursor: 'pointer',
    textAlign: 'center',
    display: 'inline-block',
    marginTop: '0.75rem',
    marginBottom: '-0.5rem',
    '&:hover': {
      color: lighten(Colors.navy, 0.25)
    }
  },
  changeButtonText: {
    fontSize: '1rem',
    fontWeight: 400
  },
  photoContainer: {
    borderRadius: '.625rem',
    position: 'relative'
  },
  photoInnerContainer: {
    width: '100%',
    overflow: 'hidden',
    margin: 0,
    paddingTop: '56.25%',
    position: 'relative',
    borderRadius: '.3125rem'
  },
  photo: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  emptyPhoto: {
    cursor: 'pointer',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    maxWidth: '100%',
    height: 'auto',
    backgroundColor: Colors.black05
  },
  removePhoto: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2rem',
    height: '2rem',
    bottom: '1rem',
    right: '.75rem',
    backgroundColor: Colors.black,
    '&:hover': {
      backgroundColor: Colors.black
    }
  },
  closeIcon: {
    height: '1.5rem',
    width: 'auto',
    maxHeight: '1.5rem',
    maxWidth: '1.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  leftColumn: {
    flex: 2
  },
  rightColumn: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    paddingLeft: '1.125rem'
  },
  description: {
    fontFamily: 'Ambit',
    fontWeight: 500,
    fontSize: '0.875rem',
    color: Colors.inputText,
    textAlign: 'center',
    paddingTop: '1rem',
    maxWidth: '10.5rem'
  },
  smallDescription: {
    fontFamily: 'Ambit',
    fontWeight: 500,
    fontSize: '0.675rem',
    color: Colors.inputText,
    textAlign: 'center',
    paddingTop: '0.75rem',
    maxWidth: '10.5rem'
  },
  plusIcon: {
    opacity: 0.2
  },
  toggleContainer: {
    padding: '1rem',
    borderRadius: '.3125rem',
    backgroundColor: Colors.black05,
    height: '2rem',
    width: '2rem',
    position: 'absolute',
    top: '.5rem',
    right: '.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.black10
    }
  },
  toggleMinimizedIcon: {
    userSelect: 'none'
  },
  minimizedContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: '.25rem',
    minHeight: '2rem'
  },
  statusText: {
    fontSize: '1rem',
    fontWeight: 500,
    color: Colors.inputText,
    paddingLeft: '.5rem'
  },
  flex: {
    flex: 1
  },
  actionText: {
    color: Colors.navy,
    textDecoration: 'underline',
    fontWeight: 700,
    cursor: 'pointer',
    padding: '0 .25rem',
    marginRight: '2.75rem',
    '&:hover': {
      color: lighten(Colors.navy, 0.25)
    }
  },
  statusIcon: {
    width: '1.5rem',
    height: '1.5rem'
  }
}))

export default function LargeImageUpload(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [minimized, setMinimized] = useState(!!props.photo || !!props.minimized)
  const [alertVisible, setAlertVisible] = useState(false)

  const toggleMinimized = () => setMinimized(!minimized)

  const openAlert = () => setAlertVisible(true)
  const closeAlert = () => setAlertVisible(false)

  const removePhoto = () => {
    setAlertVisible(false)
    setMinimized(false)
    props.removePhoto()
  }

  const { getInputProps, open } = useDropzone({
    accept: 'image/*',
    multiple: false,
    noDrag: true,
    noClick: true,
    noKeyboard: true,
    onDrop: props.onFileUpload
  })

  const renderPhoto = () => {
    if (props.photo) {
      return (
        <div className={classes.photoInnerContainer}>
          <div
            style={{ backgroundImage: `url(${props.photo}` }}
            className={classes.photo}
          />
        </div>
      )
    }
    return (
      <div className={classes.photoInnerContainer}>
        <div className={classes.emptyPhoto} onClick={open}>
          <img src={BigPlus} className={classes.plusIcon} />
        </div>
      </div>
    )
  }

  const renderAddPhotoButton = () => {
    if (props.photo) return null

    return (
      <div className={props?.small ? classes.smallAddButton : classes.addButton} onClick={open}>
        {t('add_photo')}
      </div>
    )
  }

  const renderChangePhotoButton = () => {
    if (props.photo) {
      return (
        <div className={props?.small ? classes.smallAddButton : classes.addButton} onClick={open}>
          {t('change_photo')}
        </div>
      )
    }
    return null
  }

  const renderRemovePhoto = () => {
    if (props.photo && props.removePhoto) {
      return (
        <IconButton
          onClick={openAlert}
          className={classes.removePhoto}
          disabled={props.disabled}
          size='large'
        >
          <img src={closeIcon} className={classes.closeIcon} alt='icon' />
        </IconButton>
      )
    }
    return null
  }

  const renderToggle = () => {
    const icon = minimized ? DownArrow : UpArrow
    return (
      <div className={classes.toggleContainer} onClick={toggleMinimized}>
        <img src={icon} className={classes.toggleMinimizedIcon} />
      </div>
    )
  }

  const renderMinimized = () => {
    const icon = props.photo ? GreenCheck : RedAlert
    return (
      <div className={classes.minimizedContainer}>
        <img src={icon} className={classes.statusIcon} />
        <div className={classes.statusText}>
          {props.photo ? t('target_has_photo_added') : t('target_has_no_photo_added')}
        </div>
        <div className={classes.flex} />
        <div
          className={classes.actionText}
          onClick={() => props.photo ? openAlert() : open()}
        >
          {props.photo ? t('remove_photo') : t('add_photo')}
        </div>
      </div>
    )
  }

  const renderMaximized = () => {
    return (
      <div className={classes.row}>
        <div className={classes.leftColumn}>
          <input {...getInputProps()} />
          <div className={classes.photoContainer}>
            {renderPhoto()}
            {renderRemovePhoto()}
          </div>
        </div>
        <div className={classes.rightColumn}>
          <div className={props?.small ? classes.smallDescription : classes.description}>
            {t('upload_large_image_description_1')}
          </div>
          <div className={props?.small ? classes.smallDescription : classes.description}>
            {t('upload_large_image_description_2')}
          </div>
          {renderChangePhotoButton()}
          {renderAddPhotoButton()}
        </div>
      </div>
    )
  }

  return (
    <div className={`${classes.root} ${minimized ? classes.minimizedRoot : ''}`}>
      {renderToggle()}
      {minimized ? renderMinimized() : renderMaximized()}
      <Dialog
        open={alertVisible}
        handleClose={closeAlert}
        handleOk={removePhoto}
        title={t('delete_photo')}
        description={t('delete_photo_description')}
      />
    </div>
  )
}
