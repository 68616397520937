import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import { useStore } from '../../Models/RootStore'
import { Colors } from '../../Utils/theme'
import Notification from '../../Components/Common/Notification'
import LLogoIcon from '../../Assets/Icons/l-logo.svg'
import { supportsAVIF } from '../../Utils/imgTools'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse'
    }
  },
  leftColumn: {
    boxSizing: 'border-box',
    flex: 1,
    padding: 32,
    backgroundColor: Colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  rightColumn: {
    position: 'relative',
    display: 'flex',
    minHeight: '100vh',
    boxSizing: 'border-box',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    border: `40px solid ${Colors.white}`,
    borderRadius: '56px',
    backgroundColor: Colors.lightGreen,
    // backgroundImage: 'url(/images/background-1.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    [theme.breakpoints.down('md')]: {
      minHeight: '25vh',
      border: `none`,
      borderRadius: '0',
      justifyContent: 'flex-end',
      backgroundPosition: '0 bottom'
    }
  },
  logo: {
    backgroundImage: 'url(/images/logo.png)',
    backgroundPosition: '50% 50%',
    backgroundSize: 'contain',
    height: '6rem',
    width: '21rem',
    backgroundRepeat: 'no-repeat'
  },
  iconLogo: {
    width: '3.25rem'
  },
  leftCorner: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    padding: '3rem',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '2rem',
    }
  },
  slogan: {
    maxWidth: '26rem',
    margin: '1rem 0 0',
    color: Colors.navy,
    fontSize: '2rem',
    lineHeight: 1.1,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem',
    }
  }
}))

function AuthLayout(props) {
  const classes = useStyles()
  const [background, setBackground] = useState<string | undefined>(undefined)

  useEffect(() => {
    const resolveBackground = async () => {
      if (await supportsAVIF()) {
        setBackground('url(/images/background-1.avif)')
      } else {
        setBackground('url(/images/background-1.jpg)')
      }
    }
    void resolveBackground()
  }, [])

  const { appStore }: any = useStore()
  const { notification, clearNotification } = appStore

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.leftColumn}>
          {props.children}
        </div>
        <div className={classes.rightColumn} style={{ backgroundImage: background }}>
          <div className={classes.leftCorner}>
            <img src={LLogoIcon} className={classes.iconLogo} />
            <p className={classes.slogan}>
              Elämäntehtäväsi on elää,
              Lempin siivota.
            </p>
          </div>
        </div>
      </main>
      <Notification notification={notification} clearNotification={clearNotification} />
    </div>
  )
}

export default observer(AuthLayout)
