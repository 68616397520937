import React, { ReactElement, useEffect, useState } from 'react'
import { Dialog, Select } from '../index'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../Models/RootStore'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: '300px'
  },
  group: {
    marginBottom: '0.6rem'
  }
}))

interface Props {
  open: boolean
  onClose: () => void
  onConfirm: (props: { type: number, status: number }) => void
  filterParams: { type: number, status: number }
}

const CustomerFilterDialog = ({ open, onClose, onConfirm, filterParams }: Props): ReactElement => {
  const { t } = useTranslation()
  const [filterType, setFilterType] = useState(filterParams.type)
  const [filterStatus, setFilterStatus] = useState(filterParams.status)
  const { customerStore }: any = useStore()
  const classes = useStyles()

  useEffect(() => {
    if (filterParams.type !== filterType) {
      setFilterType(filterParams.type)
    }
    if (filterParams.status !== filterStatus) {
      setFilterStatus(filterParams.status)
    }
  }, [filterParams.type, filterParams.status])

  const typeOptions =
    [
      {
        label: t('all_options'),
        value: 0
      },
      ...(customerStore.types || []).map(item => (
        {
          label: t(`customer_type_${item.name}`),
          value: item.id
        }
      ))
    ]

  const statusOptions =
    [
      {
        label: t('all_options'),
        value: 0
      },
      ...(customerStore.statuses || []).map(item => (
        {
          label: t(`customer_status_${item.name}`),
          value: item.id
        }
      ))
    ]

  const handleClose = (): void => {
    setFilterType(filterParams.type)
    setFilterStatus(filterParams.status)
    onClose()
  }

  const handleConfirm = (): void => {
    onConfirm({ type: filterType, status: filterStatus })
  }

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      handleOk={handleConfirm}
      title={t('filter_customers')}
      okText={t('show_results')}
    >
      <div className={classes.content}>
        <div className={classes.group}>
          <Select
            label={t('customer_type')}
            options={typeOptions}
            value={filterType}
            onChange={setFilterType}
            noMargin
          />
        </div>
        <div className={classes.group}>
          <Select
            label={t('status')}
            options={statusOptions}
            value={filterStatus}
            onChange={setFilterStatus}
            noMargin
          />
        </div>
      </div>
    </Dialog>
  )
}

export default CustomerFilterDialog
