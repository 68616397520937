import React from 'react'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { Checkbox } from '../../Components'
import SortArrowUp from '../../Assets/Icons/sort-arrow-up.svg'
import SortArrowDown from '../../Assets/Icons/sort-arrow-down.svg'

const useStyles = makeStyles((theme) =>({
  outerContainer: {
    borderBottom: 'none'
  },
  container: {
    borderBottom: 'none'
  },
  cell: {
    height: '4rem',
    padding: '0 1rem',
    backgroundColor: Colors.navy,
    color: Colors.white,
    fontFamily: 'Ambit',
    fontSize: '1rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    borderRight: `1px solid ${Colors.border}`
  },
  selectCell: {
    width: '3rem'
  },
  firstCell: {
  },
  lastCell: {
    borderRight: 'none'
  },
  checkboxContainer: {
    paddingLeft: '.25rem',
    maxWidth: '2rem'
  },
  sortContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '1rem',
    marginTop: '-.375rem',
    marginLeft: '.125rem'
  },
  sortIcon: {
    height: '.5rem',
    userSelect: 'none'
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  sortButtonUp: {
    marginTop: '-.125rem',
    opacity: 0.33,
    cursor: 'pointer',
    padding: '.5rem .5rem .25rem',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      opacity: 0.8
    }
  },
  sortButtonDown: {
    marginTop: '-.125rem',
    opacity: 0.33,
    cursor: 'pointer',
    padding: '.25rem .5rem .5rem',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      opacity: 0.8
    }
  },
  activeButton: {
    opacity: 1
  },
}))

function TableHeader (props) {
  const classes = useStyles()

  const renderSelect = () => {
    if (props.onSelect) {
      return (
        <TableCell className={[classes.cell, classes.selectCell].join(' ')}>
          <div className={classes.checkboxContainer}>
            <Checkbox
              label=''
              checked={props.selected}
              onChange={props.onSelect}
              noBackgroundEffect
            />
          </div>
        </TableCell>
      )
    }
    return null
  }

  const renderSortArrows = (index) => {
    let isUpActive = false
    let isDownActive = false
    const upSortKey = `${props.sortKeys[index]}_asc`
    const downSortKey = `${props.sortKeys[index]}_desc`

    if (props.sort === upSortKey) {
      isUpActive = true
    }
    if (props.sort === downSortKey) {
      isDownActive = true
    }

    return (
      <div className={classes.sortContainer}>
        <div
          className={[classes.sortButtonUp, isUpActive ? classes.activeButton : ''].join(' ').trim()}
          onClick={() => props.onSort(upSortKey)}
        >
          <img src={SortArrowUp} className={classes.sortIcon} />
        </div>
        <div
          onClick={() => props.onSort(downSortKey)}
          className={[classes.sortButtonDown, isDownActive ? classes.activeButton : ''].join(' ').trim()}
        >
          <img src={SortArrowDown} className={classes.sortIcon} />
        </div>
      </div>
    )
  }

  const renderColumn = (columnName, index) => {
    let className = classes.cell
    if (index === 0) className = `${className} ${classes.firstCell}`
    else if (index === props.columnNames.length - 1) className = `${className} ${classes.lastCell}`
    return (
      <TableCell key={index} className={className}>
        <div className={classes.columnContainer}>
          {columnName}
          {props.onSort && props.sortKeys && props.sortKeys[index] && renderSortArrows(index)}
        </div>
      </TableCell>
    )
  }

  return (
    <TableHead className={classes.outerContainer}>
      <TableRow className={classes.container}>
        {renderSelect()}
        {props.columnNames.map((columnName, index) => {
          return renderColumn(columnName, index)
        })}
      </TableRow>
    </TableHead>
  )
}

export default TableHeader
