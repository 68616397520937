import moment from 'moment'
import { useTranslation } from 'react-i18next'

const useFormatter = () => {
  const {t} = useTranslation()

  const formatDate = (date: string | Date | null): string => {
    if (date === null) {
      return ''
    }
    return moment(date instanceof Date ? date.toISOString() : date).format('DD.MM.YYYY')
  }

  const formatDateTime = (date: string | Date | null): string => {
    if (date === null) {
      return ''
    }
    return moment(date instanceof Date ? date.toISOString() : date).format('DD.MM.YYYY HH.mm')
  }

  const timeSpan = (startDate, endDate) => {
    const startMoment = moment(startDate)
    const endMoment = moment(endDate)

    const weekday = t(`day_short_${startMoment.isoWeekday()}`)
    let startDay = startMoment.format('DD.MM.')
    const year = startMoment.format('YYYY')
    if (year !== moment().format('YYYY')) {
      // Add year suffix if not on current year
      startDay = `${startDay}${year}`
    }
    const startTime = startMoment.format('HH:mm')
    const endTime = endMoment.format('HH:mm')
    return `${weekday} ${startDay} ${startTime} - ${endTime}`
  }

  const duration = (startDate, endDate) => {
    const startMoment = moment(startDate)
    const endMoment = moment(endDate)
    const hoursDiff = endMoment.diff(startMoment, 'minutes') / 60
    return `${hoursDiff.toFixed(2).replace('.', ',')}h`
  }

  const formatCurrency = (input: string): string => {
    return `${parseFloat(input).toFixed(2)} €`
  }

  return {
    formatDate,
    formatDateTime,
    timeSpan,
    duration,
    formatCurrency
  }
}

export default useFormatter
