import React, { ReactElement } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { TableHeader, DataMessage } from '../index'
import { Colors } from '../../Utils/theme'
import { UserType } from '../../Schemas'
import UserTableRow from './UserTableRow'

const useStyles = makeStyles((theme) => ({
  tableOuterContainer: {
    borderLeft: `1px solid ${Colors.border}`,
    borderRight: `1px solid ${Colors.border}`
  }
}))

interface Props {
  items: UserType[]
  loading: boolean
  sort: string
  sortKeys: (string | null)[]
  onSort: (sort: string) => void
  onUserOpen: (id: number) => void
}

const UserTable = ({
  items, loading, sort, sortKeys, onSort, onUserOpen
}: Props): ReactElement => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.tableOuterContainer}>
      <Table>
        <TableHeader
          columnNames={[t('name'), t('role'), t('email')]}
          sort={sort}
          onSort={onSort}
          sortKeys={sortKeys}
        />
        <TableBody>
          {items.map(item => (
            <UserTableRow item={item} onClick={onUserOpen} />
          ))}
        </TableBody>
      </Table>
      <DataMessage
        loading={loading}
        data={items}
        message={t('no_results')}
      />
    </div>
  )
}

export default UserTable
