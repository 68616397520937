import React, { ReactElement } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { TableHeader, DataMessage } from '..'
import { Colors } from '../../Utils/theme'
import ShiftTableRow from './ShiftTableRow'
import { ShiftType } from '../../Schemas'

const useStyles = makeStyles((theme) => ({
  tableOuterContainer: {
    borderLeft: `1px solid ${Colors.border}`,
    borderRight: `1px solid ${Colors.border}`
  }
}))

interface Props {
  items: ShiftType[]
  selectedIds: number[]
  loading: boolean
  onClearSelect: () => void
  onSelectAll: () => void
  onToggleSelect: (index: number) => void
  sort: string
  sortKeys: (string | null)[]
  onSort: (sort: string) => void
  onShiftOpen: (id: number) => void
}

const ShiftTable = ({
  items, selectedIds, loading, onClearSelect, onSelectAll, onToggleSelect, sort, sortKeys, onSort, onShiftOpen
}: Props): ReactElement => {
  const classes = useStyles()
  const { t } = useTranslation()

  const isAllSelected = () => {
    if (!selectedIds.length || selectedIds.length < items.length) {
      return false
    }
    for (const item of items) {
      if (!selectedIds.includes(item.id)) {
        return false
      }
    }
    return true
  }

  const toggleAll = () => {
    const all = isAllSelected()
    if (all) {
      onClearSelect()
    } else {
      // Add new items to selection
      onSelectAll()
    }
  }

  return (
    <div className={classes.tableOuterContainer}>
      <Table>
        <TableHeader
          columnNames={[t('the_time'), t('duration'), t('customer'), t('task'), t('profit_unit')]}
          sort={sort}
          onSort={onSort}
          sortKeys={sortKeys}
          onSelect={toggleAll}
          selected={isAllSelected()}
        />
        <TableBody>
          {items.map(item => (
            <ShiftTableRow
              onClick={onShiftOpen}
              key={item.id}
              item={item}
              selectedIds={selectedIds}
              onToggleSelect={onToggleSelect}
            />
          ))}
        </TableBody>
      </Table>
      <DataMessage
        loading={loading}
        data={items}
        message={t('no_results')}
      />
    </div>
  )
}

export default ShiftTable
