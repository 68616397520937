import React, { ReactElement } from 'react'
import { ShiftType } from '../../Schemas'
import { Input, Title } from '../index'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'

interface Props {
  shift: ShiftType
  onChange: (key: string, value: string | number) => void
}

const ShiftLocation = ({ shift, onChange }: Props): ReactElement => {
  const { t } = useTranslation()

  return (
    <>
      <Title title={t('shift_location')} type="main" />
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Input
            value={shift.customer?.streetAddress}
            onChange={value => onChange('customer.streetAddress', value)}
            label={t('street_address')}
            noMargin
            disabled
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            value={shift.customer?.postalCode}
            onChange={value => onChange('customer.postalCode', value)}
            label={t('postal_code')}
            noMargin
            disabled
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            value={shift.customer?.city}
            onChange={value => onChange('customer.city', value)}
            label={t('city')}
            noMargin
            disabled
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ShiftLocation
