import { z } from 'zod'
import { LeaveSchema } from './LeaveSchema'
import { ContractSchema } from './ContractSchema'

export const EmployeeSchema = z.object({
  id: z.number(),
  extId: z.number(),
  extCostModifierId: z.number().nullable(),
  extCompanyId: z.number().nullable(),
  extResponsibleId: z.number().nullable(),
  extUpdatedAt: z.string().nullable().transform((val) => val ? new Date(val) : null),
  feedbackCount: z.number().optional(),
  status: z.string(),
  contractType: z.string(),
  contractStatus: z.string(),
  timeTrackingMethod: z.string(),
  travelCompensation: z.string(),
  salaryBasisBasicMethod: z.string(),
  salaryBasisExtraMethod: z.string(),
  salaryBasisOvertimeMethod: z.string(),
  salaryBasisDayTravelMethod: z.string(),
  createdAt: z.string().nullable().transform((val) => val ? new Date(val) : null),
  updatedAt: z.string().nullable().transform((val) => val ? new Date(val) : null),
  person: z.object({
    extId: z.number().nullable(),
    firstName: z.string(),
    lastName: z.string(),
    dateOfBirth: z.string(),
    title: z.string(),
    streetAddress: z.string(),
    postalCode: z.string(),
    city: z.string(),
    country: z.string(),
    email: z.string(),
    phone: z.string()
  }),
  leaves: LeaveSchema.array().optional(),
  contracts: ContractSchema.array().optional()
})

export type EmployeeType = z.infer<typeof EmployeeSchema>
