import { z } from 'zod'

export const GeneralFeedbackSchema = z.object({
  id: z.number(),
  feedback: z.string(),
  email: z.string().nullable(),
  receiveResponse: z.boolean(),
  active: z.boolean(),
  createdAt: z.string().transform((val) => val ? new Date(val) : null),
  updatedAt: z.string().transform((val) => val ? new Date(val) : null)
})

export type GeneralFeedbackType = z.infer<typeof GeneralFeedbackSchema>
