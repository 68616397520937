import React  from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AuthLayout } from '../../Layouts'
import { Login, ForgotPassword, VerifyEmail, EmailChange } from './Auth'
import CreateCustomerAccount from './Auth/CreateCustomerAccount'
import ResetCustomerPassword from './Auth/ResetCustomerPassword'
import PrivacyPolicy from './PrivacyPolicy'
import AccountDeleteInstructions from './AccountDeleteInstructions'

export default function Unauthorized() {
  return (
    <AuthLayout>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/reset-password' element={<ForgotPassword />} />
        <Route path='/verify-email' element={<VerifyEmail />} />
        <Route path='/email-change' element={<EmailChange />} />
        <Route path='/create-account' element={<CreateCustomerAccount />} />
        <Route path='/reset-customer-password' element={<ResetCustomerPassword />} />
        <Route path='/privacy-policy/:locale' element={<PrivacyPolicy />} />
        <Route path='/account-delete-instructions' element={<AccountDeleteInstructions />} />
        <Route path='*' element={<Navigate replace to='/login' />} />
      </Routes>
    </AuthLayout>
  )
}
