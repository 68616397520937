import React, { useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import HeaderUser from './HeaderUser'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

const PageContainer = (props) => {
  const classes = useStyles()

  useEffect(() => {
    // Scroll to top on mount
    try {
      document.getElementById('app-content').scrollTop = 0
    } catch (err) {}
  }, [])

  return (
    <div className={classes.container}>
      {props.children}
    </div>
  )
}

export default PageContainer
