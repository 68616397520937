import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Colors } from '../../Utils/theme'
import CheckIcon from '../../Assets/Icons/check-minimal.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${Colors.border}`,
    height: '1.5625rem',
    width: '1.5625rem',
    borderRadius: '.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  radioContainer: {
    border: `1px solid ${Colors.border}`,
    height: '1.5625rem',
    width: '1.5625rem',
    borderRadius: '5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  check: {
    height: '1rem',
    maxHeight: '1rem',
    maxWidth: '1rem'
  },
  radioCheck: {
    height: '.875rem',
    width: '.875rem',
    borderRadius: '1rem',
    backgroundColor: Colors.navy
  },
  label: {
    fontWeight: 400,
    color: Colors.inputText
  },
  checkboxContainer: {
    position: 'relative'
  },
  backgroundContainer: {
    position: 'absolute',
    top: '.875rem',
    left: '.875rem',

    backgroundColor: Colors.lightGreen,
    height: '1.525rem',
    width: '1.525rem',
    borderRadius: '.25rem'
  },
  plainBackgroundContainer: {
    backgroundColor: Colors.white
  }
}))

export default function CustomCheckbox(props) {
  const classes = useStyles()

  const getClasses = () => {
    if (props.classes) {
      return { label: classes.label, ...props.classes }
    }
    return { label: classes.label }
  }

  const getContainerClasses = () => {
    if (props.containerStyle) {
      return `${props.radio ? classes.radioContainer : classes.container} ${props.containerStyle}`
    }
    if (props.radio) return classes.radioContainer
    let extraClass = props.noBackgroundEffect ? classes.plainBackgroundContainer : ''
    return [classes.container, extraClass].join(' ').trim()
  }

  const renderUnchecked = () => {
    return <div className={getContainerClasses()} />
  }

  const renderChecked = () => {
    if (props.radio) {
      return (
        <div className={getContainerClasses()}>
          <div className={classes.radioCheck} />
        </div>
      )
    }
    return (
      <div className={getContainerClasses()}>
        <img
          src={CheckIcon}
          className={props.checkStyle ? props.checkStyle : classes.check}
        />
      </div>
    )
  }

  return (
    <FormControlLabel
      control={(
        <div className={classes.checkboxContainer}>
          {(!props.radio && !props.noBackgroundEffect) && <div className={classes.backgroundContainer} />}
          <Checkbox
            checkedIcon={renderChecked()}
            icon={renderUnchecked()}
            checked={props.checked}
            onChange={props.onChange}
          />
        </div>
      )}
      label={props.label}
      classes={getClasses()}
    />
  )
}
