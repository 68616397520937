import { shuffle, sortBy } from 'lodash'
import moment from 'moment'

const getMockLabel = (index) => {
  const mockDate = 23
  return 1+((mockDate + 1 + index) % 31)
}

export const EMPLOYEES_SUMMARY = {
  counter: {
    employees: {
      value: 212,
      change: -1
    },
    shifts: {
      value: 970,
      change: 0.05
    },
    abcenses: {
      value: 6,
      change: 0.2
    },
    strength: {
      value: 96,
      change: 0.01
    }
  }
}

export const CUSTOMERS_SUMMARY = {
  counter: {
    shifts: {
      value: 523,
      change: 0.12423534
    },
    newCustomers: {
      value: 44,
      change: 0.073453432
    },
    feedbacks: {
      value: 32,
      change: 0.234342234
    },
    recommendations: {
      value: 19,
      change: -0.2443234324
    }
  },
  feedbackSeries: {
    series: [
      {
        name: 'Kotisiivous',
        data: Array(30).fill(null).map((x, i) => {
          return {
            label: getMockLabel(i),
            value: Math.round(4 * Math.random()+1)
          }
        })
      },
      {
        name: 'Muuttosiivous',
        data: Array(30).fill(null).map((x, i) => {
          return {
            label: getMockLabel(i),
            value:  Math.round(4 * Math.random()+1)
          }
        })
      },
      {
        name: 'Ikkunoiden pesu',
        data: Array(30).fill(null).map((x, i) => {
          return {
            label: getMockLabel(i),
            value:  Math.round(4 * Math.random()+1)
          }
        })
      }
    ]
  },
  shiftSeries: {
    series: [
      {
        name: 'Palaava asiakas',
        data: Array(30).fill(null).map((x, i) => {
          return {
            label: getMockLabel(i),
            value: Math.round(123 * Math.random()+21)
          }
        })
      },
      {
        name: 'Uusi asiakas',
        data: Array(30).fill(null).map((x, i) => {
          return {
            label: getMockLabel(i),
            value: Math.round(123 * Math.random()+21)
          }
        })
      }
    ]
  }
}

const mockAndroidData = Array(30).fill(null).map((x, i) => {
  return {
    label: getMockLabel(i),
    value: Math.round(123 * Math.random()+33)
  }
})
const mockIosData = Array(30).fill(null).map((x, i) => {
  return {
    label: getMockLabel(i),
    value: Math.round(123 * Math.random()+12)
  }
})
const mockBothOsData = Array(30).fill(null).map((x, i) => {
  return {
    label: getMockLabel(i),
    value: mockAndroidData[i].value + mockIosData[i].value
  }
})

const androidInstalls = Math.round(Math.random() * 1000 + 1200)
const iosInstalls = Math.round(Math.random() * 1000 + 800)

const androidUninstalls = Math.round(Math.random() * 100 + 50)
const iosUninstalls = Math.round(Math.random() * 100 + 20)

export const USER_SUMMARY = {
  download: {
    total: androidInstalls + iosInstalls,
    android: androidInstalls,
    ios: iosInstalls,
    change: 0.04,
    previousDay: 17
  },
  uninstall: {
    total: androidUninstalls + iosUninstalls,
    android: androidUninstalls,
    ios: iosUninstalls,
    change: -0.11,
    previousDay: 11
  },
  activeUsers: {
    series: [
      {
        name: 'Kaikki',
        data: mockBothOsData
      },
      {
        name: 'iOS',
        data: mockIosData
      },
      {
        name: 'Android',
        data: mockAndroidData
      }
    ]
  }
}

const mockCustomerNameA = ['Sininen', 'Keltainen', 'Punainen', 'Vihreä', 'Oranssi', 'Pika', 'Laatu']
const mockCustomerNameB = ['Orvokki', 'Tehdas', 'Palvelut', 'Huolto', 'Korjaamo', 'Ompelimo', 'Studio']
const mockCustomerNameC = ['', 'Oy', 'Oy', 'Ky', 'Tmi']
const mockTaskNames = ['Kotipalvelu alv 0%', 'Säännöllinen kotisiivous', 'Lempi_viikkosiivous_2']
const mockCities = ['Kuopio', 'Lahti', 'Uusimaa', 'Tampere', 'Turku', 'Helsinki', 'Hämeenlinna']

export const SHIFT_CATEGORIES = mockCities.map((city, index) => {
  return {
    id: index + 1,
    name: `Lempi, ${city}`
  }
})

const pickRandom = (array) => {
  return array[Math.floor(Math.random() * array.length)];
}

export const SHIFTS = Array(123).fill(null).map((z, index) => {

  const diffMinutes = ((1 + Math.floor((Math.random() * 12))) * 15)

  const customerA = pickRandom(mockCustomerNameA)
  const customerB = pickRandom(mockCustomerNameB)
  const customerC = pickRandom(mockCustomerNameC)

  const customerName = `${customerA} ${customerB} ${customerC}`.trim()

  const taskName = pickRandom(mockTaskNames)
  const category = pickRandom(SHIFT_CATEGORIES)

  const startTime = moment().startOf('day').add(((index * 15) + diffMinutes), 'minutes').format()
  const endTime = moment(startTime).add(diffMinutes, 'minutes').format()
  return {
    id: index + 1,
    categoryId: category.id,
    startTime,
    endTime,
    customerName,
    taskName,
    profitUnit: category.name,
    _search: `${customerName} ${taskName} ${category.name}`.trim().toLowerCase()
  }
})

export const CUSTOMER_CATEGORIES = [
  {
    id: 1,
    name: 'Yritys'
  },
  {
    id: 2,
    name: 'Hlöasiakas'
  }
]

const personA = shuffle(['Aino', 'Bertta', 'Camilla', 'David', 'Eino', 'Heikki', 'Jaakko', 'Kalle', 'Leevi', 'Mikko', 'Nea', 'Olli', 'Petteri', 'Risto', 'Taru', 'Ulla', 'Veikko'])
const personB = shuffle(['Korhonen', 'Virtanen', 'Mäkinen', 'Nieminen', 'Mäkelä', 'Laine', 'Heikkinen', 'Koskinen', 'Järvinen'])

const mockAreaNames = ['Pasila, Helsinki', 'Tiiriö, Hämeenlinna', 'Keskusta, Turku', 'Laune, Lahti', 'Pyynikki, Tampere', 'Töölö, Helsinki', 'Kantola, Hämeenlinna']

export const CUSTOMERS = sortBy(Array(78).fill(null).map((z, index) => {
  const categoryId = (index % 2) + 1
  let categoryName = CUSTOMER_CATEGORIES[categoryId - 1].name
  let customerName
  if (categoryId === 1) {
    const customerA = pickRandom(mockCustomerNameA)
    const customerB = pickRandom(mockCustomerNameB)
    const customerC = pickRandom(mockCustomerNameC)
    customerName = [customerA, customerB, customerC].join(' ').trim()
  } else {
    customerName = [pickRandom(personA), pickRandom(personB)].join(' ')
  }
  const statusName = pickRandom(['Nykyinen', 'Nykyinen', 'Nykyinen', 'Tauolla'])

  const ordersTotal = Math.floor(Math.random() * 30)
  const feedbacksTotal = Math.floor(ordersTotal / (1 + (Math.random() * 3)))
  const areaName = pickRandom(mockAreaNames)
  return {
    id: index + 1,
    categoryId,
    categoryName,
    customerName,
    statusName,
    areaName,
    ordersTotal,
    feedbacksTotal,
    _search: [customerName, statusName, areaName].join(' ')
  }
}), 'customerName')

const mockLanguageSkills = ['FI', 'FI, EN', 'FI, EN', 'EN', 'FI, EN, SWE']
const mockShiftDurations = ['8h', '8h', '8h', '4h', '5h']

export const EMPLOYEE_CATEGORIES = [
  {
    id: 1,
    name: 'Jatkuva'
  },
  {
    id: 2,
    name: 'Määräaikainen'
  },
  {
    id: 3,
    name: 'Vuokratyö'
  },
  {
    id: 4,
    name: 'Oppisopimus'
  },
  {
    id: 5,
    name: 'Työharjoittelu'
  }
]

export const EMPLOYEES = Array(55).fill(null).map((z, index) => {
  const name = `${pickRandom(personA)} ${pickRandom(personB)}`

  let contractTypeIndex = (Math.round(Math.random() * 10000)) % (EMPLOYEE_CATEGORIES.length + 3)
  if (contractTypeIndex >= EMPLOYEE_CATEGORIES.length - 1) contractTypeIndex = 0

  const contractType = EMPLOYEE_CATEGORIES[contractTypeIndex].name
  const categoryId = EMPLOYEE_CATEGORIES[contractTypeIndex].id

  const languageSkills = pickRandom(mockLanguageSkills)
  const city = pickRandom(mockCities)
  return {
    id: index + 1,
    name,
    contractType,
    languageSkills,
    categoryId,
    city,
    shiftDuration: pickRandom(mockShiftDurations),
    feedbackAvg: (2 + (Math.random() * 3)).toFixed(1).replace('.', ','),
    _search: [name].join(' ').toLowerCase()
  }
})
