import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import { NotificationSchema, NotificationType } from '../Schemas'

export default class NotificationStore {
  rootStore
  notifications: NotificationType[] = []
  notification: NotificationType | null = null
  categories = []
  creatorUsers = null
  loading = false
  total = 0

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setLoading = (loading: boolean) => { this.loading = loading }
  setNotifications = (notifications: NotificationType[]) => { this.notifications = notifications }
  setNotification = (notification: NotificationType) => { this.notification = notification }
  setTotal = (total: number) => { this.total = total }

  async getNotifications(params) {
    this.setLoading(true);
    try {
      const response: any = await Api.getNotifications(params)
      if (response.ok) {
        const validNotifications: NotificationType[] = []
        const invalidNotifications: any[] = []

        response.data.items.forEach(item => {
          try {
            const validNotification = NotificationSchema.parse(item)
            validNotifications.push(validNotification)
          } catch (error) {
            console.error("Invalid notification:", item, error)
            invalidNotifications.push(item)
          }
        })

        this.setNotifications(validNotifications)
        this.setTotal(response.data.total)
      }
    } catch (error) {
      console.error("Error fetching notifications:", error)
    } finally {
      this.setLoading(false)
    }
  }
  

  async getNotification(id: number) {
    this.setLoading(true);
    try {
      const response = await Api.getNotification(id);
      if (response.ok) {
        const validNotification = NotificationSchema.parse(response.data)
        this.setNotification(validNotification)
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  async createNotification(notification: NotificationType) {
    this.setLoading(true);
    try {
      NotificationSchema.parse(notification)
      const response = await Api.createNotification(notification);
      this.rootStore.appStore.setErrorOrSuccess(response, true, 'push_notification_created');
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  async updateNotification(id, notification: NotificationType) {
    this.setLoading(true);
    try {
      NotificationSchema.parse(notification); // Validate before sending
      const response = await Api.updateNotification(id, notification);
      this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated');
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false);
    }
  }
}
