import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../../Models/RootStore'
import makeStyles from '@mui/styles/makeStyles'
import { Title, Input, Button } from '../../../Components'
import { Link } from 'react-router-dom'
import { Colors } from '../../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '40rem'
  },
  requestSentRoot: {
    maxWidth: '35rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  description: {
    marginBottom: '2rem'
  },
  altDescription: {
    textAlign: 'center',
    marginBottom: '2rem'
  },
  button: {
    minHeight: '4rem',
    marginTop: '0.5rem',
    marginBottom: '1.75rem'
  },
  link: {
    color: Colors.navy,
    textDecoration: 'underline',
    backgroundColor: Colors.lightGreen,
    padding: '.3125rem .5rem',
    borderRadius: '.3125rem'
  }
}))

const ForgotPassword = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { sessionStore }: any = useStore()

  const [email, setEmail] = useState('')

  const [newPassword, setNewPassword] = useState('')
  const [newPassword2, setNewPassword2] = useState('')

  const [requestSent, setRequestSent] = useState(false)

  const clearEmail = () => setEmail('')

  let initialCode = ''
  if (window.location.search) {
    const parts = window.location.search.split('code=')
    if (parts.length > 1 && parts[1].split('&')[0].length === 36) {
      initialCode = parts[1].split('&')[0]
    }
  }

  const requestPasswordReset = () => {
    sessionStore.requestPasswordReset(email)
    setRequestSent(true)
  }

  const completePasswordReset = () => {
    sessionStore.completePasswordReset(initialCode, newPassword)
    setRequestSent(true)
  }

  if (initialCode) {
    if (!requestSent) {
      return (
        <div className={classes.root}>
          <Title
            title={t('change_password')}
            description={t('change_password_description')}
            type='main'
            descriptionStyle={classes.description}
          />
          <Input
            label={t('new_password')}
            value={newPassword}
            onChange={setNewPassword}
            type='password'
            autoFocus
            tall
          />
          <Input
            label={t('new_password_again')}
            value={newPassword2}
            onChange={setNewPassword2}
            type='password'
            tall
          />
          <Button
            text={t('change_password')}
            onClick={completePasswordReset}
            disabled={newPassword.length < 8 || newPassword !== newPassword2}
            buttonStyle={classes.button}
            fullWidth
            tall
          />
          <Link className={classes.link} to="/">{t('back_to_login_page')}</Link>
        </div>
      )
    }

    return (
      <div className={classes.root}>
        <Title
          title={t('password_changed')}
          description={t('password_changed_description')}
          type='main'
          descriptionStyle={classes.altDescription}
        />
        <Link className={classes.link} to="/">{t('back_to_login_page')}</Link>
      </div>
    )
  }

  if (requestSent) {
    return (
      <div className={classes.requestSentRoot}>
        <Title
          title={t('password_reset_sent')}
          description={t('password_reset_sent_description')}
          type='main'
          descriptionStyle={classes.altDescription}
        />
        <Link className={classes.link} to="/">{t('back_to_login_page')}</Link>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Title
        title={t('forgot_password')}
        description={t('forgot_password_description')}
        type='main'
        descriptionStyle={classes.description}
      />
      <Input
        placeholder={t('email')}
        value={email}
        onChange={setEmail}
        onClear={clearEmail}
        tall
        autoFocus
      />
      <Button
        text={t('send')}
        onClick={requestPasswordReset}
        buttonStyle={classes.button}
        disabled={!email || !email.includes('@')}
        tall
        fullWidth
      />
      <Link className={classes.link} to="/">{t('back_to_login_page')}</Link>
    </div>
  )
}

export default observer(ForgotPassword)
