import React, { ReactElement } from 'react'
import { ShiftType } from '../../Schemas'
import { Input, Title } from '../index'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import useFormatter from '../../Hooks/useFormatter'

interface Props {
  shift: ShiftType
  onChange: (key: string, value: string | number) => void
}

const ShiftGeneral = ({ shift, onChange }: Props): ReactElement => {
  const { t } = useTranslation()
  const { timeSpan, duration, formatDateTime } = useFormatter()
  const { formatCurrency } = useFormatter()

  return (
    <>
      <Title title={t('shift_info')} type="main" />
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <Input
            value={shift.customer?.name}
            onChange={value => onChange('customer.name', value)}
            label={t('customer_name')}
            noMargin
            disabled
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            value={`${shift.employee?.person.firstName ?? ''} ${shift.employee?.person.lastName ?? ''}`}
            onChange={value => onChange('employee.name', value)}
            label={t('employee_name')}
            noMargin
            disabled
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input
            value={formatDateTime(shift.extCreatedAt)}
            onChange={value => onChange('employee.name', value)}
            label={t('ordered_at')}
            noMargin
            disabled
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input
            value={timeSpan(shift.startDate, shift.endDate)}
            onChange={value => onChange('employee.name', value)}
            label={t('the_time')}
            noMargin
            disabled
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input
            value={duration(shift.startDate, shift.endDate)}
            onChange={value => onChange('employee.name', value)}
            label={t('duration')}
            noMargin
            disabled
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <Input
            value={shift.pricableItem?.name}
            onChange={value => onChange('pricableItem.name', value)}
            label={t('task')}
            noMargin
            disabled
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input
            value={`${formatCurrency(shift.price.unitPrice)} / ${shift.price.unit}`}
            onChange={value => onChange('price.unitPrice', value)}
            label={t('price')}
            noMargin
            disabled
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Input
            multiline
            value={shift.additionalInfo}
            onChange={value => onChange('additionalInfo', value)}
            label={t('additionalInfo')}
            noMargin
            disabled
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Input
            multiline
            value={shift.note}
            onChange={value => onChange('note', value)}
            label={t('note')}
            noMargin
            disabled
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Input
            multiline
            value={shift.internalNote}
            onChange={value => onChange('internalNote', value)}
            label={t('internalNote')}
            noMargin
            disabled
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Input
            multiline
            value={shift.employeeRegistrationComment}
            onChange={value => onChange('employeeRegistrationComment', value)}
            label={t('employeeRegistrationComment')}
            noMargin
            disabled
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Input
            multiline
            value={shift.customerNote}
            onChange={value => onChange('customerNote', value)}
            label={t('customerNote')}
            noMargin
            disabled
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ShiftGeneral
