import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import BellIcon from '../../Assets/Icons/bell.svg'
import { Button } from '@mui/material'
import { Tabs } from '../../Components'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: '15rem',
    width: '34rem',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.white,
    zIndex: 1204,
    borderTopRightRadius: '1rem',
    borderBottomRightRadius: '1rem',
    overflowX: 'hidden',
    overflowY: 'auto',
    [theme.breakpoints.up('lg')]: {
      top: '2rem',
      bottom: '2rem',
      left: '17.3725rem',
    }
  },
  backdropClickArea: {
    position: 'fixed',
    zIndex: 1202,
    left: '51.5rem', // 16rem + 34rem
    top: 0,
    bottom: 0,
    right: 0
  },
  backdrop: {
    background: Colors.backdrop,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1200
  },
  topContainer: {
    padding: '2rem 3rem 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  flex: {
    flex: 1
  },
  h3: {
    textTransform: 'uppercase',
    fontWeight: 900,
    textShadow: `.25rem .25rem 0 ${Colors.lightGreen}`
  },
  iconContainer: {
    position: 'relative'
  },
  icon: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '1.75rem'
  },
  iconBackdrop: {
    position: 'absolute',
    top: '.35rem',
    left: '.35rem',
    height: '1.75rem',
    width: '1.75rem',
    borderRadius: '2rem',
    backgroundColor: Colors.lightGreen
  },
  notificationContainer: {
    display: 'flex',
    flexDirection: 'row',
    border: `1px solid ${Colors.border}`,
    padding: '1rem',
    borderRadius: '.325rem',
    marginBottom: '1rem'
  },
  notificationLeft: {
    display: 'flex',
    width: '3rem',
    userSelect: 'none'
  },
  notificationRight: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  notificationTitle: {
    fontWeight: 700,
    color: Colors.navy,
    fontSize: '1.25rem'
  },
  notificationContent: {
    color: Colors.secondaryText,
    fontSize: '1rem'
  },
  tabContainer: {
    marginBottom: '2rem',
    display: 'flex',
    flexDirection: 'row',
    background: Colors.lightGreen,
    padding: '.25rem',
    borderRadius: '.3125rem',
    fontWeight: 700
  },
  tabActive: {
    flex: 1,
    cursor: 'pointer',
    backgroundColor: Colors.navy,
    color: Colors.white,
    borderRadius: '.3125rem',
    padding: '.5rem .5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tab: {
    flex: 1,
    cursor: 'pointer',
    backgroundColor: 'transparent',
    color: Colors.navy,
    borderRadius: '.3125rem',
    padding: '.5rem .5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  linkButton: {
    padding: '.25rem .75rem',
    textDecoration: 'underline',
    borderRadius: '.3125rem',
    backgroundColor: Colors.lightGreen,
    fontWeight: 700,
    fontSize: '.875rem',
    cursor: 'pointer',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: Colors.lightGreen,
      textDecoration: 'underline'
    }
  },
  notificationsContainer: {
    padding: '1rem 3rem'
  },
  tabsContainer: {
    padding: '1rem 3rem .5rem'
  },
  emptyContainer: {
    paddingTop: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    textAlign: 'center',
    color: Colors.secondaryText
  }
}))

enum Tab {
  customer = 'customer',
  system = 'system'
}

export default function NotificationSidebar(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  const [tab, setTab] = useState(Tab.customer)

  const getNotifications = () => {
    return props.notifications.filter(item => item.type === tab)
  }

  const renderNotifications = () => {
    const notifications = getNotifications()
    if (!notifications.length) {
      return (
        <div className={classes.emptyContainer}>
          {t('no_notifications')}
        </div>
      )
    }
    return notifications.map(notification => {
      return (
        <div className={classes.notificationContainer}>
          <div className={classes.notificationLeft}>
            <div className={classes.iconContainer}>
              <div className={classes.iconBackdrop} />
              <img src={BellIcon} className={classes.icon} />
            </div>
          </div>
          <div className={classes.notificationRight}>
            <div className={classes.notificationTitle}>{notification.titleFi}</div>
            <div className={classes.notificationContent}>{notification.contentFi}</div>
          </div>
        </div>
      )
    })
  }

  const renderTabs = () => {
    const tabs = [
      { value: Tab.customer, name: t('customers') },
      { value: Tab.system, name: t('system') }
    ]
    return (
      <Tabs
        items={tabs}
        value={tab}
        onChange={setTab}
      />
    )
  }

  if (!props.open) return null

  return (
    <>
      <div className={classes.backdrop} />
      <div className={classes.backdropClickArea} onClick={props.onClose} />
      <div className={classes.container}>
        <div className={classes.topContainer}>
          <h3 className={classes.h3}>{t('notifications')}</h3>
          <div className={classes.flex} />
          <Button className={classes.linkButton}>
            {t('mark_as_read')}
          </Button>
        </div>
        <div className={classes.tabsContainer}>
          {renderTabs()}
        </div>
        <div className={classes.notificationsContainer}>
          {renderNotifications()}
        </div>
      </div>
    </>
  )
}
