import { ReactElement } from 'react'
import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Close } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
  item: {
    margin: '0 10px 10px 0',
    textTransform: 'none'
  },
  value: {
    fontWeight: 'bold',
    marginLeft: '0.4rem'
  }
}))

interface Props {
  items: {key: string, label: string, value: string}[]
  onDisable: (key: string) => void
}

const FilterButtons = ({ items, onDisable }: Props): ReactElement => {
  const classes = useStyles()

  return (
    <div>
      {items.map(item => (
        <Button
          key={`filter_button_${item.key}`}
          className={classes.item}
          onClick={() => onDisable(item.key)}
          variant="contained"
          endIcon={<Close />}
        >
          <span>{item.label}</span>:
          <span className={classes.value}>{item.value}</span>
        </Button>
      ))}
    </div>
  )
}

export default FilterButtons
