import React from 'react'
import { Input, Select } from '..'
import makeStyles from '@mui/styles/makeStyles'
import SearchIcon from '../../Assets/Icons/search.svg'

const useStyles = makeStyles({
  actionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'row'
  },
  searchContainer: {
    width: '25rem',
    marginRight: '2rem'
  },
  selectTypeContainer: {
    width: '14rem'
  },
  sortContainer: {
    width: '16rem'
  }
})

function DataActions (props) {
  const classes = useStyles()

  const renderSearch = () => {
    return (
      <div className={classes.searchContainer}>
        <Input
          placeholder={props.searchPlaceholder}
          value={props.searchValue}
          onChange={props.onSearchChange}
          iconLeft={SearchIcon}
        />
      </div>
    )
  }

  const renderTypeSelect = () => {
    if (props?.typeOptions) {
      return (
        <div className={classes.selectTypeContainer}>
          <Select
            options={props.typeOptions}
            value={props.typeValue}
            onChange={props.onTypeChange}
          />
        </div>
      )
    }
  }

  const renderSort = () => {
    if (props.sortOptions) {
      return (
        <div className={classes.sortContainer}>
          <Select
            options={props.sortOptions}
            value={props.sortValue}
            onChange={props.onSortChange}
          />
        </div>
      )
    }
  }

  return (
    <div className={classes.actionsContainer}>
      <div className={classes.leftColumn}>
        {renderSearch()}
        {renderTypeSelect()}
      </div>
      {renderSort()}
    </div>
  )
}

export default DataActions
