import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useStore } from './Models/RootStore'
import { Authorized, Unauthorized } from './Containers'
import { observer } from 'mobx-react'

const App = () => {
  const { sessionStore }: any = useStore()

  useEffect(() => {
    // Detect www. in url and redirect without it
    if (window.location.hostname.indexOf('www.') === 0) {
      window.location = window.location.href.replace('www.', '')
    }
    if (sessionStore.isLoggedIn) {
      sessionStore.getMe()
    }
  }, [])

  const renderRouter = () => {
    if (sessionStore.isLoggedIn) {
      return <Authorized user={sessionStore.user} />
    }
    return <Unauthorized />
  }

  return (
    <BrowserRouter>
      {renderRouter()}
    </BrowserRouter>
  )
}

export default observer(App)
