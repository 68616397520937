import React, { useMemo, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import makeStyles from '@mui/styles/makeStyles'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Button, Dialog } from '../../Components'
import CloseIcon from '../../Assets/Icons/close.svg'
import PhotoIcon from '../../Assets/Icons/photo.svg'
import { Colors } from '../../Utils/theme'

const baseStyle = {
  display: 'inline-block',
  outline: 'none'
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  addButton: {
    width: '10rem'
  },
  addButtonCompact: {
    width: '8.125rem'
  },
  addButtonTextCompact: {
    fontSize: '0.875rem'
  },
  photo: {
    width: '19.75rem',
    height: '19.75rem',
    borderRadius: '1.25rem',
    objectFit: 'cover'
  },
  compactPhoto: {
    width: '12.5rem',
    height: '12.5rem',
    borderRadius: '1rem',
    objectFit: 'cover'
  },
  removePhoto: {
    position: 'absolute',
    bottom: '1.75rem',
    right: '0.35rem',
    backgroundColor: Colors.black,
    '&:hover': {
      backgroundColor: Colors.black
    }
  },
  removePhotoCompact: {
    width: '2rem',
    height: '2rem',
    position: 'absolute',
    bottom: '1.75rem',
    right: '0.35rem',
    backgroundColor: Colors.black,
    '&:hover': {
      backgroundColor: Colors.black
    }
  },
  closeIconCompact: {
    maxHeight: '1.25rem',
    maxWidth: '1.25rem'
  }
}))

export default function ImageUploadButton(props) {
  const theme = useTheme()
  const xlMatches = useMediaQuery(theme.breakpoints.down('xl'))
  const mdMatches = useMediaQuery(theme.breakpoints.down('lg'))
  const classes = useStyles()
  const { t } = useTranslation()

  const [alertVisible, setAlertVisible] = useState(false)

  const openAlert = () => setAlertVisible(true)
  const closeAlert = () => setAlertVisible(false)

  const removePhoto = () => {
    setAlertVisible(false)
    props.removePhoto()
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/*',
    multiple: false,
    noDrag: true,
    noClick: true,
    noKeyboard: true,
    onDrop: props.onFileUpload
  })
  const style = baseStyle

  const renderPhoto = () => {
    if (props.photo) {
      return (
        <img
          src={props.photo}
          className={classes.photo}
          alt='profile'
        />
      )
    }
    return (
      <Button
        leftIcon={PhotoIcon}
        text={t('add_photo')}
        onClick={open}
        disabled={props.disabled}
        outlined
      />
    )
  }

  const renderRemovePhoto = () => {
    if (props.photo && props.removePhoto) {
      return (
        <>
          <IconButton
            onClick={openAlert}
            className={props.compact ? classes.removePhotoCompact : classes.removePhoto}
            disabled={props.disabled}
            size="large"
          >
            <img
              src={CloseIcon}
              className={props.compact ? classes.closeIconCompact : classes.closeIcon}
              alt='icon'
            />
          </IconButton>
          <Dialog
            open={alertVisible}
            handleClose={closeAlert}
            handleOk={removePhoto}
            title={t('delete_photo')}
            description={t('delete_photo_description')}
          />
        </>
      )
    }
    return null
  }

  return (
    <div className={classes.root}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {renderPhoto()}
      </div>
      {renderRemovePhoto()}
    </div>
  )
}
