import { z } from 'zod'

export const PricableItemSchema = z.object({
  id: z.number(),
  name: z.string(),
  pricableItemType: z.string(),
  billingCode: z.string(),
  salaryCode: z.string(),
  createdAt: z.string().transform((val) => val ? new Date(val) : null),
  updatedAt: z.string().transform((val) => val ? new Date(val) : null)
})

export type PricableItemType = z.infer<typeof PricableItemSchema>
