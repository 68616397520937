import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import moment from 'moment'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import { PageContainer, PageHeader, PageActions, PageContent, PollForm, Checkbox, Input, Button } from '../../../Components'
import { useNavigate } from 'react-router-dom'
import { Colors } from '../../../Utils/theme'
import { Lang } from '../../../Constants'
import { editorToHtml } from '../../../Components/Common/EditorInput/editor.transform'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    padding: '2.5rem'
  },
  rootContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  main: {
    flex: 1
  },
  sidebar: {
    marginTop: '4rem'
  },
  box: {
    border: `1px solid ${Colors.border}`,
    borderRadius: '.625rem',
    padding: '2rem'
  },
  h3: {
    margin: '0 0 1.5rem',
    fontFamily: 'Ambit',
    fontSize: '1.25rem',
    color: Colors.navy,
    textShadow: `3px 3px ${Colors.lightGreen}`,
    lineHeight: '1.1',
    textTransform: 'uppercase'
  },
  publishTimeContainer: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'row'
  },
  spacer: {
    width: '1rem',
    height: '1rem'
  },
  cancelButton: {
    border: 0,
  },
  cancelButtonText: {
    fontWeight: 700
  }
}))

enum PublishType {
  now = 'now',
  draft = 'draft',
  scheduled = 'scheduled'
}

enum ExpireType {
  notSet = 'not_set',
  scheduled = 'scheduled'
}


function CreatePoll() {
  const classes = useStyles()

  const { sessionStore, appStore, pollStore }: any = useStore()
  const navigate = useNavigate()

  const { t } = useTranslation()

  const [nameFi, setNameFi] = useState('')
  const [nameEn, setNameEn] = useState('')
  const [titleFi, setTitleFi] = useState('')
  const [titleEn, setTitleEn] = useState('')
  const [video, setVideo] = useState(null)
  const [questions, setQuestions] = useState([])
  const [category, setCategory] = useState('general')
  const [submitted, setSubmitted] = useState(false)

  const [lang, setLang] = useState(Lang.fi)

  const [publishType, setPublishType] = useState(PublishType.scheduled)

  const [publishTime, setPublishTime] = useState(moment().add(0, 'minutes').startOf('hour').format('HH:mm'))
  const [publishDate, setPublishDate] = useState(moment().add(0, 'minutes').startOf('hour').format('YYYY-MM-DD'))

  const [expireType, setExpireType] = useState(ExpireType.notSet)

  const [expireTime, setExpireTime] = useState<any>(moment().add(30, 'days').endOf('day').format('HH:mm'))
  const [expireDate, setExpireDate] = useState<any>(moment().add(30, 'days').endOf('day').format('YYYY-MM-DD'))

  useEffect(() => {
    if (appStore.notification && appStore.notification.type === 'success') {
      navigate(-1)
    }
  }, [appStore.notification])

  const handleCancel = () => {
    navigate(-1)
  }

  const handleCreate = () => {
    setSubmitted(true)
    const poll: any = {
      nameFi,
      nameEn,
      titleFi,
      titleEn,
      video,
      questions,
      type: category,
      draft: false
    }

    // Published at
    if (publishType === PublishType.draft) {
      poll.draft = true
      poll.validFrom = null
    } else if (publishType === PublishType.now) {
      poll.validFrom = moment().add(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
    } else {
      // Specific time
      const date = moment(publishDate).format('YYYY-MM-DD')
      const time = publishTime
      poll.validFrom =  moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss')
    }

    // Expires at
    if (expireType === ExpireType.scheduled) {
      const date = moment(expireDate).format('YYYY-MM-DD')
      const time = expireTime
      poll.validUntil = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss')
    } else {
      poll.validUntil = null
    }

    pollStore.createPoll(poll)
  }

  const getCategoryOptions = () => {
    return [
      { label: t('general'), value: 'general' }
    ]
  }

  const getPublicationOptions = () => {
    return [
      {
        label: t('immediately'),
        value: PublishType.now
      },
      {
        label: t('save_as_draft'),
        value: PublishType.draft
      },
      {
        label: t('as_scheduled'),
        value: PublishType.scheduled
      }
    ]
  }

  const getExpireptions = () => {
    return [
      {
        label: t('for_now'),
        value: ExpireType.notSet
      },
      {
        label: t('as_scheduled'),
        value: ExpireType.scheduled
      }
    ]
  }


  return (
    <PageContainer>
      <div className={classes.rootContainer}>
        <div className={classes.main}>
          <PageHeader
            title={t('create_poll')}
            description={t('create_poll_description')}
            lang={lang}
            setLang={setLang}
          />
          <PageContent size='medium'>
            <PollForm
              lang={lang}
              nameFi={nameFi}
              setNameFi={setNameFi}
              nameEn={nameEn}
              setNameEn={setNameEn}
              titleFi={titleFi}
              setTitleFi={setTitleFi}
              titleEn={titleEn}
              setTitleEn={setTitleEn}
              questions={questions}
              setQuestions={setQuestions}
              video={video}
              setVideo={setVideo}
              categoryOptions={getCategoryOptions()}
              category={category}
              setCategory={setCategory}
              submitted={submitted}
              mode='create'
            />
          </PageContent>
        </div>
        <div className={classes.sidebar}>
          <PageActions
            user={sessionStore.user}
            logout={() => sessionStore.logout()}
          >
            <h3 className={classes.h3}>{t('publication')}</h3>
            <div className={classes.box}>
              {getPublicationOptions().map(item => {
                return (
                  <div>
                    <Checkbox
                      label={item.label}
                      checked={publishType === item.value}
                      onChange={() => setPublishType(item.value)}
                      radio
                    />
                  </div>
                )
              })}
              <div className={classes.publishTimeContainer}>
                <Input
                  value={publishDate}
                  onChange={setPublishDate}
                  type='date'
                />
                <div className={classes.spacer} />
                <Input
                  value={publishTime}
                  onChange={setPublishTime}
                  type='time'
                />
              </div>
            </div>
            <div className={classes.spacer} />


            <h3 className={classes.h3}>{t('open_until')}</h3>
            <div className={classes.box}>
              {getExpireptions().map(item => {
                return (
                  <div>
                    <Checkbox
                      label={item.label}
                      checked={expireType === item.value}
                      onChange={() => setExpireType(item.value)}
                      radio
                    />
                  </div>
                )
              })}
              {expireType !== ExpireType.notSet && (
                <div className={classes.publishTimeContainer}>
                  <Input
                    value={expireDate}
                    onChange={setExpireDate}
                    type='date'
                  />
                  <div className={classes.spacer} />
                  <Input
                    value={expireTime}
                    onChange={setExpireTime}
                    type='time'
                  />
                </div>
              )}
            </div>
            <div className={classes.spacer} />

            <Button
              text={t('save')}
              onClick={handleCreate}
              fullWidth
            />
            <div className={classes.spacer} />
            <Button
              text={t('cancel')}
              onClick={handleCancel}
              outlined
              fullWidth
              buttonStyle={classes.cancelButton}
              buttonTextStyle={classes.cancelButtonText}
            />
          </PageActions>
        </div>
      </div>
    </PageContainer>
  )
}

export default observer(CreatePoll)
