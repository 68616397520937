import apisauce from 'apisauce'
import StorageService from './StorageService'

const create = () => {
  const api = apisauce.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 20000
  })

  api.addAsyncRequestTransform(request => async () => {
    const authToken = StorageService.getItem('token')
    if (authToken === null) {
      return
    }
    request.headers["Authorization"] = `Bearer ${authToken}`
  })

  return {
    getPrivacyPolicy: (locale: string) => api.get('app/privacy-policy', { locale }),
    // Auth
    login: (data) => api.post('auth/login', data),
    logout: () => api.put('auth/logout'),
    logoutOthers: () => api.put('auth/logout-others'),
    keepAuthAlive: () => api.put('auth/keep-alive'),
    requestPasswordReset: (data) => api.post('auth/password-reset', data),
    completePasswordResetWithCode: (data) => api.put('auth/password-reset', data),
    createAccount: (data) => api.post('auth/create-account', data),
    resetCustomerPassword: (data) => api.post('auth/reset-customer-password', data),

    // Analytics
    getCustomersSummary: (date) => api.get('analytics/customer-summary', { date }),
    getEmployeesSummary: (date) => api.get('analytics/employees-summary', { date }),

    // Polls
    getPolls: (params) => api.get('poll', params),
    getPoll: (id) => api.get(`poll/${id}`),
    createPoll: (poll) => api.post('poll', poll),
    updatePoll: (id, poll) => api.put(`poll/${id}`, poll),
    getPollAnswers: (id) => api.get(`poll/answers/${id}`),

    // Shifts
    getShifts: (params) => api.get('shift', params),
    getShift: (shiftId) => api.get(`shift/${shiftId}`),

    // Notifications
    getNotifications: (params) => api.get('notification', params),
    getNotification: (id) => api.get(`notification/${id}`),
    createNotification: (data) => api.post(`notification`, data),
    updateNotification: (id, data) => api.put(`notification/${id}`, data),

    // Feedbacks
    getGeneralFeedbacks: (params) => api.get('feedback/general-feedback', params),
    getShiftFeedbacks: (params) => api.get('feedback/shift-feedback', params),

    // User
    getMe: () => api.get('user/me'),
    updateMe: (data) => api.put('user/me', data),
    updateMyPhoto: (data) => {
      const formData = new FormData()
      formData.append('file', data)
      const options = { headers: { 'Content-Type': 'multipart/form-data' } }
      return api.post('user/me/photo', formData, options)
    },
    updateUserPhoto: (userId, data) => {
      const formData = new FormData()
      formData.append('file', data)
      const options = { headers: { 'Content-Type': 'multipart/form-data' } }
      return api.post(`/user/my/${userId}/photo`, formData, options)
    },
    changePassword: (data) => api.put('user/me/password', data),
    createUser: (data) => api.post('user', data),
    updateUser: (id, data) => api.put(`user/${id}`, data),
    getUser: (id) => api.get(`user/${id}`),
    getUsers: (params) => api.get('user', params),

    // Customers
    getCustomers: (params) => api.get('customer', params),
    getCustomer: (id) => api.get(`customer/${id}`),

    // Employees
    getEmployees: (params) => api.get('employee', params),
    getEmployee: (id) => api.get(`employee/${id}`),

    // Files
    getVideoEmbed: (url) => api.get('media/video-embed', { url }),

    uploadPhoto: (data) => {
      const formData = new FormData()
      formData.append('file', data)
      const options = { headers: { 'Content-Type': 'multipart/form-data' } }
      return api.post(`file/photo`, formData, options)
    }
  }
}

const Api = create()

export default Api
