import React, { ReactElement } from 'react'
import { Input, Select, Title } from '../index'
import { Grid } from '@mui/material'
import { CustomerType } from '../../Schemas'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../Models/RootStore'
import makeStyles from '@mui/styles/makeStyles'

interface Props {
  customer: CustomerType
  onChange: (key: string, value: string | number) => void
}

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: '3rem'
  }
}))

const CustomerForm = ({ customer, onChange }: Props): ReactElement => {
  const { customerStore }: any = useStore()
  const { t } = useTranslation()
  const classes = useStyles()

  const types = customerStore.types
  const categories = customerStore.categories
  const statuses = customerStore.statuses

  const generateOptions = (items: { id: number, name: string }[], translate?: string) => {
    return items.map(item => ({
      label: !!translate ? t(`${translate}_${item.name}`) : item.name,
      value: item.id
    }))
  }

  const CustomerInfo = <div className={classes.section}>
    <Title title={t('customer_info')} type="main" />
    <Grid container spacing={4}>
      <Grid item md={6} xs={12}>
        <Select
          label={t('customer_type')}
          options={generateOptions(types, 'customer_type')}
          value={types.find(t => t.name === customer.customerType)?.id}
          onChange={value => onChange('customerType', types.find(t => t.id === value)?.name)}
          noMargin
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Select
          label={t('category')}
          options={generateOptions(categories)}
          value={categories.find(c => c.name === customer.category)?.id}
          onChange={value => onChange('category', categories.find(c => c.id === value)?.name)}
          noMargin
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Input
          value={customer.name}
          onChange={value => onChange('name', value)}
          label={t('name')}
          noMargin
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Input
          value={customer.customerNumber}
          onChange={value => onChange('customerNumber', value)}
          label={t('customer_number')}
          noMargin
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Input
          value={customer.area}
          onChange={value => onChange('area', value)}
          label={t('area')}
          noMargin
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Select
          label={t('customer_status')}
          options={generateOptions(statuses, 'customer_status')}
          value={statuses.find(s => s.name === customer.status)?.id}
          onChange={value => onChange('status', statuses.find(t => t.id === value)?.name)}
          noMargin
        />
      </Grid>
    </Grid>
  </div>

  const CompanyInfo = <div className={classes.section}>
    <Title title={t('company_info')} type="subtitle" />
    <Grid container spacing={4}>
      <Grid item md={6} xs={12}>
        <Input
          value={customer.company.officialName}
          label={t('official_name')}
          noMargin
          disabled
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Input
          value={customer.company.costCenterCode}
          label={t('cost_center_code')}
          noMargin
          disabled
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Input
          value={customer.company.email}
          label={t('email')}
          noMargin
          disabled
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Input
          value={customer.company.phone}
          label={t('phone')}
          noMargin
          disabled
        />
      </Grid>
    </Grid>
  </div>

  const PersonInfo = <div className={classes.section}>
    <Title title={t('contact_info')} type="subtitle" />
    <Grid container spacing={4}>
      <Grid item md={6} xs={12}>
        <Input
          value={customer.person.firstName}
          label={t('first_name')}
          onChange={value => onChange('person.firstName', value)}
          noMargin
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Input
          value={customer.person.lastName}
          label={t('last_name')}
          onChange={value => onChange('person.lastName', value)}
          noMargin
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Input
          value={customer.person.email}
          label={t('email')}
          onChange={value => onChange('person.email', value)}
          noMargin
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Input
          value={customer.person.phone}
          label={t('phone')}
          onChange={value => onChange('person.phone', value)}
          noMargin
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          value={customer.person.dateOfBirth}
          label={t('date_of_birth')}
          onChange={value => onChange('person.dateOfBirth', value)}
          noMargin
        />
      </Grid>
      <Grid item md={8} xs={12}>
        <Input
          value={customer.person.streetAddress}
          label={t('street_address')}
          onChange={value => onChange('person.streetAddress', value)}
          noMargin
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          value={customer.person.postalCode}
          label={t('postal_code')}
          onChange={value => onChange('person.postalCode', value)}
          noMargin
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          value={customer.person.city}
          label={t('city')}
          onChange={value => onChange('person.city', value)}
          noMargin
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          value={customer.person.country}
          label={t('country')}
          onChange={value => onChange('person.country', value)}
          noMargin
        />
      </Grid>
    </Grid>
  </div>

  return (
    <>
      {CustomerInfo}
      {!!customer.company?.extId && CompanyInfo}
      {!!customer.person?.extId && PersonInfo}
    </>
  )
}

export default CustomerForm
