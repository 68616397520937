import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import { TableHeader, DataMessage } from '../index'
import UserInviteTableRow from './UserInviteTableRow'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  spacer: {
    height: '1rem',
    [theme.breakpoints.down('xl')]: {
      height: '0.75rem'
    }
  }
}))

function UserInviteTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.users) {
      return props.users.map((user) => (
        <React.Fragment key={user.id}>
          <div className={classes.spacer} />
          <UserInviteTableRow user={user} inviteUser={props.inviteUser} />
        </React.Fragment>
      ))
    }
    return null
  }

  return (
    <>
      <Table classes={{ root: classes.root }}>
        <TableHeader columnNames={[t('email'), t('role'), t('valid_until'), t('actions')]} />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      <DataMessage
        loading={props.loading}
        data={props.users}
        message={t('no_invites')}
      />
    </>
  )

}

export default UserInviteTable
