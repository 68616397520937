import React, { ReactElement, useEffect, useState } from 'react'
import { Dialog, Select } from '../index'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../Models/RootStore'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: '300px'
  },
  group: {
    marginBottom: '0.6rem'
  }
}))

interface Props {
  open: boolean
  onClose: () => void
  onConfirm: (props: { contractType: number }) => void
  filterParams: { contractType: number }
}

const EmployeeFilterDialog = ({ open, onClose, onConfirm, filterParams }: Props): ReactElement => {
  const { t } = useTranslation()
  const [filterContractType, setFilterContractType] = useState(filterParams.contractType)
  const { employeeStore }: any = useStore()
  const classes = useStyles()

  useEffect(() => {
    if (filterParams.contractType !== filterContractType) {
      setFilterContractType(filterParams.contractType)
    }
  }, [filterParams.contractType])

  const contractTypeOptions =
    [
      {
        label: t('all_options'),
        value: 0
      },
      ...(employeeStore.contractTypes || []).map(item => (
        {
          label: t(`employee_contract_type_${item.name}`),
          value: item.id
        }
      ))
    ]

  const handleClose = (): void => {
    setFilterContractType(filterParams.contractType)
    onClose()
  }

  const handleConfirm = (): void => {
    onConfirm({ contractType: filterContractType })
  }

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      handleOk={handleConfirm}
      title={t('filter_employees')}
      okText={t('show_results')}
    >
      <div className={classes.content}>
        <div className={classes.group}>
          <Select
            label={t('employee_contract_type')}
            options={contractTypeOptions}
            value={filterContractType}
            onChange={setFilterContractType}
            noMargin
          />
        </div>
      </div>
    </Dialog>
  )
}

export default EmployeeFilterDialog
