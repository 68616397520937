import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import { ShiftFeedbackSchema, ShiftFeedbackType } from '../Schemas'

export default class ShiftFeedbackStore {
  rootStore
  shiftFeedbacks: ShiftFeedbackType[] = []
  kinds = []
  skip = 0
  take = 50
  total = 0 // Number of total results
  totalFiltered = 0 // Number of total results after filtering
  loading = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setLoading = (loading) => { this.loading = loading }
  setShiftFeedbacks = (shiftFeedbacks) => { this.shiftFeedbacks = shiftFeedbacks }
  setTotal = (total) => { this.total = total }
  setTotalFiltered = (total) => { this.totalFiltered = total }
  setKinds = (kinds) => { this.kinds = kinds }
  setSkip = (skip) => { this.skip = skip }
  setTake = (take) => { this.take = take }

  async getShiftFeedbacks(params) {
    const search = params.search || ''
    const take = params.perPage || 50
    const skip = (params.page || 0) * take
    const kind = (params.kind !== 0) ? params.kind : undefined
    const sort = params.sort || undefined

    this.setLoading(true)
    const response: any = await Api.getShiftFeedbacks({ skip, take, search, kind, sort })
    if (response.ok) {
      const validFeedbacks: ShiftFeedbackType[] = []
      response.data.items.forEach(item => {
        try {
          const feedback = ShiftFeedbackSchema.parse(item)
          validFeedbacks.push(feedback)
        } catch (error) {
          console.error("Invalid feedback: ", item, error)
        }
      })
      this.setShiftFeedbacks(validFeedbacks)
      this.setTotal(response.data.total)
      this.setTotalFiltered(response.data.totalFiltered)
      this.setKinds(response.data.kinds)
    }
    this.setLoading(false)
  }
}
