import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import { Colors } from '../../../Utils/theme'
import { FinlandMap, PageHeader, BarChart, AreaChart } from '../../../Components'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    padding: '2.5rem'
  },
  headerRow: {
    display: 'grid',
    gap: '2rem',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr'
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr'
    }
  },
  headerBox: {
    flex: 1,
    border: `1px solid ${Colors.border}`,
    borderRadius: '.625rem',
    padding: '1.25rem',
    textAlign: 'center'
  },
  headerTitle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '1.25rem',
    color: Colors.navy,
    fontWeight: 900,
    textShadow: `.125rem .125rem 0 ${Colors.lightGreen}`
  },
  headerValue: {
    fontSize: '4rem',
    color: Colors.navy,
    fontWeight: 400,
    textShadow: `.125rem .125rem 0 ${Colors.lightGreen}`
  },
  headerNote: {
    color: Colors.text
  },
  horizontalSpacer: {
    width: '2rem'
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row'
    }
  },
  topLeftContainer: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column'
  },
  topRightContainer: {
    paddingTop: '1rem',
    width: '18rem',
    minWidth: '18rem',
    minHeight: '28rem',
    border: `1px solid ${Colors.border}`,
    borderRadius: '.625rem',
  },
  mapContainer: {
    marginTop: '1rem',
    widht: '100%',
    overflow: 'hidden'
  },
  mapInnerContainer: {
    width: '30rem',
    marginLeft: '-25%'
  },
  feedbacksContainer: {
    flex: 1,
    marginTop: '2rem',
    border: `1px solid ${Colors.border}`,
    borderRadius: '.625rem',
    justifyContent: 'flex-start'
  },
  footerContainer: {
    marginTop: '2rem',
    border: `1px solid ${Colors.border}`,
    borderRadius: '.625rem'
  },
  chartHeadingRow: {
    paddingLeft: '2rem',
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  chartTitle: {
    marginRight: '.5rem',
    textTransform: 'uppercase',
    fontSize: '1.25rem',
    color: Colors.navy,
    fontWeight: 900,
    textShadow: `.125rem .125rem 0 ${Colors.lightGreen}`
  },
  chartNote: {
    fontWeight: 300,
    color: Colors.navy
  }
}))

enum TabType {
  customers = 'customers',
  employees = 'employees'
}

function Dashboard() {
  const classes = useStyles()

  const { sessionStore, analyticsStore }: any = useStore()

  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'))

  const [tab, setTab] = useState(TabType.customers)

  const { t } = useTranslation()

  const handleSetCurrentDate = (value) => {
    setCurrentDate(value)
    analyticsStore.getCustomersSummary(value)
    analyticsStore.getEmployeesSummary(value)
  }

  useEffect(() => {
    analyticsStore.getCustomersSummary(currentDate)
    analyticsStore.getEmployeesSummary(currentDate)
  }, [])

  const formatChange = (ratio) => {
    let changePercent: any = 0
    if (ratio) {
      changePercent = Math.round(ratio * 100)
    }
    if (changePercent > 0) {
      changePercent = `+${changePercent}`
    }
    return `${changePercent}% ${t('previous_day_short_alt')}`
  }

  const renderCustomerHeader = () => {
    const {
      shifts,
      newCustomers,
      feedbacks,
      recommendations
    } = analyticsStore?.customersSummary?.counter

    const labels = [
      t('shifts'),
      t('new_customers'),
      t('feedbacks'),
      t('recommendations')
    ]

    return (
      <div className={classes.headerRow}>
        {[shifts, newCustomers, feedbacks, recommendations].map((item, index) => {
          return (
            <div key={index} className={classes.headerBox}>
              <div className={classes.headerTitle}>
                {labels[index]}
              </div>
              <div className={classes.headerValue}>
                {item?.value}
              </div>
              <div className={classes.headerNote}>
                {formatChange(item?.change)}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const renderEmployeeHeader = () => {
    const {
      employees,
      shifts,
      abcenses,
      strength
    } = analyticsStore?.employeesSummary?.counter

    const formatPersonChange = (value) => {
      const diff = value < 0 ? `${value}` : `+${value}`
      return `${diff} ${t('person_compared_to_previous_day_short')}`
    }

    const labels = [
      t('employees'),
      t('shifts'),
      t('absences'),
      t('strength_percent')
    ]

    return (
      <div className={classes.headerRow}>
        {[employees, shifts, abcenses, strength].map((item, index) => {
          return (
            <div key={index} className={classes.headerBox}>
              <div className={classes.headerTitle}>
                {labels[index]}
              </div>
              <div className={classes.headerValue}>
                {item?.value}
              </div>
              <div className={classes.headerNote}>
                {index === 0 ? formatPersonChange(item?.change) : formatChange(item?.change)}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const renderCustomerFeedbacks = () => {
    return (
      <div className={classes.feedbacksContainer}>
        <div className={classes.chartHeadingRow}>
          <div className={classes.chartTitle}>{t('feedback_division')}</div>
          <div className={classes.chartNote}>- {t('previous_30d_short')}</div>
        </div>
        <AreaChart
          data={analyticsStore?.customersSummary?.feedbackSeries?.series}
        />
      </div>
    )
  }

  // Render loading spinner
  if (tab === TabType.customers && !analyticsStore.customersSummary) return null

  const renderCustomerView = () => {
    return (
        <>
          <div className={classes.topContainer}>
            <div className={classes.topLeftContainer}>
              {renderCustomerHeader()}
              {renderCustomerFeedbacks()}
            </div>
            <div className={classes.topRightContainer}>
              <div className={classes.headerTitle}>{t('shifts_on_map')}</div>
              <div className={classes.mapContainer}>
                <div className={classes.mapInnerContainer}>
                  <FinlandMap />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.footerContainer}>
            <div className={classes.chartHeadingRow}>
              <div className={classes.chartTitle}>{t('progress_of_shift_counts')}</div>
              <div className={classes.chartNote}>- {t('previous_30d_short')}</div>
            </div>
            <BarChart
              data={analyticsStore?.customersSummary?.shiftSeries?.series}
              stacked
            />
          </div>
        </>
    )
  }

  const renderEmployeeView = () => {
    return (
      <>
        <div className={classes.topContainer}>
          <div className={classes.topLeftContainer}>
            {renderEmployeeHeader()}
            {renderCustomerFeedbacks()}
          </div>
          <div className={classes.topRightContainer}>
            <div className={classes.headerTitle}>{t('shifts_on_map')}</div>
            <div className={classes.mapContainer}>
              <div className={classes.mapInnerContainer}>
                <FinlandMap />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.footerContainer}>
          <div className={classes.chartHeadingRow}>
            <div className={classes.chartTitle}>{t('progress_of_shift_counts')}</div>
            <div className={classes.chartNote}>- {t('previous_30d_short')}</div>
          </div>
          <BarChart
            data={analyticsStore?.customersSummary?.shiftSeries?.series}
            stacked
          />
        </div>
      </>
    )
  }


  return (
    <div className={classes.root}>
      <PageHeader
        title={t('summary')}
        date={currentDate}
        setDate={handleSetCurrentDate}
        user={sessionStore.user}
        logout={() => sessionStore.logout()}
        tabs={[
          {
            name: t('customers'),
            value: TabType.customers
          },
          {
            name: t('employees'),
            value: TabType.employees
          }
        ]}
        setTab={setTab}
        tab={tab}
      />
      <div className={classes.container}>
        {tab === TabType.customers ? renderCustomerView() : renderEmployeeView()}
      </div>
    </div>
  )
}

export default observer(Dashboard)
