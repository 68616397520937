import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../../Models/RootStore'
import { useNavigate } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import { Title, Input, Button } from '../../../Components'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '44rem',
    [theme.breakpoints.down('xl')]: {
      maxWidth: '38rem'
    }
  },
  description: {
    marginBottom: '2rem'
  },
  button: {
    minHeight: '3.125rem',
    marginTop: '0.5rem',
    marginBottom: '1.75rem'
  }
}))

const VerifyEmail = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { sessionStore }: any = useStore()
  const navigate = useNavigate()

  let initialCode = ''
  if (window.location.search) {
    const parts = window.location.search.split('code=')
    if (parts.length > 1 && parts[1].split('&')[0].length === 36) {
      initialCode = parts[1].split('&')[0]
    }
  }

  useEffect(() => {
    if (initialCode) {
      sessionStore.getUserInvite(initialCode)
    }
  }, [])

  const [password, setPassword] = useState('')

  const toLogin = () => navigate('/login')
  const verifyUserInvite = () => sessionStore.verifyUserInvite(initialCode, password, toLogin)

  const getDescription = () => {
    if (initialCode && !!sessionStore.emailVerification) {
      return t('verify_email_description')
    }
    return t('invalid_or_expired_code_description')
  }

  const renderPasswordInput = () => {
    if (initialCode && !!sessionStore.emailVerification) {
      return (
        <Input
          label={t('password')}
          value={password}
          onChange={setPassword}
          type='password'
          autoFocus
        />
      )
    }
    return null
  }

  const renderVerifyButton = () => {
    if (initialCode && !!sessionStore.emailVerification) {
      return (
        <Button
          buttonStyle={classes.button}
          text={t('verify')}
          onClick={verifyUserInvite}
          fullWidth
        />
      )
    }
    return null
  }

  return (
    <div className={classes.root}>
      <Title
        title={t('verify_email_address')}
        description={getDescription()}
        type='main'
        descriptionStyle={classes.description}
      />
      {renderPasswordInput()}
      {renderVerifyButton()}
    </div>
  )
}

export default observer(VerifyEmail)
