import React, { useState, useCallback } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import Sidebar from './Sidebar'
// import Header from './Header'
import Notification from '../../Components/Common/Notification'
import { useStore } from '../../Models/RootStore'
import { AvailablePrimaryRoutes, AvailableSecondaryRoutes } from '../../Constants'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    marginLeft: '15rem', /* Sidebar width */
    [theme.breakpoints.up('lg')]: {
      marginLeft: '17rem',
    }
  },
  sidebarContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 999
  }
}))

function AppLayout(props) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const toggleDrawer = () => setOpen(!open)

  const { appStore, sessionStore }: any = useStore()
  const { notification, clearNotification } = appStore

  const getPrimaryNavigationItems = useCallback(() => {
    if (sessionStore.user) {
      const role = sessionStore.user?.role ?? null
      return AvailablePrimaryRoutes(role)
    }
    return []
  }, [sessionStore.user])

  const getSecondaryNavigationItems = useCallback(() => {
    if (sessionStore.user) {
      const role = sessionStore.user?.role ?? null
      return AvailableSecondaryRoutes(role)
    }
    return []
  }, [sessionStore.user])

  return (
    <div className={classes.root}>
      <div className={classes.sidebarContainer}>
        <Sidebar
          open={open}
          toggleDrawer={toggleDrawer}
          primaryNavigationItems={getPrimaryNavigationItems()}
          secondaryNavigationItems={getSecondaryNavigationItems()}
          user={sessionStore.user}
          notifications={appStore.systemNotifications}
        />
      </div>
      <main className={classes.content} id="app-content">
        {props.children}
      </main>
      <Notification notification={notification} clearNotification={clearNotification} />
    </div>
  )
}

export default observer(AppLayout)
