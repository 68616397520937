import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import { ShiftSchema, ShiftType } from '../Schemas'

export default class ShiftStore {
  rootStore
  shifts: ShiftType[] = []
  total = 0 // Number of total results
  totalFiltered = 0 // Number of total results after filtering
  types: { id: number, name: string}[] = []
  statuses: { id: number, name: string}[] = []
  loading = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setLoading = (loading) => { this.loading = loading }
  setShifts = (shifts) => { this.shifts = shifts }
  setTotal = (total) => { this.total = total }
  setTotalFiltered = (total) => { this.totalFiltered = total }
  setTypes = (types) => { this.types = types }
  setStatuses = (statuses) => { this.statuses = statuses }

  async getShifts(params): Promise<void> {
    const search = params.search || ''
    const take = params.perPage || 50
    const skip = (params.page || 0) * take
    const sort = params.sort || undefined
    const type = (params.type !== 0) ? params.type : undefined
    const status = (params.status !== 0) ? params.status : undefined
    const startDate = params.startDate ? `${params.startDate} 00:00:00` : undefined
    const endDate = params.endDate ? `${params.endDate} 23:59:59` : undefined
    const startTime = params.startTime || undefined
    const endTime = params.endTime || undefined

    this.setLoading(true)
    const response: any = await Api.getShifts({
      skip, take, type, status, search, sort, startDate, endDate, startTime, endTime
    })
    if (response.ok) {
      const validShifts: ShiftType[] = []
      response.data.items.forEach(item => {
        try {
          const shift = ShiftSchema.parse(item)
          validShifts.push(shift)
        } catch (error) {
          console.error("Invalid shift: ", item)
          if (error instanceof Error) {
            console.error("Zod error: ", error.message)
          }
        }
      })
      this.setShifts(validShifts)
      this.setTotal(response.data.total)
      this.setTotalFiltered(response.data.totalFiltered)
      this.setTypes(response.data.types)
      this.setStatuses(response.data.statuses)
    }
    this.setLoading(false)
  }

  async getShift(id: number): Promise<ShiftType | null> {
    this.setLoading(true)
    const response: any = await Api.getShift(id)
    if (response.ok) {
      try {
        return ShiftSchema.parse(response.data)
      } catch (error) {
        console.error("Invalid shift: ", response.data, error)
      }
    }
    this.setLoading(false)
    return null
  }
}
