import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import { GeneralFeedbackSchema, GeneralFeedbackType } from '../Schemas'

export default class GeneralFeedbackStore {
  rootStore
  generalFeedbacks: GeneralFeedbackType[] = []
  categories = []
  skip = 0
  take = 50
  total = 0 // Number of total results
  totalFiltered = 0 // Number of total results after filtering
  loading = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setLoading = (loading) => { this.loading = loading }
  setGeneralFeedbacks = (generalFeedbacks) => { this.generalFeedbacks = generalFeedbacks }
  setTotal = (total) => { this.total = total }
  setTotalFiltered = (total) => { this.totalFiltered = total }
  setCategories = (categories) => { this.categories = categories }
  setSkip = (skip) => { this.skip = skip }
  setTake = (take) => { this.take = take }

  async getGeneralFeedbacks(params) {
    const search = params.search || ''
    const take = params.perPage || 50
    const skip = (params.page || 0) * take
    const sort = params.sort || undefined

    this.setLoading(true)
    const response: any = await Api.getGeneralFeedbacks({ skip, take, search, sort })
    if (response.ok) {
      const validFeedbacks: GeneralFeedbackType[] = []
      response.data.items.forEach(item => {
        try {
          const feedback = GeneralFeedbackSchema.parse(item)
          validFeedbacks.push(feedback)
        } catch (error) {
          console.error("Invalid feedback: ", item, error)
        }
      })
      this.setGeneralFeedbacks(validFeedbacks)
      this.setTotal(response.data.total)
      this.setTotalFiltered(response.data.totalFiltered)
    }
    this.setLoading(false)
  }
}
