import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import {
  ShiftFeedbackTable,
  PageContainer,
  PageHeader,
  PageContent,
  IndexActions,
  TableHeaderActions
} from '../../../Components'
import { useNavigate } from 'react-router-dom'
import { uniq } from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    padding: '2.5rem'
  }
}))

const SORT_KEYS = ['kind', 'giverName', 'taskName', 'startTime', 'employeeName']
const SORT_DIRECTIONS = ['asc', 'desc']

enum TabType {
  general = 'general',
  cleaning = 'cleaning'
}

function ShiftFeedbacks() {
  const classes = useStyles()

  const { sessionStore, shiftFeedbackStore }: any = useStore()
  const navigate = useNavigate()

  const [search, setSearch] = useState('')
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState(`${SORT_KEYS[0]}_${SORT_DIRECTIONS[0]}`)
  const [kind, setKind] = useState(0)
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const getKindOptions = () => {
    return [
      {
        label: t('all_feedbacks'),
        value: 0
      },
      ...(shiftFeedbackStore.kinds || []).map(item => {
        return {
          label: t(`feedback_kind_${item.name}`),
          value: item.id
        }
      })
    ]
  }

  const toggleSelect = (id: number) => {
    // Try remove
    const newIds: number[] = selectedIds.filter(val => val !== id)
    if (newIds.length !== selectedIds.length) {
      setSelectedIds(newIds)
    } else {
      // Add new
      setSelectedIds([...selectedIds, id])
    }
  }
  const selectAll = (ids) => {
    setSelectedIds(uniq([...selectedIds, ...ids]))
  }
  const clearSelect = () => setSelectedIds([])

  useEffect(() => {
    refreshItems()
  }, [])

  const { t } = useTranslation()

  const refreshItems = (newParams = {}) => {
    shiftFeedbackStore.getShiftFeedbacks({
      search,
      perPage,
      page,
      kind,
      sort,
      ...newParams
    })
  }

  const handleTagChange = (value) => {
    if (value === TabType.general) {
      navigate('/general-feedbacks')
    }
  }

  const handleSetKind = (kind) => {
    setKind(kind)
    refreshItems({ kind })
  }

  const handleSetSearch = (search) => {
    setSearch(search)
    refreshItems({ search })
  }

  const handleSetPerPage = (perPage) => {
    setPerPage(perPage)
    refreshItems({ perPage })
  }

  const handleSetSort = (sort) => {
    setSort(sort)
    refreshItems({ sort })
  }

  const getSortOptions = () => {
    const options: any = []
    for (const direction of SORT_DIRECTIONS) {
      for (const sortKey of SORT_KEYS) {
        const fullSortKey = `${sortKey}_${direction}`
        options.push({
          value: fullSortKey,
          label: t(`sort_${fullSortKey}`)
        })
      }
    }
    return options
  }

  return (
    <PageContainer>
      <PageHeader
        title={t('app_feedbacks')}
        user={sessionStore.user}
        logout={() => sessionStore.logout()}
        tab={TabType.cleaning}
        setTab={handleTagChange}
        tabs={[
          {
            name: t('general_feedbacks_short'),
            value: TabType.general

          },
          {
            name: t('cleaning_feedbacks'),
            value: TabType.cleaning
          }
        ]}
      />
      <PageContent>
        <IndexActions
          sort={sort}
          setSort={handleSetSort}
          sortOptions={getSortOptions()}
          category={kind}
          setCategory={handleSetKind}
          categoryOptions={getKindOptions()}
        />
        <TableHeaderActions
          search={search}
          setSearch={handleSetSearch}
          perPage={perPage}
          setPerPage={handleSetPerPage}
          total={shiftFeedbackStore.totalFiltered}
        />
        <ShiftFeedbackTable
          loading={shiftFeedbackStore.loading}
          items={shiftFeedbackStore.shiftFeedbacks}
          sort={sort}
          sortKeys={SORT_KEYS}
          onSort={handleSetSort}
          selectedIds={selectedIds}
          toggleSelect={toggleSelect}
          selectAll={selectAll}
          clearSelect={clearSelect}
        />
      </PageContent>
    </PageContainer>
  )
}

export default observer(ShiftFeedbacks)
