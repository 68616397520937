import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  leftIcon: {
    marginLeft: '0.5rem',
    userSelect: 'none',
    height: '1rem',
    marginRight: '.5rem'
  },
  rightIcon: {
    height: '100%',
    width: '100%',
    maxHeight: '1.125rem',
    maxWidth: '1.125rem',
    marginLeft: '0.75rem',
    userSelect: 'none'
  },
  baseButton: {
    backgroundColor: Colors.navy,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    height: '2.75rem',
    borderRadius: '.625rem',
    alignItems: 'center',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: Colors.navy80
    },
    "&:active": {
      color: Colors.white
    }
  },
  fullWidth: {
    width: '100%'
  },
  baseButtonText: {
    whiteSpace: 'nowrap',
    margin: 0,
    fontFamily: 'Ambit',
    fontSize: '1rem',
    fontWeight: 700
  },
  // Button variants
  defaultButton: {
    backgroundColor: Colors.navy,
  },
  defaultButtonText: {
    color: Colors.white
  },
  outlinedButton: {
    backgroundColor: 'transparent',
    border: `1px solid ${Colors.navy}`,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.navy10
    },
    "&:active": {
      color: Colors.navy
    }
  },
  outlinedButtonText: {
    color: Colors.navy,
    fontWeight: 400
  },
  smallButton: {
    padding: '0.75rem',
    height: '2.75rem',
  },
  smallButtonText: {
    fontSize: '0.75rem',
  },
  disabledButton: {
    opacity: 0.4
  },
  // Loading icon variants
  colorWhite: {
    color: Colors.white
  },
  colorBlack: {
    color: Colors.black
  },
  colorViolet: {
    color: Colors.navy
  },
  tallButton: {
    height: '4rem'
  },
  tallButtonText: {
    fontSize: '1.25rem'
  }
}))

export default function BaseButton(props) {
  const classes = useStyles()

  const buttonClasses = [classes.baseButton]
  const buttonTextClasses = [classes.baseButtonText]
  let loadingClass = classes.colorWhite
  let loadingSize = 22

  if (props.fullWidth) {
    buttonClasses.push(classes.fullWidth)
  }

  // Add button base style
  if (props.tall) {
    buttonTextClasses.push(classes.tallButtonText)
  }
  if (props.outlined) {
    loadingClass = classes.colorViolet
    buttonClasses.push(classes.outlinedButton)
    buttonTextClasses.push(classes.outlinedButtonText)
  } else {
    buttonClasses.push(classes.defaultButton)
    buttonTextClasses.push(classes.defaultButtonText)
  }

  // Add size
  if (props.small) {
    loadingSize = 18
    buttonClasses.push(classes.smallButton)
    buttonTextClasses.push(classes.smallButtonText)
  }

  if (props.tall) {
    buttonClasses.push(classes.tallButton)
  }

  if (props.buttonStyle) buttonClasses.push(props.buttonStyle)
  if (props.buttonTextStyle) buttonTextClasses.push(props.buttonTextStyle)

  const renderContent = () => {
    if (props.loading) return <CircularProgress className={loadingClass} size={loadingSize} />

    return (
      <>
        {props.leftIcon && <img src={props.leftIcon} className={classes.leftIcon} alt='icon' />}
        <p className={buttonTextClasses.join(' ')}>
          {props.text}
        </p>
        {props.rightIcon && <img src={props.rightIcon} className={classes.rightIcon} alt='icon' />}
      </>
    )
  }

  return (
    <Button
      className={buttonClasses.join(' ')}
      onClick={props.onClick}
      disabled={props.disabled}
      classes={{ disabled: classes.disabledButton }}
    >
      {renderContent()}
    </Button>
  )
}
