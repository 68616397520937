import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import {
  EmployeeTable,
  PageContainer,
  PageHeader,
  PageContent,
  IndexActions,
  TableHeaderActions
} from '../../../Components'
import { useLocation, useNavigate } from 'react-router-dom'
import { EmployeeType } from '../../../Schemas'
import EmployeeFilterDialog from '../../../Components/Employee/EmployeeFilterDialog'
import FilterButtons from '../../../Components/Common/FilterButtons'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    padding: '2.5rem'
  }
}))

const SORT_KEYS = ['name', 'contractType', null, 'location', null, 'feedbackCount']
const SORT_DIRECTIONS = ['asc', 'desc']

interface QueryParams {
  search: string
  perPage: number
  sort: string
  contractType: number
}

function Employees() {
  const classes = useStyles()

  const { sessionStore, employeeStore }: any = useStore()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()

  const [queryParams, setQueryParams] = useState<QueryParams>({
    search: '',
    perPage: 10,
    sort: `${SORT_KEYS[0]}_${SORT_DIRECTIONS[0]}`,
    contractType: 0
  })

  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [showFilter, setShowFilter] = useState(false)

  const handleToggleSelect = (id: number): void => {
    // Try remove
    const newIds: number[] = selectedIds.filter(val => val !== id)
    if (newIds.length !== selectedIds.length) {
      setSelectedIds(newIds)
    } else {
      // Add new
      setSelectedIds([...selectedIds, id])
    }
  }
  const handleSelectAll = (): void => {
    setSelectedIds(employeeStore.employees.map((employee: EmployeeType) => employee.id))
  }
  const handleClearSelect = (): void => setSelectedIds([])

  useEffect(() => {
    // Handle setting queryParams when returning from customer screen
    const params = (location.state as any)?.queryParams
    if (params !== undefined) {
      setQueryParams(params)
    }
    // Clear state for page refresh to clear all queryParams
    navigate('')
  }, [])

  useEffect(() => {
    refreshItems()
  }, [queryParams])

  const refreshItems = () => {
    employeeStore.getEmployees({
      search: queryParams.search,
      perPage: queryParams.perPage,
      page: 0,
      type: queryParams.contractType,
      sort: queryParams.sort
    })
  }

  const handleQueryParamsChange = (key: string, value: string | number): void => {
    setQueryParams({...queryParams, [key]: value})
  }

  const handleFilterConfirm = ({ contractType }: { contractType: number }): void => {
    setQueryParams({...queryParams, contractType})
    setShowFilter(false)
  }

  const getSortOptions = () => {
    const options: any = []
    for (const direction of SORT_DIRECTIONS) {
      for (const sortKey of SORT_KEYS) {
        const fullSortKey = `${sortKey}_${direction}`
        options.push({
          value: fullSortKey,
          label: t(`sort_${fullSortKey}`)
        })
      }
    }
    return options
  }

  const resolveFilterButtonItems = (): { key: string, label: string, value: string }[] => {
    const buttons: { key: string, label: string, value: string }[] = []
    if (queryParams.contractType !== 0) {
      const name = employeeStore.contractTypes.find(item => item.id === queryParams.contractType)?.name
      buttons.push({ key: 'type', label: t('employee_contract_type'), value: t(`employee_contract_type_${name}`) })
    }
    return buttons
  }

  const handleFilterDisable = (key: string): void => {
    switch (key) {
      case 'type':
        setQueryParams({...queryParams, contractType: 0})
        break
    }
  }

  const handleEmployeeOpen = (id: number): void => {
    navigate(`/employees/${id}`, { state: { queryParams }})
  }

  return (
    <PageContainer>
      <PageHeader
        title={t('employees')}
        total={employeeStore.total}
        user={sessionStore.user}
        logout={() => sessionStore.logout()}
      />
      <PageContent>
        <IndexActions
          sort={queryParams.sort}
          setSort={(sort: string) => handleQueryParamsChange('sort', sort)}
          sortOptions={getSortOptions()}
        />
        <FilterButtons
          items={resolveFilterButtonItems()}
          onDisable={handleFilterDisable}
        />
        <TableHeaderActions
          search={queryParams.search}
          setSearch={(search: string) => handleQueryParamsChange('search', search)}
          perPage={queryParams.perPage}
          setPerPage={(perPage: number) => handleQueryParamsChange('perPage', perPage)}
          onFilter={() => setShowFilter(state => !state)}
          total={employeeStore.totalFiltered}
        />
        <EmployeeTable
          loading={employeeStore.loading}
          items={employeeStore.employees}
          sort={queryParams.sort}
          sortKeys={SORT_KEYS}
          onSort={(sort: string) => handleQueryParamsChange('sort', sort)}
          selectedIds={selectedIds}
          onToggleSelect={handleToggleSelect}
          onSelectAll={handleSelectAll}
          onClearSelect={handleClearSelect}
          onEmployeeOpen={handleEmployeeOpen}
        />
      </PageContent>
      <EmployeeFilterDialog
        open={showFilter}
        onClose={() => setShowFilter(false)}
        onConfirm={handleFilterConfirm}
        filterParams={queryParams}
      />
    </PageContainer>
  )
}

export default observer(Employees)
