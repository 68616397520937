import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { Button } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    background: Colors.lightGreen,
    padding: '.25rem',
    borderRadius: '.3125rem',
    fontWeight: 700
  },
  tabActive: {
    flex: 1,
    cursor: 'pointer',
    backgroundColor: Colors.navy,
    color: Colors.white,
    borderRadius: '.3125rem',
    padding: '.5rem .5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: Colors.navy
    }
  },
  tab: {
    flex: 1,
    cursor: 'pointer',
    backgroundColor: 'transparent',
    color: Colors.navy,
    borderRadius: '.3125rem',
    padding: '.5rem .5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tabLabel: {
    fontWeight: 700,
    textTransform: 'none',
    fontSize: '1rem'
  },
  tabLabelExtraPadding: {
    fontWeight: 700,
    textTransform: 'none',
    fontSize: '1rem',
    paddingLeft: '2rem',
    paddingRight: '2rem'
  }
}))

export default function Tabs(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {props.items.map(tab => {
        return (
          <Button
            key={tab.value}
            className={props.value === tab.value ? classes.tabActive : classes.tab}
            onClick={() => props.onChange(tab.value)}
          >
            <span className={props.extraPadding ? classes.tabLabelExtraPadding : classes.tabLabel}>{tab.name}</span>
          </Button>
        )
      })}
    </div>
  )
}
