import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { Button, Select } from '../index'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '2rem'
  },
  spacer: {
    width: '1rem'
  },
  selectSort: {
    width: '15rem'
  },
  selectCategory: {
    width: '15rem'
  },
  flexDivider: {
    flex: 1,
    borderRight: `1px solid ${Colors.border}`,
    height: '2rem',
    marginRight: '2rem'
  },
  addNewButton: {
    paddingLeft: '2rem',
    paddingRight: '2rem'
  }
}))

export default function IndexActions(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const CreateButton = <>
    <div className={classes.flexDivider} />
    <Button
      text={t('add_new')}
      onClick={props.onCreate}
      buttonStyle={classes.addNewButton}
    />
  </>

  return (
    <div className={classes.container}>
      <div className={classes.selectSort}>
        <Select
          options={props.sortOptions}
          value={props.sort}
          onChange={props.setSort}
          noMargin
        />
      </div>
      <div className={classes.spacer} />
      {props.categoryOptions && (
        <div className={classes.selectCategory}>
          <Select
            options={props.categoryOptions}
            value={props.category}
            onChange={props.setCategory}
            noMargin
          />
        </div>
      )}
      {props.onCreate && CreateButton}
    </div>
  )
}
