import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { Checkbox } from '../index'
import { EmployeeType } from '../../Schemas/EmployeeSchema'

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    backgroundColor: Colors.white,
    '&:hover': {
      backgroundColor: `${Colors.tableHover} !important`
    },
    borderBottom: `1px solid ${Colors.border}`
  },
  cell: {
    fontFamily: 'Ambit',
    fontSize: '1.25rem',
    fontWeight: 400,
    color: Colors.black,
    borderBottom: 'none',
    height: '4.25rem'
  },
  secondary: {
    color: Colors.secondaryText
  },
  checkboxContainer: {
    paddingLeft: '.25rem',
    maxWidth: '2rem'
  }
}))

interface Props {
  item: EmployeeType,
  selectedIds: number[],
  onToggleSelect: (index: number) => void,
  onClick: (id: number) => void
}

const EmployeeTableRow = ({ item, selectedIds, onToggleSelect, onClick }: Props): ReactElement => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <TableRow
      classes={{ root: classes.root }}
      onClick={() => onClick(item.id)}
      hover
    >
      <TableCell className={classes.cell}>
        <div className={classes.checkboxContainer}>
          <Checkbox
            checked={selectedIds.includes(item.id)}
            onChange={() => onToggleSelect(item.id)}
            label=''
          />
        </div>
      </TableCell>
      <TableCell key={`row${item.id}_name`} className={classes.cell}>
        <span>{item.person.firstName} {item.person.lastName}</span>
      </TableCell>
      <TableCell key={`row${item.id}_contractType`} className={classes.cell}>
        <span className={classes.secondary}>
          {item.contractType ? t(`employee_contract_type_${item.contractType}`) : ''}
        </span>
      </TableCell>
      <TableCell key={`row${item.id}_languageSkills`} className={classes.cell}>
        <span className={classes.secondary}>?</span>
      </TableCell>
      <TableCell key={`row${item.id}_location`} className={classes.cell}>
        <span className={classes.secondary}>{item.person.city}</span>
      </TableCell>
      <TableCell key={`row${item.id}_shiftDuration`} className={classes.cell}>
        <span className={classes.secondary}>?</span>
      </TableCell>
      <TableCell key={`row${item.id}_feedbackAvg`} className={classes.cell}>
        <span className={classes.secondary}>{item.feedbackCount}</span>
      </TableCell>
    </TableRow>
  )
}

export default EmployeeTableRow
