import { ReactElement, useEffect, useState } from 'react'
import { useStore } from '../../Models/RootStore'
import { useParams } from 'react-router-dom'
import parse from 'html-react-parser'

const PrivacyPolicy = (): ReactElement => {
  const { appStore }: any = useStore()
  const { getPrivacyPolicy } = appStore
  const { locale } = useParams()
  const [privacyPolicy, setPrivacyPolicy] = useState<string | null>(null)

  useEffect(() => {
    void fetchPrivacyPolicy()
  }, [locale])

  const fetchPrivacyPolicy = async (): Promise<void> => {
    setPrivacyPolicy(await getPrivacyPolicy(locale))
  }

  if (privacyPolicy === null) {
    return <></>
  }

  return (
    <div>
      {parse(privacyPolicy)}
    </div>
  )
}

export default PrivacyPolicy
