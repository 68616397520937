import { EmployeeType, LeaveType } from '../../Schemas'
import React, { ReactElement, useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../Models/RootStore'
import { useTranslation } from 'react-i18next'
import { Input, Title, TableHeader } from '../index'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import useFormatter from '../../Hooks/useFormatter'

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: '3rem'
  }
}))

interface Props {
  employee: EmployeeType
  onChange: (key: string, value: string | number) => void
}

const EmployeeGeneral = ({ employee, onChange }: Props): ReactElement => {
  const { employeeStore }: any = useStore()
  const { t } = useTranslation()
  const classes = useStyles()
  const [leaves, setLeaves] = useState<LeaveType[]>([])
  const { formatDate } = useFormatter()

  useEffect(() => {
    setLeaves([...(employee.leaves ?? [])].sort((a, b) => (
      (b.startTime?.valueOf() ?? 0) - (a.startTime?.valueOf() ?? 0))
    ))
  }, [employee.leaves])

  const EmployeeInfo = <div className={classes.section}>
    <Title title={t('employee_info')} type="main" />
    <Grid container spacing={4}>
      <Grid item md={4} xs={12}>
        <Input
          value={employee.person.firstName}
          onChange={value => onChange('person.firstName', value)}
          label={t('first_name')}
          noMargin
          disabled
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          value={employee.person.lastName}
          onChange={value => onChange('person.lastName', value)}
          label={t('last_name')}
          noMargin
          disabled
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          value={employee.person.dateOfBirth}
          onChange={value => onChange('person.dateOfBirth', value)}
          label={t('date_of_birth')}
          noMargin
          disabled
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          value={t(`employee_status_${employee.status}`)}
          onChange={value => onChange('status', value)}
          label={t('status')}
          noMargin
          disabled
        />
      </Grid>
      <Grid item md={8} xs={12}>
        <Input
          value={employee.person.streetAddress}
          onChange={value => onChange('person.streetAddress', value)}
          label={t('street_address')}
          noMargin
          disabled
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          value={employee.person.postalCode}
          onChange={value => onChange('person.postalCode', value)}
          label={t('postal_code')}
          noMargin
          disabled
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          value={employee.person.city}
          onChange={value => onChange('person.city', value)}
          label={t('city')}
          noMargin
          disabled
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          value={employee.person.country}
          onChange={value => onChange('person.country', value)}
          label={t('country')}
          noMargin
          disabled
        />
      </Grid>
    </Grid>
  </div>

  const LeavesTable = <div className={classes.section}>
    <Title title={t('leaves')} type="main" />
    <Table>
      <TableHeader
        columnNames={[t('type'), t('start_date'), t('end_date'), t('confirmed')]}
      />
      <TableBody>
        {leaves.map(leave => (
          <TableRow>
            <TableCell key={`leave_row${leave.id}_name`}>
              <span>{t(`leave_type_${leave.leaveType}`)}</span>
            </TableCell>
            <TableCell key={`leave_row${leave.id}_start_time`}>
              <span>{formatDate(leave.startTime)}</span>
            </TableCell>
            <TableCell key={`leave_row${leave.id}_end_time`}>
              <span>{formatDate(leave.endTime)}</span>
            </TableCell>
            <TableCell key={`leave_row${leave.id}_confirmed`}>
              <span>{t(`leave_status_${leave.confirmed}`)}</span>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>

  return (
    <>
      {EmployeeInfo}
      {Array.isArray(employee.leaves) && employee.leaves.length > 0 && LeavesTable}
    </>
  )
}

export default EmployeeGeneral
