import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import { UserSchema, UserType } from '../Schemas'

export default class UserStore {
  rootStore
  users: UserType[] = []
  total = 0
  totalFiltered = 0
  userInvites = []
  user = null
  loading = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setUsers = (users) => { this.users = users }
  setUserInvites = (invites) => { this.userInvites = invites }
  setUser = (user) => { this.user = user }
  setLoading = (loading) => { this.loading = loading }
  setTotal = (total) => { this.total = total }
  setTotalFiltered = (total) => { this.totalFiltered = total }

  // API

  async getUsers(params) {
    const search = params.search || ''
    const take = params.perPage || 50
    const skip = (params.page || 0) * take
    const state = params.state || undefined
    const sort = params.sort || undefined

    this.setLoading(true)
    const response: any = await Api.getUsers({ search, take, skip, state, sort})
    this.setLoading(false)
    if (response.ok) {
      const validUsers: UserType[] = []
      response.data.items.forEach(item => {
        try {
          const user = UserSchema.parse(item)
          validUsers.push(user)
        } catch (error) {
          console.error("Invalid user: ", item, error)
        }
      })
      this.setUsers(validUsers)
      this.setTotal(response.data.total)
      this.setTotalFiltered(response.data.totalFiltered)
    }
  }

  async getUser(id) {
    this.setLoading(true)
    this.setUser(null)
    const response = await Api.getUser(id)
    this.setLoading(false)
    if (response.ok) {
      const user = response.data
      this.setUser(user)
    }
  }

  async createUser(data: any): Promise<boolean | string[]> {
    this.setLoading(true)
    const response = await Api.createUser(data)
    this.setLoading(false)
    if (response.ok) {
      return true
    }
    return [response.data?.message].flat()
  }

  async updateUserPhoto(id, data) {
    this.setLoading(true)
    const response = await Api.updateUserPhoto(id, data)
    this.setLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')
    if (response.ok) {
      const user = response.data
      this.setUser(user)
    }
  }

  async updateUser(id, data): Promise<boolean | string[]> {
    this.setLoading(true)
    const response = await Api.updateUser(id, data)
    this.setLoading(false)
    if (response.ok) {
      this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')
      return true
      // const user = response.data
      // this.setUser(user)
    }
    return [response.data?.message].flat()
  }

  async getUserInvites() {
    this.setLoading(true)
    const response: any = await Api.getUserInvites()
    this.setLoading(false)
    if (response.ok) {
      const userInvites = response.data
      this.setUserInvites(userInvites)
    }
  }

  async inviteUser(data) {
    this.setLoading(true)
    const response = await Api.inviteUser(data)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'invite_sent')
    this.setLoading(false)
    if (response.ok) {
      // Reload invites
      this.getUserInvites()
    }
  }

  async verifyUserInvite(data) {
    this.setLoading(true)
    const response = await Api.verifyUserInvite(data)
    if (response.ok) {
      // TODO: Redirect to login and display some message?
    }
    this.setLoading(false)
  }


}
