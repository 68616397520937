import { ReactElement } from 'react'
import image1 from '../../Assets/Images/delete1.png'
import image2 from '../../Assets/Images/delete2.png'
import image3 from '../../Assets/Images/delete3.png'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  image: {
    margin: '0 10px 0 10px'
  },
  header: {
    margin: '0 0 20px 0'
  },
  footer: {
    margin: '20px 0 20px 0'
  }
}))

const AccountDeleteInstructions = (): ReactElement => {
  const classes = useStyles()

  return (
    <div>
      <h1>Lempi account deletion instructions</h1>
      <div className={classes.header}>
        <span>Login to OmaLempi mobile application and follow these steps:</span>
      </div>
      <div>
        <img src={image1} width={256} height={512} className={classes.image} alt='Click settings icon' />
        <img src={image2} width={256} height={512} className={classes.image} alt='Click "deleting the user account"' />
        <img src={image3} width={256} height={512} className={classes.image}  alt='Click "Send an account deletion request"' />
      </div>
      <div className={classes.footer}>
        <span>If you have any problems, please contact us at <a href='mailto:info@lempi.fi'>info@lempi.fi</a></span>
      </div>
    </div>
  )
}

export default AccountDeleteInstructions
