import React, { ReactElement, useRef } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import moment from 'moment'
import 'dayjs/locale/fi'
import Input from './Input'

interface Props {
  label?: string
  value: string | null
  onChange: (newValue: string) => void
}

const DatePicker = ({ label, value, onChange }: Props): ReactElement => {
  const [open, setOpen] = React.useState(false)
  const inputRef = useRef<HTMLDivElement | null>(null)

  const InputField = (props) => (
    <div ref={inputRef}>
      <Input
        onChange={() => {}}
        label={label}
        noMargin
        value={!!value ? moment(value).format('DD.MM.YYYY') : ''}
        onClick={() => setOpen(true)}
      />
    </div>
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fi'}>
      <DesktopDatePicker
        PopperProps={{ anchorEl: inputRef.current }}
        inputFormat="DD.MM.YYYY"
        renderInput={InputField}
        value={value}
        open={open}
        onChange={(newValue) => {
          onChange(newValue.format('YYYY-MM-DD'))
          setOpen(false)
        }}
      />
    </LocalizationProvider>
  )
}

export default DatePicker
