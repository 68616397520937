import { createTheme, adaptV4Theme } from '@mui/material/styles'

export enum Colors {
  black = '#000000',
  black90 = 'rgba(0, 0, 0, .9)',
  black80 = 'rgba(0, 0, 0, .8)',
  black70 = 'rgba(0, 0, 0, .7)',
  black60 = 'rgba(0, 0, 0, .6)',
  black50 = 'rgba(0, 0, 0, .5)',
  black40 = 'rgba(0, 0, 0, .4)',
  black30 = 'rgba(0, 0, 0, .3)',
  black20 = 'rgba(0, 0, 0, .2)',
  black10 = 'rgba(0, 0, 0, .1)',
  black05 = 'rgba(0, 0, 0, .05)',
  white90 = 'rgba(255, 255, 255, .9)',
  white80 = 'rgba(255, 255, 255, .8)',
  white70 = 'rgba(255, 255, 255, .7)',
  white60 = 'rgba(255, 255, 255, .6)',
  white50 = 'rgba(255, 255, 255, .5)',
  white40 = 'rgba(255, 255, 255, .4)',
  white30 = 'rgba(255, 255, 255, .3)',
  white20 = 'rgba(255, 255, 255, .2)',
  white10 = 'rgba(255, 255, 255, .1)',
  white05 = 'rgba(255, 255, 255, .05)',
  grey = '#C1C1C1',
  greyIcon = 'rgba(181, 181, 181, .7)',
  lightGrey = '#F7F7F7',
  backgroundGrey = '#F4F4F4',
  white = '#FFFFFF',
  green = '#3EB973',
  red = '#FF1744',
  red20 = 'rgba(255, 23, 68, .2)',
  redHover = '#c20025',
  tableRow = '#E5E5E5',
  lightBlue = 'rgba(0,65,125,0.1)',
  blueLink = '#265EB2',
  blue = '#00417D',
  blue05 = 'rgba(0, 65, 125, .05)',
  mapMarker = '#EB5E57',
  tableHover = '#f8f8f8',
  purple = '#9b51e0',
  geoPoint = '#1565c0',
  geoArea = '#2e7d32',
  geoRoute = '#FF1744',
  label = '#0f2e3c',
  text = '#6F828A',
  secondaryText = '#6F818A',
  inputText = '#6F828A',
  inputTextDark = '#212121',
  border = '#9FABB1',
  navy = '#0f2e3c',
  navy80 = 'rgba(15,46,60,.8)',
  navy50 = 'rgba(15,46,60,.5)',
  navy30 = 'rgba(15,46,60,.3)',
  navy10 = 'rgba(15,46,60,.1)',
  chart1 = '#0f2e3c',
  chart2 = '#6F828A',
  chart3 = '#FAC436',
  lightBorder = '#CED5D8',
  lightGreen = '#e4ffa8',
  backdrop = 'rgba(15, 46, 60, 0.50)'
}

// A custom theme for this app
const Theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: Colors.navy
    },
    secondary: {
      main: Colors.black
    },
    error: {
      main: Colors.red
    },
    background: {
      default: Colors.white
    }
  }
}))

export default Theme
