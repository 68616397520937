import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { CircularProgress } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '12.5rem',
    borderBottom: `1px solid ${Colors.border}`
  },
  infoText: {
    fontSize: '1.75rem',
    color: Colors.black80,
    fontFamily: 'Ambit'
  }
}))

function DataMessage (props) {
  const classes = useStyles()

  const renderEmptyText = () => {
    return (
      <p className={classes.infoText}>{props.message}</p>
    )
  }

  const renderSpinner = () => {
    return (
      <CircularProgress />
    )
  }

  if (!props.data || !props.data.length) {
    return (
      <div className={classes.infoContainer}>
        {props.loading ? renderSpinner() : renderEmptyText()}
      </div>
    )
  }
  return null
}

export default DataMessage
