import { sortBy } from 'lodash'
import {
  CUSTOMERS,
  CUSTOMERS_SUMMARY,
  EMPLOYEES_SUMMARY,
  CUSTOMER_CATEGORIES,
  EMPLOYEES,
  EMPLOYEE_CATEGORIES,
  SHIFTS,
  SHIFT_CATEGORIES,
  USER_SUMMARY
} from '../Utils/mockData'

const mockApi = {
  getCustomersSummary: async (date) => {
    return {
      ok: true,
      data: {
        date,
        ...CUSTOMERS_SUMMARY
      }
    }
  },

  getEmployeesSummary: async (date) => {
    return {
      ok: true,
      data: {
        date,
        ...EMPLOYEES_SUMMARY
      }
    }
  },

  getUserSummary: async (startDate, endDate) => {
    return {
      ok: true,
      data: {
        startDate,
        endDate,
        ...USER_SUMMARY
      }
    }
  },

  getShifts: async (params) => {
    let items = SHIFTS
    if (params.search && params.search.length > 1) {
      const searchStr = (params?.search || '').toLowerCase()
      items = items.filter(item => item?._search.indexOf(searchStr) !== -1)
    }

    if (params.category) {
      items = items.filter(item => item.categoryId === params.category)
    }

    return {
      ok: true,
      data: {
        categories: SHIFT_CATEGORIES,
        total: items.length,
        items: items.slice(params.skip, params.take)
      }
    }
  },

  getCustomers: async (params) => {
    let items = CUSTOMERS

    if (params.search && params.search.length > 1) {
      const searchStr = (params?.search || '').toLowerCase()
      items = items.filter(item => item?._search.indexOf(searchStr) !== -1)
    }

    if (params.category) {
      items = items.filter(item => item.categoryId === params.category)
    }

    if (params.sort === 'time') {
      items = sortBy(items, 'id')
    }

    return {
      ok: true,
      data: {
        categories: CUSTOMER_CATEGORIES,
        total: items.length,
        items: items.slice(params.skip, params.take)
      }
    }
  },
  getCustomer: async (id) => {
    return CUSTOMERS.find(item => item.id === id)
  },

  getEmployees: async (params) => {
    let items = EMPLOYEES

    if (params.search && params.search.length > 1) {
      const searchStr = (params?.search || '').toLowerCase()
      items = items.filter(item => item?._search.indexOf(searchStr) !== -1)
    }

    if (params.category) {
      items = items.filter(item => item.categoryId === params.category)
    }

    if (params.sort === 'time') {
      items = sortBy(items, 'id')
    } else {
      items = sortBy(items, 'name')
    }

    return {
      ok: true,
      data: {
        categories: EMPLOYEE_CATEGORIES,
        total: items.length,
        items: items.slice(params.skip, params.take)
      }
    }
  },
  getEmployee: async (id) => {
    return EMPLOYEES.find(item => item.id === id)
  }
}

export default mockApi
