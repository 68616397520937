import React, { useState } from 'react'
import moment from 'moment'
import { chunk } from 'lodash'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { TableHeader, DataMessage, Checkbox, Button, Select } from '..'
import { Link, useNavigate } from 'react-router-dom'
import { Colors } from '../../Utils/theme'
import { IconButton } from '@mui/material'
import ChevronMiniLeft from '../../Assets/Icons/chevron-mini-left.svg'
import ChevronMiniRight from '../../Assets/Icons/chevron-mini-right.svg'

const useStyles = makeStyles((theme) => ({
  container: {
  },
  pagerContainer: {
    marginLeft: '.125rem'
  },
  answersContainer: {
    marginBottom: '1rem'
  },
  answersChunkRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
  },
  boxContainer: {
    border: `1px solid ${Colors.border}`,
    padding: '1rem',
    borderRadius: '.25rem',
    marginBottom: '1rem',
    boxSizing: 'border-box'
  },
  placeholder: {
    width: '100%'
  },
  answerText: {
    color: 'rgba(15, 46, 60, 0.60)',
    fontSize: '1.125rem',
    lineHeight: 1.2
  },
  date: {
    marginTop: '.5rem',
    color: Colors.navy,
    fontSize: '1rem'
  },
  actionsRow: {
    marginLeft: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  selectContainer: {
    marginLeft: '1rem',
    marginTop: '1.25rem',
    maxWidth: '3rem'
  },
  chevronIcon: {
    height: '.75rem'
  },
  pager: {
    display: 'flex',
    flexDirection: 'row',
    border: `1px solid ${Colors.border}`,
    borderRadius: '.25rem',
    marginLeft: '2rem',
    paddingLeft: '.125rem',
    paddingRight: '.125rem'
  },
  pagerMiddle: {
    backgroundColor: Colors.backgroundGrey,
    padding: '.5rem .75rem'
  }
}))

const ITEMS_PER_ROW = 3

function PollTextAnswers(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [displayItems, setDisplayItems] = useState(3)
  const [currentPage, setCurrentPage] = useState(1)

  const handleSetDisplayItems = (value) => {
    setCurrentPage(1)
    setDisplayItems(value)
  }

  const handlePagePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }
  const handlePageNext = () => {
    if ((currentPage) * displayItems < props.items.length) {
      setCurrentPage(currentPage + 1)
    }
  }

  const getDisplayItemsOptions = () => [3, 9, 15].map(val => {
    return {
      label: val,
      value: val
    }
  })

  const renderPager = () => {
    return (
      <div className={classes.pager}>
        <IconButton onClick={handlePagePrevious}>
          <img src={ChevronMiniLeft} className={classes.chevronIcon} />
        </IconButton>
        <div className={classes.pagerMiddle}>
          {currentPage} / {Math.ceil(props.items.length / displayItems)}
        </div>
        <IconButton onClick={handlePageNext}>
          <img src={ChevronMiniRight} className={classes.chevronIcon} />
        </IconButton>
      </div>
    )
  }

  return (
    <div>
      <div className={classes.answersContainer}>
        {chunk(props.items.slice(((currentPage - 1) * displayItems), (((currentPage - 1) * displayItems) + displayItems)), ITEMS_PER_ROW).map(items => {
          return (
            <div className={classes.answersChunkRow}>
              <>
                {items.map((item: any, index) => {
                  return (
                    <div
                      key={index}
                      className={classes.boxContainer}
                    >
                      <div className={classes.answerText}>{item.textAnswer}</div>
                      <div className={classes.date}>{moment(item.createdAt).format('DD.MM.YYYY')} {t('time_at')} {moment(item.createdAt).format('HH:mm')}</div>
                    </div>
                  )
                })}
              </>
              <>
                {((ITEMS_PER_ROW - items.length) > 0) ? Array(ITEMS_PER_ROW - items.length).fill(null).map((_, index) => {
                  return (
                    <div
                      key={index}
                      className={classes.placeholder}
                    />
                  )
                }) : null}
              </>
            </div>
          )
        })}
      </div>
      <div className={classes.actionsRow}>
        <div className={classes.answerText}>{t('show_at_once')}</div>
        <div className={classes.selectContainer}>
          <Select
            options={getDisplayItemsOptions()}
            onChange={handleSetDisplayItems}
            value={displayItems}
          />
        </div>
        <div className={classes.pagerContainer}>
          {renderPager()}
        </div>
      </div>
    </div>
  )
}

export default PollTextAnswers
